import React, {Component} from "react";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-dashboard-pro-react/views/CommentSectionStyle";
import {deleteComment, getComment, postComment, putComment} from "actions/contract";
import {convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {Editor} from 'react-draft-wysiwyg';
import {stateFromHTML} from 'draft-js-import-html';
import CustomDialog from 'components/CustomDialog/CustomDialog';


class CommentSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      userData: JSON.parse(localStorage.getItem('userData')),
      viewAll: false,
      showableComments: [],
      commentList: [],
      oldComments: [],
      editorState: EditorState.createEmpty(),
      showDeleteDialog: false,
      commentToDelete: null,
      saveDisabled: false,
    }
  }

  componentDidMount() {
    this.props.dispatch(getComment(this.props.id, (response) => {
      this.setCommentInState(response)
    }, () => {
      console.log(this.state.showableComments)
    }))
  }

  setCommentInState(response) {
    let commentsWithWithdraws = this.addWithdrawsToComments(response.data);
    const sortedList = commentsWithWithdraws.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime())
    this.setState({
      commentList: sortedList,
      showableComments: sortedList.slice(0, 2),
      viewAll: false,
      isLoading: false
    })
  }

  addWithdrawsToComments(comments) {
    comments = comments || [];
    const commentsWithWithdraws = Object.keys(this.props.withdraws).map(key => {
      const withdraw = this.props.withdraws[key];
      return {
        userId: withdraw.bid.userId,
        userName: withdraw.firstName + " " + withdraw.lastName,
        comment: undefined,
        created: withdraw.bid.createdAt,
        updated: withdraw.bid.updatedAt,
        withdrawReason: withdraw.bid.withdrawReason,
        withdrawNotes: withdraw.bid.notes,
        type: "withdraw"
      }
    });

    let commentsWithType = comments.map(comment => ({...comment, type: 'comment'}));
    return commentsWithWithdraws.concat(commentsWithType);
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  };

  onContentStateChange(e) {
    console.log('on content', e)
  }


  onComment(event) {
    console.log('event', event)
    this.setState({
      value: event.target.value
    }, () => {
      if (event.charCode === 13 && event.target.value) {
        this.props.dispatch(postComment(this.props.id, {
          userId: JSON.parse(localStorage.getItem('userData'))._id,
          comment: event.target.value
        }, () => {
          this.setState({

            value: '',
          }, () => {
            this.props.dispatch(getComment(this.props.id, (response) => {
              const sortedList = response.data ? response.data.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()) : []
              this.setState({
                commentList: sortedList,
                showableComments: sortedList.slice(0, 2),
                viewAll: false
              })
            }))
          })
        }))
      }
    })
  }

  showAllComments() {
    this.setState({
      viewAll: true,
      showableComments: this.state.commentList,
    })
  }


  saveComment() {
    let comment = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    if (comment) {
      comment = comment.trim();
    }
    if (comment === '<p></p>') return;

    this.setState({saveDisabled: true});
    this.props.dispatch(postComment(this.props.id, {
      userId: this.state.userData._id,
      comment
    }, () => {
      this.setState({
        value: '',
        editorState: EditorState.createEmpty(),
        saveDisabled: false
      }, () => {
        this.props.dispatch(getComment(this.props.id, (response) => {
          this.setCommentInState(response);
        }))
      })
    }))
  }

  toggleEditor() {
    this.setState({showEditor: !this.state.showEditor});
  }

  handleDeleteDialog(comment) {
    this.setState((prevState) => ({
      showDeleteDialog: !prevState.showDeleteDialog,
      commentToDelete: comment
    }))

  }

  isComment(comment) {
    return comment && comment.type === "comment"
  }


  render() {
    const {classes} = this.props;
    const {editorState, viewAll, showableComments, commentList} = this.state;
    return (
      <div>
        {this.state.showEditor ?
          <div>
            <Editor
              initialEditorState={editorState}
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(e) => this.onEditorStateChange(e)}
            />
            <div className={classes.commentEdit}>
              <span className={classes.editorSave + ' ' + classes.clickable}
                    onClick={() => this.state.saveDisabled ? null : this.saveComment()}>Save</span>
              <span className={classes.clickable} onClick={() => this.toggleEditor()}>Cancel</span>
            </div>
          </div> :
          <CustomInput className={classes.commentInput} inputProps={{
            placeholder: "Add a comment...",
            defaultValue: "",
            type: "text",
            onClick: () => this.toggleEditor(),
            onChange: (event) => this.onFormChange(event)
          }} formControlProps={{fullWidth: true}}></CustomInput>}
        <div className={classes.oldComment}>{!viewAll && (commentList.length - showableComments.length > 0) &&
        <span onClick={() => this.showAllComments()}
              className={classes.clickable}>View {commentList.length - showableComments.length} older comments</span>}</div>
        {this.state.showableComments.map((comment) =>
          <div className={classes.commentWrapper}>
            <div className={classes.imgWrapper}>
              <div className={classes.userImg}></div>
            </div>
            <div className={classes.totalCommentSection}>
              <div className={classes.commentWrapper}>
                <div className={classes.user}>{comment.userName}</div>
              </div>
              <div className={classes.date}>
                                    <span>Posted: {(() => {
                                      let date = new Date(comment.created).toDateString().split(' ');
                                      let d = new Date(comment.created);
                                      return `${date[2]} ${date[1]} ${date[3]} - ${d.getHours() >= 10 ? d.getHours() : '0' + d.getHours()}:${d.getMinutes() >= 10 ? d.getMinutes() : '0' + d.getMinutes()} Hr`
                                    })()}
                                    </span>
                {comment.created !== comment.updated &&
                <span className={classes.updateTag}>Updated: {(() => {
                  let date = new Date(comment.updated).toDateString().split(' ');
                  let d = new Date(comment.updated);
                  return `${date[2]} ${date[1]} ${date[3]} - ${d.getHours() >= 10 ? d.getHours() : '0' + d.getHours()}:${d.getMinutes() >= 10 ? d.getMinutes() : '0' + d.getMinutes()} Hr`
                })()}</span>
                }
              </div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                {comment.withdrawReason && (<div>Withdrawal reason: </div>)} {comment.withdrawReason && (
                <p style={{marginLeft: 3}}> {comment.withdrawReason} </p>)}
              </div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                {comment.withdrawNotes && (<div>Note: </div>)} {comment.withdrawNotes && (
                <p style={{marginLeft: 3}}> {comment.withdrawNotes} </p>)}
              </div>
              {this.isComment(comment) && !comment.editable &&
              (<div className='mt-2'>
                <div dangerouslySetInnerHTML={{__html: comment.comment}}></div>
                <span>{(comment.created !== comment.updated) ?
                  <span className='' style={{fontSize: '12px'}}>(edited)</span> : ''}</span>
              </div>)}
              {this.isComment(comment) && comment.editable &&
              <>
                <Editor
                  initialEditorState={comment.editor}
                  editorState={comment.editor}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={(e) => this.onEditComment(e, comment)}
                />
                <div className={classes.editButtons}>
                  <span className={classes.clickable + ' ' + classes.buttonStyle}
                    onClick={() => this.onEditComment(undefined, comment)}>Save</span>
                  <span className={classes.clickable + ' ' + classes.buttonStyle}
                    onClick={() => this.editComment(comment)}>Cancel</span>
                </div>
              </>
              }
              {this.isComment(comment) && (this.state.userData._id === comment.userId || this.state.userData.userRoles.includes("Admin")) &&
                <div className={classes.iconWrapper}>
                  {
                    <><span className='mx-3 a-icon text-primary'><i className={`fa fa-edit  ${classes.iconStyle}`}
                      onClick={() => this.editComment(comment)}></i></span><span
                        className='mx-3 a-icon text-primary'><i className={`fa fa-trash  ${classes.iconStyle}`}
                          onClick={() => this.handleDeleteDialog(comment)}></i></span></>
                  }

                </div>}
            </div>
          </div>
        )}
        <CustomDialog
          onClose={() => this.handleDeleteDialog()}
          onConfirm={() => this.deleteComment()}
          showDialog={this.state.showDeleteDialog}
        >
          <h5>Are you sure you want to delete this comment ?</h5>
        </CustomDialog>
      </div>

    )
  }

  onEditComment(event, comment) {
    const comments = JSON.parse(JSON.stringify(this.state.showableComments));
    const newComment = comments.find(c => c.id === comment.id);
    const fullListCopy = JSON.parse(JSON.stringify(this.state.commentList))
    const fullListComment = fullListCopy.find(c => c.id === comment.id);
    newComment.comment = draftToHtml(convertToRaw(comment.editor.getCurrentContent()))
    newComment.editor = event;
    fullListComment.comment = draftToHtml(convertToRaw(comment.editor.getCurrentContent()))
    fullListComment.editor = event


    this.setState({
      showableComments: comments
    }, () => {
      if (!event) {
        const serverCopy = JSON.parse(JSON.stringify(newComment))
        delete serverCopy.editable;
        serverCopy.updated = new Date(Date.now());
        newComment.updated = new Date().toISOString()
        fullListComment.updated = new Date().toISOString()
        this.props.dispatch(putComment(serverCopy.id, serverCopy, (response) => {
          delete newComment.editable;
          this.setState({
            showableComments: comments,
            commentList: fullListCopy
          })
        }, (error) => {
          this.editComment(comment)
        }))
      }
    })
  }


  editComment(comment) {
    const comments = JSON.parse(JSON.stringify(this.state.showableComments));
    const newComment = comments.find(c => c.id === comment.id);
    if (!comment.editable) {
      newComment['editable'] = true;
      newComment['editor'] = EditorState.createWithContent(stateFromHTML(comment.comment))
      newComment.oldValue = newComment.comment;
      comments.forEach(e => {
        if (e.id !== comment.id) {
          delete e['editable']
          delete e['editor']
          if (e.oldValue) {
            e.comment = e.oldValue
          }
        }
      })
    } else {
      delete newComment['editable']
      delete newComment['editor']
      newComment.comment = newComment.oldValue;

    }
    this.setState({
      showableComments: comments
    })
  }

  deleteComment() {
    const comment = this.state.commentToDelete;
    const comments = JSON.parse(JSON.stringify(this.state.showableComments));
    const index = comments.findIndex(c => c.id === comment.id);
    const fullListIndex = this.state.commentList.findIndex(c => c.id === comment.id);
    const fullListCopy = JSON.parse(JSON.stringify(this.state.commentList))
    this.props.dispatch(deleteComment(comment.id, (response) => {
      comments.splice(index, 1);
      fullListCopy.splice(fullListIndex, 1);
      this.setState({
        showableComments: comments,
        commentList: fullListCopy,
        showDeleteDialog: false
      })
    }))
  }

}


export const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(styles)(CommentSection));