import {
  DELETE_COMMENT_ERROR,
  DELETE_COMMENT_START,
  DELETE_COMMENT_SUCCESS,
  GET_ALL_CONTRACTS_ERROR,
  GET_ALL_CONTRACTS_START,
  GET_ALL_CONTRACTS_SUCCESS,
  GET_ASSIGNEE_LIST_ERROR,
  GET_ASSIGNEE_LIST_START,
  GET_ASSIGNEE_LIST_SUCCESS,
  GET_BID_DETAIL_ERROR,
  GET_BID_DETAIL_START,
  GET_BID_DETAIL_SUCCESS,
  GET_COMMENT_ERROR,
  GET_COMMENT_START,
  GET_COMMENT_SUCCESS,
  GET_CONTRACT_LIST_START,
  GET_CONTRACT_LIST_ERROR,
  GET_CONTRACT_STATUS_ERROR,
  GET_CONTRACT_STATUS_START,
  GET_CONTRACT_STATUS_SUCCESS,
  GET_OPEN_CONTRACT_LIST_SUCCESS,
  GET_PAYMENT_CONTRACT_LIST_SUCCESS,
  POST_COMMENT_ERROR,
  POST_COMMENT_START,
  POST_COMMENT_SUCCESS,
  POST_CREATE_CONTRACT_ERROR,
  POST_CREATE_CONTRACT_START,
  POST_CREATE_CONTRACT_SUCCESS,
  PUT_AWARDED_BID_ERROR,
  PUT_AWARDED_BID_START,
  PUT_AWARDED_BID_SUCCESS,
  PUT_COMMENT_ERROR,
  PUT_COMMENT_START,
  PUT_COMMENT_SUCCESS,
  PUT_CONTRACT_STATUS_ERROR,
  PUT_CONTRACT_STATUS_START,
  PUT_CONTRACT_STATUS_SUCCESS,
  UPDATE_CONTRACT_ERROR,
  UPDATE_CONTRACT_START,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_STORY_STATUS_ERROR,
  UPDATE_STORY_STATUS_START,
  UPDATE_STORY_STATUS_SUCCESS,
} from "./constant";
import {UPDATE_CONTRACT_SUCCESS_MSG} from "../common/constants";
import {
  deleteCommentAPI,
  getAllContractsAPI,
  getAssigneeListAPI,
  getBidDetails,
  getCommentAPI,
  getContractListAPI,
  getContractNoOfBidAPI,
  getContractStatusAPI,
  postCommentAPI,
  postCreateContractAPI,
  putAwardedBidAPI,
  putCommentAPI,
  updateContract,
  updateContractStatusAPI,
  updateStoryAPI
} from "../api/contractsApi";
import {getUsersInfoAPI} from "../api/usersApi";
import {getUserData} from "../utils/common";
import _ from "lodash-es";

const requestContractList = () => ({
  type: GET_CONTRACT_LIST_START,
});

const receiveContractListSuccess = (json, forOpenContract) => {
  if (forOpenContract) {
    return {
      type: GET_OPEN_CONTRACT_LIST_SUCCESS,
      payload: json
    };
  }
  return {
    type: GET_PAYMENT_CONTRACT_LIST_SUCCESS,
    payload: json
  };
};

const recieveContractListError = error => ({
  type: GET_CONTRACT_LIST_ERROR,
  errorMsg: error.message
});

export const getContractList = (query = '', isContractScreen = false, isPaymentScreen = false) => {
  return async (dispatch) => {
    dispatch(requestContractList());
    try {
      const response = await getContractListAPI(query);
      const userData = getUserData();
      if (response.data) {
        // && userData.userRoles.includes("Admin")
        if (userData.userRoles && userData.userRoles.length) {
          //Get list of Owner ID
          const ownerIDArr = response.data.map(
            (val, i) => {
              if (val.owner !== null) {
                return val.owner;
              }
            });

          if (ownerIDArr && ownerIDArr.length > 0) {
            const ownerIDArrWithoutUndefined = _.without(ownerIDArr, undefined);
            const uniqueUsersArr = _.uniq(ownerIDArrWithoutUndefined);
            let userResponse = {};
            if (uniqueUsersArr.length > 0) {
              const query = '?ids=' + uniqueUsersArr.join(',');
              userResponse = await getUsersInfoAPI(query);
            }

            if (userResponse.data) {
              //Add users info on projects
              const newResponse = response.data.map(
                (val, i) => {
                  let userInfo = _.find(userResponse.data, {'_id': val.owner});
                  val.userInfo = userInfo;
                  return val;
                });
              const newBid = []
              for (const i in newResponse) {
                let bidRequest = 0, bidReceived = undefined, userDetails = undefined;
                if (isContractScreen) {
                  // bidReceived = await getBidDetails(newResponse[i].id);
                } else if (isPaymentScreen) {
                  userDetails = _.find(userResponse.data, {'_id': newResponse[i].isAssigned && newResponse[i].assigned.length && newResponse[i].assigned[0].userId});
                }
                if (newResponse[i] && newResponse[i].visible && newResponse[i].visible[0] && newResponse[i].visible[0].type === "team") {
                  bidRequest = await getContractNoOfBidAPI(newResponse[i].visible[0].id)
                  bidRequest = (bidRequest && bidRequest.data && bidRequest.data.teamMembers && bidRequest.data.teamMembers.length)
                }
                if (newResponse[i] && newResponse[i].visible && newResponse[i].visible[0] && newResponse[i].visible[0].type === "Individual_User") {
                  bidRequest = 1
                }
                const data = {
                  ...newResponse[i],
                  noOfBidRequest: bidRequest,
                  bidReceived: bidReceived && bidReceived.data.length,
                  userDetails: userDetails && (userDetails.firstName + " " + userDetails.lastName)
                };
                newBid.push(data)
              }
              dispatch(receiveContractListSuccess(newBid, isContractScreen));
            } else {
              dispatch(receiveContractListSuccess(response.data, isContractScreen));
            }
          } else {
            dispatch(receiveContractListSuccess(response.data, isContractScreen));
          }
        } else {
          dispatch(receiveContractListSuccess(response.data, isContractScreen));
        }
      }
    } catch (error) {
      dispatch(recieveContractListError(error));
    }
  }
};


const putContractStatusStart = () => ({
  type: PUT_CONTRACT_STATUS_START,
});
const putContractStatusSuccess = (data) => ({
  type: PUT_CONTRACT_STATUS_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const putContractStatusError = (error) => ({
  type: PUT_CONTRACT_STATUS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateContractStatus = (contractId, data, callback) => {
  return async (dispatch) => {
    dispatch(putContractStatusStart());
    try {
      const response = await updateContractStatusAPI(contractId, data);
      dispatch(putContractStatusSuccess(response));
      callback && callback();
      return response;
    } catch (error) {
      dispatch(putContractStatusError(error));
      return error;
    }
  }
};


const updateContractStart = () => ({
  type: UPDATE_CONTRACT_START,
});
const updateContractSuccess = (data) => ({
  type: UPDATE_CONTRACT_SUCCESS,
  successMsg: UPDATE_CONTRACT_SUCCESS_MSG,
  payload: data
});
const updateContractError = (error) => ({
  type: UPDATE_CONTRACT_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateContractModal = (data, userId, callback) => {
  return async (dispatch) => {
    dispatch(updateContractStart());
    try {
      const response = await updateContract(data, userId);
      dispatch(updateContractSuccess(response));
      callback && callback();
      return response;
    } catch (error) {
      dispatch(updateContractError(error));
      return error;
    }
  }
};


const getAssigneeListStart = () => ({
  type: GET_ASSIGNEE_LIST_START,
});
const getAssigneeListSuccess = (data) => ({
  type: GET_ASSIGNEE_LIST_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const getAssigneeListError = (error) => ({
  type: GET_ASSIGNEE_LIST_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getAssigneeList = (contractId, callback) => {
  return async (dispatch) => {
    dispatch(getAssigneeListStart());
    try {
      const response = await getAssigneeListAPI(contractId, callback);
      callback && callback(response);
      dispatch(getAssigneeListSuccess(response));
      return response;
    } catch (error) {
      dispatch(getAssigneeListError(error));
      return error;
    }
  }
};

// Get Bid Details 

const getBidDetailStart = () => ({
  type: GET_BID_DETAIL_START,
});
const getBidDetailSuccess = (data) => ({
  type: GET_BID_DETAIL_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const getBidDetailError = (error) => ({
  type: GET_BID_DETAIL_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getBidDetail = (contractId, callback) => {
  return async (dispatch) => {
    dispatch(getBidDetailStart());
    try {
      const response = await getBidDetails(contractId, callback);
      callback && callback(response.data);
      dispatch(getBidDetailSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getBidDetailError(error));
      return error;
    }
  }
};

const getCommentStart = () => ({
  type: GET_COMMENT_START,
});
const getCommentSuccess = (data) => ({
  type: GET_COMMENT_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const getCommentError = (error) => ({
  type: GET_COMMENT_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getComment = (contractId, callback) => {
  return async (dispatch) => {
    dispatch(getCommentStart());
    try {
      const response = await getCommentAPI(contractId, callback);
      dispatch(getCommentSuccess(response));
      callback && callback(response);
      return response;
    } catch (error) {
      dispatch(getCommentError(error));
      return error;
    }
  }
};


const postCommentStart = () => ({
  type: POST_COMMENT_START,
});
const postCommentSuccess = (data) => ({
  type: POST_COMMENT_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const postCommentError = (error) => ({
  type: POST_COMMENT_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const postComment = (contractId, data, callback) => {
  return async (dispatch) => {
    dispatch(postCommentStart());
    try {
      const response = await postCommentAPI(contractId, data);
      dispatch(postCommentSuccess(response));
      callback && callback(response);
      return response;
    } catch (error) {
      dispatch(postCommentError(error));
      return error;
    }
  }
};

const putCommentStart = () => ({
  type: PUT_COMMENT_START,
});
const putCommentSuccess = (data) => ({
  type: PUT_COMMENT_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const putCommentError = (error) => ({
  type: PUT_COMMENT_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const putComment = (contractId, data, callback) => {
  return async (dispatch) => {
    dispatch(putCommentStart());
    try {
      const response = await putCommentAPI(contractId, data);
      dispatch(putCommentSuccess(response));
      callback && callback(response);
      return response;
    } catch (error) {
      dispatch(putCommentError(error));
      return error;
    }
  }
};

const deleteCommentStart = () => ({
  type: DELETE_COMMENT_START,
});
const deleteCommentSuccess = (data) => ({
  type: DELETE_COMMENT_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const deleteCommentError = (error) => ({
  type: DELETE_COMMENT_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const deleteComment = (commentId, callback) => {
  return async (dispatch) => {
    dispatch(deleteCommentStart());
    try {
      const response = await deleteCommentAPI(commentId);
      dispatch(deleteCommentSuccess(response));
      callback && callback(response);
      return response;
    } catch (error) {
      dispatch(deleteCommentError(error));
      return error;
    }
  }
};

const putAwardedBidStart = () => ({
  type: PUT_AWARDED_BID_START,
});
const putAwardedBidSuccess = (data) => ({
  type: PUT_AWARDED_BID_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const putAwardedBidError = (error) => ({
  type: PUT_AWARDED_BID_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ''
});

export const putAwardedBid = (bid, id, callback) => {
  return async (dispatch) => {
    dispatch(putAwardedBidStart());
    try {
      const response = await putAwardedBidAPI(bid, id);
      dispatch(putAwardedBidSuccess(response));
      callback && callback(response);
      return response;
    } catch (error) {
      dispatch(putAwardedBidError(error));
      return error;
    }
  }
};

const getContractStatusStart = () => ({
  type: GET_CONTRACT_STATUS_START,
});
const getContractStatusSuccess = (data) => ({
  type: GET_CONTRACT_STATUS_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const getContractStatusError = (error) => ({
  type: GET_CONTRACT_STATUS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getContractStatus = (callback) => {
  return async (dispatch) => {
    dispatch(getContractStatusStart());
    try {
      const response = await getContractStatusAPI(callback);
      dispatch(getContractStatusSuccess(response));
      callback && callback(response);
      return response;
    } catch (error) {
      dispatch(getContractStatusError(error));
      return error;
    }
  }
};


const postCreateContractStart = () => ({
  type: POST_CREATE_CONTRACT_START,
});
const postCreateContractSuccess = (data) => ({
  type: POST_CREATE_CONTRACT_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const postCreateContractError = (error) => ({
  type: POST_CREATE_CONTRACT_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const postCreateContract = (data, callback) => {
  return async (dispatch) => {
    dispatch(postCreateContractStart());
    try {
      const response = await postCreateContractAPI(data);
      dispatch(postCreateContractSuccess(response));
      callback && callback(response);
      return response;
    } catch (error) {
      dispatch(postCreateContractError(error));
      return error;
    }
  }
};

const updateStoryStatusStart = () => ({
  type: UPDATE_STORY_STATUS_START,
});
const updateStoryStatusSuccess = (data) => ({
  type: UPDATE_STORY_STATUS_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const updateStoryStatusError = (error) => ({
  type: UPDATE_STORY_STATUS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateStoryStatus = (storyId, sprintId, projectId, data, callback) => {
  return async (dispatch) => {
    dispatch(updateStoryStatusStart());
    try {
      const response = await updateStoryAPI(storyId, sprintId, projectId, data);
      dispatch(updateStoryStatusSuccess(response));
      callback && callback();
      return response;
    } catch (error) {
      dispatch(updateStoryStatusError(error));
      return error;
    }
  }
};


const getAllContractsStart = () => ({
  type: GET_ALL_CONTRACTS_START,
});
const getAllContractsSuccess = (data) => ({
  type: GET_ALL_CONTRACTS_SUCCESS,
  //successMsg: PUT_CONTRACT_STATUS_SUCCESS_MSG,
  payload: data
});
const getAllContractsError = (error) => ({
  type: GET_ALL_CONTRACTS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getAllContracts = (storyId, callback) => {
  return async (dispatch) => {
    dispatch(getAllContractsStart());
    try {
      const response = await getAllContractsAPI(storyId);
      dispatch(getAllContractsSuccess(response));
      callback && callback();
      return response;
    } catch (error) {
      dispatch(getAllContractsError(error));
      return error;
    }
  }
};