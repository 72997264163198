import React from 'react';
import ReactDOM from 'react-dom';
// import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import store from './store';
// import {history} from './store'
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import AuthenticatedRoutes from 'components/AuthenticatedRoutes/AuthenticatedRoutes.jsx';
import { indexRoutes, privateRoutes } from 'routes/index.jsx';

import AuthLayout from 'layouts/Auth.js';
import RtlLayout from 'layouts/RTL.js';
import AdminLayout from 'layouts/Admin.js';

import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './global.scss';

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    <Router>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return (
            <Route path={prop.path} key={key} component={prop.component} />
          );
        })}
        <AuthenticatedRoutes>
          {privateRoutes.map((prop, key) => {
            return (
              <Route path={prop.path} key={key} component={prop.component} />
            );
          })}
        </AuthenticatedRoutes>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);
