import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import { connect } from "react-redux";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import CardIcon from "components/Card/CardIcon.js";
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import EventIcon from '@material-ui/icons/Event';
import { addDiscountManagement } from "actions/discount";
import moment from 'moment';
import { toast } from "react-toastify";
import { updateDiscountManagement } from "actions/discount";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SaveVoucher = (props) => {
  const { modalState, closeModal } = props;
  const [largeModal, setLargeModal] = React.useState(modalState);
  const [name, setVoucherName] = React.useState(modalState.id ? modalState.name : '');
  const [voucherNameState, setVoucherNameState] = React.useState(modalState.id ? true : "");
  const [expiryDate, setExpiryDate] = React.useState(modalState.id ? moment(new Date(moment(modalState.createdAt).add(modalState.expiry, 'days'))).format('L') : '');
  const [expiryDateState, setExpiryDateState] = React.useState(modalState.id ? true : "");
  const [amount, setAmount] = React.useState(modalState.id ? modalState.amount : '');
  const [amountState, setAmountState] = React.useState(modalState.id ? true : "");
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const dateInp = React.createRef();

  const closeModalHandler = () => {
    setLargeModal(false);
    closeModal();
  };

  const openCal = () => {
    dateInp.current.openCalendar()
  }

  const registerClick = () => {
    name ? setVoucherNameState(true) : setVoucherNameState(false);
    expiryDate ? setExpiryDateState(true) : setExpiryDateState(false);
    amount ? setAmountState(true) : setAmountState(false);
    setLoading(true);

    if (voucherNameState && amountState) {
      if (!modalState.id) {
        let expireDays = 0;
        if (moment(new Date()).format('MM/DD/YYYY') === moment(expiryDate || new Date()).format('MM/DD/YYYY')) {
          expireDays = 0;
        } else {
          expireDays = moment(expiryDate).diff(new Date(Date.now()), 'days') + 1;
        }

        props.dispatch(addDiscountManagement({
          amount: amount,
          expiry: expireDays,
          name: name
        }, () => {
          toast.success("Voucher added successfully")
          modalState.callback && modalState.callback();
          setLargeModal(false);
          closeModal({ name, expiryDate, amount, close: true });
          setLoading(false);
        }));
      } else {
        let expireDays = 0;
        if (moment(modalState.createdAt).format('MM/DD/YYYY') === moment(expiryDate).format('MM/DD/YYYY')) {
          expireDays = 0;
        } else {
          expireDays = moment(expiryDate).diff(new Date(modalState.createdAt), 'days') + 1;
        }
        props.dispatch(updateDiscountManagement({
          amount: amount,
          expiry: expireDays,
          name: name
        }, modalState.id, () => {
          toast.success("Voucher updated successfully")
          modalState.callback && modalState.callback();
          setLargeModal(false);
          closeModal({ name, expiryDate, amount, close: true });
          setLoading(false);
        }))
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={largeModal}
        transition={Transition}
        keepMounted
        onClose={closeModalHandler}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        className={classes.dialogBoxy}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={closeModalHandler}
          >
            <Close className={classes.modalClose} />
          </Button>

          <CardIcon className={classes.tag} color="info">
            <LocalActivityIcon />
          </CardIcon>

          <h4 className={classes.modalTitle}>{modalState.id ? 'Edit Voucher' : 'Add Voucher'}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                success={voucherNameState}
                error={voucherNameState === false}
                labelText="Discount name"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  defaultValue: name,
                  onChange: event => {
                    event.target.value ? setVoucherNameState(true) : setVoucherNameState(false);
                    setVoucherName(event.target.value);
                  },
                  type: "text"
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl
                fullWidth
                success={expiryDateState}
                error={expiryDateState === false}>
                <div className={classes.dateLogo}>
                  <div className={classes.dateInput}>
                    <Datetime
                      defaultValue={expiryDate || Date.now()}
                      timeFormat={false}
                      onChange={(event) => {
                        setExpiryDate(event);
                      }}
                      ref={dateInp}
                      inputProps={{
                        placeholder: "Expire Date"
                      }}/>
                  </div>
                  <EventIcon onClick={()=>openCal()}/>
                </div>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                success={amountState}
                error={amountState === false}
                labelText="Discount Amount($)"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  defaultValue: modalState.id ? amount / 100 : amount,
                  onChange: event => {
                    event.target.value ? setAmountState(true) : setAmountState(false);
                    setAmount(event.target.value);
                  },
                  type: "number"
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterClr}>
          <Button color='transparent' round onClick={closeModalHandler}>Cancel</Button>
          <Button color='info' round className={classes.btn} disabled={loading} onClick={registerClick}>
            {modalState.id ? 'Update' : 'Generate'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStatetoProps = state => state;

export default connect(mapStatetoProps)(SaveVoucher);
