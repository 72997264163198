import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardFooter from "components/Card/CardFooter.js";
import PropTypes from 'prop-types';

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

import image from "assets/img/bg7.jpg";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { connect } from "react-redux";
// import NavigationBar from "components/inc/NavigationBar.jsx";
import { login } from "actions/login";

import styles from "assets/jss/customStyle.jsx";
import Footer from "components/Footer/Footer.js";
import { isLoggedIn, getUserData } from "utils/common";
import Clearfix from "components/Clearfix/Clearfix";
import { Link } from "@material-ui/core";
import { toast } from "react-toastify";
import { forgotPassword } from "actions/login";
import LoadingOverlay from 'react-loading-overlay';

// import LoadingComponent from "../../components/inc/LoadingComponent";


export class ForgotPassword extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      cardHidden: {
        cardAnimaton: ''
      },
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      isLoggedIn: false,
      erMessage: "",
      isLoading: false
    }
    this.emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ;
  }

  

  handleSubmit() {
    this.setState({
      isLoading: true
    })

    if(this.state.email){
      // const userData = getUserData(); 
      this.props.dispatch(forgotPassword({
        userRole:'Admin',
        resend:true,
        frontpanel:'adminpanel',
        email: this.state.email
      }, () => {
        this.setState({
          isLoading: false
        })
        toast.success("If your email address is registered / exists, password reset email link will be sent to that email address")
      }));
    
  }
    else{
      toast.error("Invalid E-Mail")
    }
  }

  handleUserInput = (e) => {
    // const name = e.target.name;
    // const value = e.target.value;
    // this.setState({ [name]: value });
  }

  

  handleUserValidation = (event, type = 'email') => {
    // if (type === 'password') {
    //   if (!event.target.value) {
    //     this.setState({ passwordError: true });
    //   } else {
    //     this.setState({ passwordError: false });
    //   }
    // } 
    
      if (!event.target.value) {
        this.setState({ emailError: true });
      } else {
        const isValidEmail = event.target.value.match(this.emailPattern);
        if (isValidEmail) {
          this.setState({ emailError: false, email: event.target.value });

          // localStorage.setItem('email', event.target.value);
        } else {
          this.setState({ emailError: true });
        }
      }
    
  };

  onClose = () => {
    this.setState({ erMessage: "" })
  }
  
  render() {
  const {cardAnimaton} = this.state.cardHidden;
  const { classes } = this.props;
  return (
    <div className={classes.container}>
    {this.state.erMessage ?
          <div>
            <SnackbarContent
              message={
                <span>
                  <b>ERROR:</b> {this.state.erMessage}
                </span>
              }
              customNotificationClear={this.onClose}
              color="danger"
              close={true}
              icon="info_outline"
            />
            <Clearfix />
          </div> : null
        }
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Forgot Password</h4>
              </CardHeader>
              <CardBody>
               <CustomInput
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "Email...",
                    type: "email",
                    name: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (event) => this.handleUserInput(event),
                    onBlur: (event) => this.handleUserValidation(event)
                  }}
                  value={this.state.email}
                  error={this.state.emailError ? true : false}
                />
               
              </CardBody>
              
              <CardFooter className={classes.justifyContentCenter}>
              <LoadingOverlay
                            active={this.state.isLoading}
                            spinner
                            text='Loading...'
                        >
                <Button simple color="success" size="lg" onClick={() => this.handleSubmit()}>
                  Submit
                </Button>
                </LoadingOverlay>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
     
    </div>
  );
}
}

// const mapStateToProps = state => ({
//   userData: state.login.auth
// });

// const mapDispatchToProps = dispatch => ({
//   login: (data) => dispatch(login(data)),
// });

// LoginPage.contextTypes = {
//   router: PropTypes.object.isRequired
// };

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(loginPageStyle)(ForgotPassword));