import React, { Component } from 'react';
import { makeStyles } from "@material-ui/core/styles";

import Table from "components/Table/Table";
import Visibility from "@material-ui/icons/Visibility";
import Create from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import sprintEditStyle from "assets/jss/material-dashboard-pro-react/views/sprintEditStyle.js";
import {
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";
import Card from "components/Card/Card";
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from "components/CustomButtons/Button.js";
import Pagination from "components/Pagination/Pagination.js";
import { connect } from 'react-redux';
import { getProjectList } from 'actions/project';
import { card } from 'assets/jss/material-dashboard-pro-react';
import CardFooter from 'components/Card/CardFooter';
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Select from "@material-ui/core/Select";

import GridItem from "components/Grid/GridItem.js";
import { getSprintDetails } from 'actions/sprint';
import { getAllSprintList } from 'actions/sprint';
import { getUserStories } from 'actions/userStories';
import { from } from 'rxjs';
import userStories from 'reducers/userStories';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { developmentStatus, acceptanceStatus, sprintStatus, complexity } from "common/constants";
import Icon from "@material-ui/core/Icon";
import { updateUserStories } from 'actions/userStories';
import { updateSprint } from 'actions/sprint';
import { BreadCrumb } from 'utils/common';
import LoadingOverlay from '../../../node_modules/react-loading-overlay';

class SprintEdit extends Component {



  constructor(props) {
    super(props);
    this.props.dispatch(getProjectList());

    this.state = {
      sprintEditList: undefined,
      complexities: ['High', 'Low']
    }
    if (this.props.match.url.includes('viewSprint')) {
      this.isViewOnly = true;
    }
    this.props.dispatch(getSprintDetails(this.props.match.params.projectId, this.props.match.params.sprintId));
    this.props.dispatch(getAllSprintList(this.props.match.params.projectId));
    this.props.dispatch(getUserStories(this.props.match.params.projectId, this.props.match.params.sprintId));

  }



  componentWillReceiveProps(nextProps) {
    console.log('apiresponse', nextProps)
    if (nextProps.sprint.data &&
        nextProps.sprint.sprintInfo &&
        nextProps.sprint.sprintInfo._id &&
        nextProps.userStories &&
        nextProps.userStories.userStoriesList) {
      this.setState({
        sprintInfo: nextProps.sprint.sprintInfo,
        storyList: nextProps.userStories.userStoriesList,
        sprintList: nextProps.sprint.data
      })
    }

    // if (this.submitted && nextProps.userStories.userStoriesUpdateData && nextProps.sprint.sprintSavedData) {
    //   this.submitted = false;
    //   this.gotoSprintPage();
    // }
  }


  gotoSprintPage() {
    this.props.history.goBack()
  }


  gotoUpdate() {
    const { storyList, sprintInfo } = this.state;
    this.loading = true;
    this.props.dispatch(updateSprint({title: sprintInfo.title, sprintStatus: sprintInfo.sprintStatus}, this.props.match.params.projectId, this.props.match.params.sprintId, () => {
      this.props.dispatch(updateUserStories(storyList, this.props.match.params.projectId, this.props.match.params.sprintId, () => {
        this.submitted = false;
        this.loading = false
        this.gotoSprintPage();
      }));
    }))
    this.submitted = true;
  }





  onSprintChange(type, event, i) {
    event.persist();
    let storyList;
    switch (type) {
      case 'title':
        this.setState({ sprintInfo: { ...this.state.sprintInfo, title: event.target.value } })
        break;
      case 'status':
        this.setState({ sprintInfo: { ...this.state.sprintInfo, sprintStatus: { status: event.target.value } } })
        break;
      case 'complex':
        storyList = this.state.storyList;
        storyList[i] = { ...storyList[i], complexity: event.target.value };
        this.setState({ storyList: storyList });
        break;
      case 'develop':
        storyList = this.state.storyList;
        storyList[i] = { ...storyList[i], development: { status: event.target.value } };
        this.setState({ storyList: storyList });
        break;
      case 'accept':
        storyList = this.state.storyList;
        storyList[i] = { ...storyList[i], acceptance: { status: event.target.value } };
        this.setState({ storyList: storyList });
        break;
      case 'move':
        storyList = this.state.storyList;
        storyList[i].moveTo = event.target.value;
        this.setState({ storyList: storyList });
        break;
      case 'cost change':
         storyList= this.state.storyList;
         storyList[i] = { ...storyList[i], creditCost: event.target.value * 1};
         this.setState({storyList: storyList});
    }
  }




  render() {
    const { classes } = this.props;
    const { sprintInfo, storyList, sprintList } = this.state;
    const isFetching = this.props.project.isFetching;
    const loadingComp = <div className={classes.load}>Loading</div>

    let storyComp, data;
    if (storyList) {
      storyComp = storyList.map((story, i) => {
        return (
          <>
            <GridContainer justify="flex-end">
              <GridItem xs={12} sm={12} md={3}></GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <div className={classes.headings}>
                  USER STORY&nbsp;
                  {i + 1}/{storyList.length}&nbsp;:&nbsp;{story.title}
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem xs={12} sm={12} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Complexity
              </FormLabel>
              </GridItem>
              <GridItem className={classes.selectPadding} xs={12} sm={12} md={9}>
                <Select onChange={(event) => this.onSprintChange('complex', event, i)}
                  disabled={this.isViewOnly} value={story.complexity} className={classes.slSize} MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}>
                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Choose Complexity</MenuItem>
                  {
                    complexity.map(e => <MenuItem value={e.value} classes={{ root: classes.selectMenuItem }}>{e.name}</MenuItem>)
                  }
                </Select>
              </GridItem>
            </GridContainer>


            <GridContainer justify="flex-end">
              <GridItem xs={12} sm={12} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Development Status
                </FormLabel>
              </GridItem>
              <GridItem className={classes.selectPadding} xs={12} sm={12} md={9}>
                <Select disabled={this.isViewOnly} onChange={(event) => this.onSprintChange('develop', event, i)} value={story.development.status} className={classes.slSize} MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}>
                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Choose Development Status</MenuItem>
                  {
                    developmentStatus.map(e => <MenuItem value={e.value} classes={{ root: classes.selectMenuItem }}>{e.name}</MenuItem>)
                  }
                </Select>
              </GridItem>
            </GridContainer>


            <GridContainer justify="flex-end">
              <GridItem xs={12} sm={12} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Acceptance Status
            </FormLabel>
              </GridItem>
              <GridItem className={classes.selectPadding} xs={12} sm={12} md={9}>

                <Select disabled={this.isViewOnly} onChange={(event) => this.onSprintChange('accept', event, i)} value={story.acceptance.status} className={classes.slSize} MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}>

                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Choose Acceptance Status</MenuItem>
                  {
                    acceptanceStatus.map(e => <MenuItem value={e.value} classes={{ root: classes.selectMenuItem }}>{e.name}</MenuItem>)
                  }
                </Select>
              </GridItem>
            </GridContainer>



            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Story Credit Cost
            </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={12} md={9}>
                <CustomInput inputProps={{ disabled: this.isViewOnly, defaultValue: story.creditCost, type: "number", onChange: (event) => this.onSprintChange('cost change', event, i) }} />

              </GridItem>
            </GridContainer>


            {!this.isViewOnly && <GridContainer className={classes.bottom} justify="flex-end">
              <GridItem xs={12} sm={12} md={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Move User Body to Sprint
            </FormLabel>
              </GridItem>
              <GridItem className={classes.selectPadding} xs={12} sm={12} md={9}>

                <Select value={story.moveTo} onChange={(event) => this.onSprintChange('move', event, i)} className={classes.slSize}
                  MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }}

                >

                  <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Sprints</MenuItem>
                  {
                    sprintList.filter(s => s._id !== this.props.match.params.sprintId).map(e => <MenuItem value={e._id} classes={{ root: classes.selectMenuItem }}>{e.title}</MenuItem>)
                  }
                </Select>


              </GridItem>
            </GridContainer>}
            <hr />
          </>
        )
      })
    }



    return (
      <div>
        <BreadCrumb pathArray={[
          { name: 'Requestor' },
          { name: 'Projects', path: '/admin/projects' },
          { name: localStorage.getItem('projectSelected'), path: `/admin/sprints/${this.props.match.params.projectId}` },
          { name: 'Update' },
        ]} />
         <LoadingOverlay
                     active={this.loading || isFetching}
                     spinner
                     text= {this.loading ? 'Loading...' : 'Updating...'}
                >
        <Card>
          {
            !isFetching && <div>
              <CardHeader color="info" className={classes.cardHeader}>
                <Button justIcon color='transparent' onClick={() => this.gotoSprintPage()}>
                  <ArrowBackIcon />
                </Button>
                <h4 className={classes.cardTitle}>
                  {this.isViewOnly ? '' : 'Update'} Sprint and User Story Status
                </h4>
              </CardHeader>



              <CardBody>
                {sprintInfo && sprintInfo._id && <form>


                  <GridContainer justify="flex-end">
                    <GridItem xs={12} sm={12} md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={9}>

                      <div className={classes.headings}>SPRINT DETAILS</div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Title
                  </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <CustomInput disabled={this.isViewOnly} inputProps={{ disabled: this.isViewOnly, defaultValue: sprintInfo.title, type: "text", onChange: (event) => this.onSprintChange('title', event) }} formControlProps={{ fullWidth: true }} />
                    </GridItem>
                  </GridContainer>


                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Sprint Status
                  </FormLabel>
                    </GridItem>
                    <GridItem className={classes.selectPadding} xs={12} sm={12} md={9}>

                      <Select disabled={this.isViewOnly} onChange={(event) => this.onSprintChange('status', event)} value={sprintInfo.sprintStatus.status} className={classes.slSize} MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }}>

                        <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Choose Sprint Status</MenuItem>
                        {
                          sprintStatus.map(e => <MenuItem value={e.value} classes={{ root: classes.selectMenuItem }}>{e.name}</MenuItem>)
                        }
                      </Select>
                    </GridItem>
                  </GridContainer>
                  <hr />
                  {/* <div className={classes.headings}>USER STORY</div> */}
                  {storyComp}
                </form>
                }
              </CardBody>
              {!this.isViewOnly && <CardFooter className={classes.btnStyle}>
                <Button color='transparent' round onClick={() => this.gotoSprintPage()}>Cancel</Button>
                <Button color='info' round onClick={() => this.gotoUpdate()}>Update</Button>
              </CardFooter>}
            </div>
          }
          {/* {(isFetching || this.loading) && loadingComp} */}
        </Card>
        </LoadingOverlay>
      </div>
    );


  }
}

const mapStateToProps = state => state;


export default connect(mapStateToProps)(withStyles(sprintEditStyle)(SprintEdit));