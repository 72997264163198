
const CommentSectionStyle = {
    oldComment: {
        justifyContent: "center",
        display: 'flex',
        fontSize: '15px',
        textDecoration: 'underline',
        color: '#00acc1'
    },
    userImg: {
        backgroundImage: `url(${require('./../../../img/user.png')})`,
        height: '20px',
        width: '20px',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    commentWrapper: {
        display: 'flex',
        marginTop: '10px'
    },
    imgWrapper: {
        marginRight: '5px',
        marginTop: '10px'
    },
    user: {
        fontSize: '15px',
        color:'#00acc1'
    },
    date: {
        fontSize: '12px'
    },
    commentBody: {
        marginTop: '5px'
    },
    badge: {
        color: 'white',
        backgroundColor: '#00acc1',
        borderRadius: '5px',
        fontSize: '10px',
        marginLeft: '5px',
        padding: '0 5px'
        // height: '10px',
        // width: '30px'
    },
    commentEdit: {
        textAlign: 'right',
        color: '#00acc1'
    },
    editorSave: {
        marginRight: '15px'
    },
    clickable: {
        "&:hover": {
            textDecoration: 'underline'
          },
        cursor:'pointer'
    },
    totalCommentSection: {
        width: '100%',
        position: 'relative'
    },
    iconWrapper: {
        position: 'absolute',
        right: '0',
        top: '0',
        bottom: '0',
        display: 'flex',
        alignItems: 'center'
    },
    iconStyle: {
        color: '#00acc1',
        padding: '10px',
        cursor: 'pointer'
    },
    editButtons:{
        textAlign: 'right',
        color: '#00acc1',
        zIndex: 10,
        position: 'relative'
    },
    buttonStyle: {
        padding: '0px 10px'
    },
    updateTag: {
        marginLeft: '10px'
    }
}

export default CommentSectionStyle;