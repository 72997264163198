import axios from "axios";
import { BASE_URL } from "../common/constants";
import { getToken, getUserId } from "../utils/common";


export async function getMargins() {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  
  let url = `${BASE_URL}contracts/aiders/margins`;
  const response = await axios.get(url, config);
  return response.data;

}

export async function updateMarginAPI(marginId, data) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}contracts/aiders/margins/${marginId}`;
  const response = await axios.put(url, data, config);
  return response.data;

}

export async function getContractMargins(contractId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}contracts/${contractId}/margins`;
  const response = await axios.get(url, config);
  return response.data;

}

export async function updateContractMargins(contractId, data) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/${contractId}/margins/override`;
  const response = await axios.put(url, data, config);
  return response.data;
}