import React, { Component } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
  } from 'reactstrap';
  
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import FilePopUpStyle from 'assets/jss/material-dashboard-pro-react/FilePopUpStyle';
import Button from './../CustomButtons/Button'


class FilePopUp extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             animating: false,
             activeIndex: this.props.activeIndex ? this.props.activeIndex : 0
        }
    }

   
      
    

    next = () => {
        const {animating, activeIndex} = this.state;
        if (animating) return;
        const nextIndex = activeIndex === this.props.fileList.length - 1 ? 0 : activeIndex + 1;
        this.setState({
            activeIndex: nextIndex
        })
      }
    previous = () => {
        const {animating, activeIndex} = this.state;
        if (animating) return;
        const nextIndex = activeIndex === 0 ? this.props.fileList.length - 1 : activeIndex - 1;
        this.setState({
            activeIndex: nextIndex
        })
    }
    
    goToIndex = (newIndex) => {
        const {animating} = this.state;
        if (animating) return;
        this.setState({
            activeIndex: newIndex
        })  
    }

    downloadFile() {
        const a = document.createElement('a');
        a.href = this.props.fileList[this.state.activeIndex].value;
        a.download = true;
        a.click();
    }
    isFileTypeImage(url) {
        const imageExtensions = ['jpg', 'png', 'jpeg', 'gif'];
        return url && url.split('.').reverse()[0] && imageExtensions.indexOf(url.split('.').reverse()[0]) > -1;
    }
    render() {
        const {classes, fileList, onClosePopUp, activeIndex} = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.topSec}>
                    <Button color='transparent' size='sm' className={classes.btn} onClick={() => this.downloadFile()}>
                    <GetAppIcon/>
                    <span className={classes.downloadBtn}>Download</span>
                    </Button>
                    <span onClick={()=>onClosePopUp()}><CloseIcon/></span>
                </div>
                <Carousel interval={false} activeIndex={this.state.activeIndex}
                              next={() => this.next()}
                              previous={() => this.previous()}>
                    { fileList.map(item => 
                    <CarouselItem
                        onExiting={() => this.setState({animating:true})}
                        onExited={() => this.setState({animating:false})}
                    >
                       <div className={classes.itemContainer}>
                            <div className={classes.midItem}>
                                {/* <object width="100%" height="100%" data={item.value}></object> */}
                                {this.isFileTypeImage(item.value) ? 
                                <img src={item.value} width="100%"/> :
                                <iframe id="pdfviewer" src={ `http://docs.google.com/gview?embedded=true&url=${item.value}&amp;embedded=true`} frameborder="0" width="100%" height="100%"></iframe>
                                }
                                
                            </div>
                        </div>
                    </CarouselItem>
                    )
                    }
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => this.previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => this.next()} />
                </Carousel>
            </div>
        )
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps) (withStyles(FilePopUpStyle)(FilePopUp));
