import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { connect } from "react-redux";

// @material-ui/icons

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import FormLabel from "@material-ui/core/FormLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(styles);

const MarginField = (props) => {

    const classes = useStyles();
    const [margin, setMargin] = useState(props.margin);
    const [prevMargin, setPrevMargin] = useState(props.margin);

    const baseCreditsRef = useRef();
    const percentRef = useRef();

    const handleEdit = () => {
        setMargin((prevState) => ({ ...prevState, edit: true }));
    }

    const handleSave = (margin) => {
        const baseCredits = parseInt(baseCreditsRef.current.value);
        const percent = parseInt(percentRef.current.value);
        if (isValidMarginInput(baseCredits) && isValidMarginInput(percent)) {
            props.updateMargins(margin.id, baseCredits, percent);
            setMargin((prevState) => {
                setPrevMargin({ ...prevState, edit: false })
                return { ...prevState, edit: false }
            });
            
        } else {

        }
    }

    const handleCancel = () => {
        setMargin(prevMargin);
    }

    const handleMarginInputChange = (type, value) => {
        let marginValue = value !== undefined || value !== null ? value : 0;
        if(marginValue > 100){
            marginValue = 100
        }
        setMargin((prevState) => ({ ...prevState, [type]: marginValue }));
    }

    const isValidMarginInput = (value) => {
        let marginValue = parseInt(value);
        return marginValue.length != 0 && !isNaN(marginValue) && marginValue <= 100;
    }

    const { percent, baseCredits, description, edit } = margin;

    return (
        <>
            <GridContainer className={classes.selectPadding} style={{ paddingTop: '27px' }}>
                <GridItem className={classes.selectPadding} xs={12} sm={12} md={6}>
                    <FormLabel className={classes.labelHorizontal}>
                        {description}
                    </FormLabel>
                </GridItem>
                <GridItem className={classes.selectPadding} xs={12} sm={12} md={2}>
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.inputStyle
                        }}
                        inputProps={{
                            type: "number",
                            value: baseCredits,
                            readOnly: !edit,
                            inputRef: baseCreditsRef,
                            onChange: (e) => handleMarginInputChange('baseCredits', e.target.value)
                        }}
                    />
                </GridItem>
                <GridItem className={classes.selectPadding} xs={12} sm={12} md={2}>
                    <CustomInput
                        formControlProps={{
                            fullWidth: true,
                            className: classes.inputStyle
                        }}
                        inputProps={{
                            type: "number",
                            value: percent,
                            readOnly: !edit,
                            inputRef: percentRef,
                            onChange: (e) => handleMarginInputChange('percent', e.target.value)
                        }}
                    />
                </GridItem>
                <GridItem className={classes.selectPadding} xs={12} sm={12} md={2} style={{ display: "flex" }}>
                    {
                        edit ?
                            <div className="confirmIcons" style={{ display: 'flex', cursor: 'pointer' }} >
                                <CancelIcon onClick={() => handleCancel()} />
                                <CheckIcon onClick={() => handleSave(margin)} style={{ marginLeft: '10px' }} />
                            </div> :
                            <div className="editIcon" >
                                <EditIcon onClick={() => handleEdit()} />
                            </div>
                    }
                </GridItem>
            </GridContainer>
        </>
    )
};
const mapStatetoProps = state => state;

export default connect(mapStatetoProps)(MarginField);
