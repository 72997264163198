import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardFooter from "components/Card/CardFooter.js";
import PropTypes from 'prop-types';
// import resetPassword f

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

import image from "assets/img/bg7.jpg";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { connect } from "react-redux";
// import NavigationBar from "components/inc/NavigationBar.jsx";
import { login } from "actions/login";

import styles from "assets/jss/customStyle.jsx";
import Footer from "components/Footer/Footer.js";
import { isLoggedIn, getUserData } from "utils/common";
import Clearfix from "components/Clearfix/Clearfix";
import { Link } from "@material-ui/core";
import { toast } from "react-toastify";
import { resetPassword } from "actions/login";
import { validationToken } from "actions/login";
// import LoadingComponent from "../../components/inc/LoadingComponent";
import LoadingOverlay from 'react-loading-overlay';


export class ResetPassword extends Component {

  constructor(props) {
    super(props)

    this.state = {
      cardHidden: {
        cardAnimaton: ''
      },
      email: '',
      password: '',
      emailError: false,
      passwordError: true,
      cPasswordError: true,
      isLoggedIn: false,
      erMessage: "",
      isLoading: false
    }
    this.passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  }

  handleSubmit() {
    this.setState({
      isLoading: true
    })
    if (this.state.password !== this.state.confirmPassword) {
      toast.error("Both the passwords should be same")
    }
    else {
      console.log('props',this.props)
      this.props.dispatch(validationToken( window.location.search.split('&')[1].split('=')[1],(response) => {
        console.log('khkhjhhkjhhhkhhjhjhk=e=erttttttttttt=', response)
        this.props.dispatch(
          resetPassword({
            validationToken: response.data.validationToken,
            password: this.state.password,
            code: window.location.search.split('&')[1].split('=')[1]
          }, () => {
            this.setState({
              isLoading: false
            })
            toast.success('password successfully changed')
          })
        )
      }))
    }
  }

  handleUserInput = (e) => {
    // const name = e.target.name;
    // const value = e.target.value;
    // this.setState({ [name]: value });
  }

  handleUserValidation = (event, type) => {
  console.log('type', type)
    if (!event.target.value) {
      this.setState({ passwordError: 'password is required' });
    }
    else {
      const isValidPassword = event.target.value.match(this.passwordPattern);
        if (type === "password") {
          this.setState({ passwordError: !isValidPassword? "password should have minimum length of 8 characters, must have 1 number, 1 special character, 1 upper case & 1 lower case" : "", password: event.target.value });
        }
        else if (type === "confirmPassword") {
          this.setState({ cPasswordError: !isValidPassword? "Password doesn't match": "", confirmPassword: event.target.value });
        }
    }
  }


onClose = () => {
  this.setState({ erMessage: "" })
}

render() {
  const { cardAnimaton } = this.state.cardHidden;
  const { classes } = this.props;
  return (
    <div className={classes.container}>
      {this.state.erMessage ?
        <div>
          <SnackbarContent
            message={
              <span>
                <b>ERROR:</b> {this.state.erMessage}
              </span>
            }
            customNotificationClear={this.onClose}
            color="danger"
            close={true}
            icon="info_outline"
          />
          <Clearfix />
        </div> : null
      }
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Reset Password</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  id="n_pass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "New Password",
                    type: "password",
                    name: "newPassword",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className={classes.inputIconsColor}>
                          lock_utline
                        </Icon>
                      </InputAdornment>
                    ),
                    // onChange: (event) => this.handleUserInput(event),
                    onChange: (event) => this.handleUserValidation(event, 'password')
                  }}
                  value={this.state.password}
                  error={this.state.passwordError?true:false}
                  helperText={this.state.passwordError}
                
                />
                <CustomInput
                  id="c_pass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "Confirm Password",
                    type: "password",
                    name: "confirmPassword",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className={classes.inputIconsColor}>
                          lock_utline
                        </Icon>
                      </InputAdornment>
                    ),
                    // onChange: (event) => this.handleUserInput(event),
                    onChange: (event) => this.handleUserValidation(event, 'confirmPassword')
                  }}
                  value={this.state.password}
                  error={this.state.cPasswordError?true:false}
                  helperText={this.state.cPasswordError}
                />
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
              <LoadingOverlay
                            active={this.state.isLoading}
                            spinner
                            text='Loading...'
                        >
                <Button disabled={this.state.passwordError || this.state.cPasswordError} simple color="success" size="lg" onClick={() => this.handleSubmit()}>
                  Save
                </Button>
                </LoadingOverlay>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>

    </div>
  );
}
}


// const mapStateToProps = state => ({
//   userData: state.login.auth
// });

// const mapDispatchToProps = dispatch => ({
//   login: (data) => dispatch(login(data)),
// });

// LoginPage.contextTypes = {
//   router: PropTypes.object.isRequired
// };

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(loginPageStyle)(ResetPassword));