import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Input from "@material-ui/core/Input";
import { convertFromHTML, EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { makeStyles } from "@material-ui/core/";
import linkifyHtml from "linkifyjs/html";

const styles = {
  editorWrapper: {
    border: "1px solid transparent !important",
    minHeight: "100px"
  },
  editor: {
    lineHeight: "20px !important",
    fontSize: "16px",
    padding: "0px !important"
  }
};

const linkify = require("linkify-it")();

const textWithUrlFormatter = text => {
  if (text) {
    const checkContainUrl = linkify.test(text);
    if (!checkContainUrl) return text;
    else {
      return linkifyHtml(text);
    }
  }
};
const useStyles = makeStyles(styles);

const FieldRow = ({
  label,
  name,
  classes,
  value,
  onChange,
  readOnly,
  disabled,
  rte
}) => {
  const rteClasses = useStyles(styles);

  let fieldValue = value || '';

  return (
    <GridContainer style={{ marginTop: "1rem" }}>
      <GridItem xs={12} sm={12} md={3}>
        <FormLabel className={classes.labelHorizontal}>{label} :</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={12} md={5} className={classes.selectPadding}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={9}>
            {readOnly && !rte && (
              <div
                className="col-sm-8 mt-2"
                style={{ fontSize: "16px"}}
                dangerouslySetInnerHTML={{
                  __html: textWithUrlFormatter(fieldValue)
                }}
              ></div>
            )}
            {!readOnly && !rte && (
              <Input
                name={name}
                fullWidth
                value={fieldValue}
                readOnly={readOnly}
                onChange={onChange}
                multiline
                disabled={disabled}
              />
            )}
            {rte && readOnly && (
              <Editor
                wrapperClassName={rteClasses.editorWrapper}
                toolbarHidden
                editorClassName={rteClasses.editor}
                editorState={EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(fieldValue ? textWithUrlFormatter(fieldValue) : fieldValue).contentBlocks,
                    convertFromHTML(fieldValue ? textWithUrlFormatter(fieldValue) : fieldValue).entityMap
                  )
                )}
                readOnly
              />
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

const FieldsApiType = ({
  classes,
  onChange,
  readOnly,
  apiAccessibleField,
  apiArchitecture,
  apiAuthenticatedField,
  apiDatastore,
  apiDescription,
  apiRefDesc,
  apiTestDesc,
  apiTool,
  disabled
}) => (
  <>
    <FieldRow
      classes={classes}
      label={"Programming tools"}
      name={"apiTool"}
      value={apiTool}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Description"}
      name={"apiDescription"}
      value={apiDescription}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Authenticated"}
      name={"apiAuthenticatedField"}
      value={apiAuthenticatedField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"API reference"}
      name={"apiRefDesc"}
      value={apiRefDesc}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Accessible by role"}
      name={"apiAccessibleField"}
      value={apiAccessibleField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />

    <FieldRow
      classes={classes}
      label={"Architecture requirement"}
      name={"apiArchitecture"}
      value={apiArchitecture}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Datastore requirements"}
      name={"apiDatastore"}
      value={apiDatastore}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Test cases"}
      name={"apiTestDesc"}
      value={apiTestDesc}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
      rte={true}
    />
  </>
);

const FieldsFrontendType = ({
  classes,
  onChange,
  readOnly,
  frontEndDesc,
  frontEndDesignMockRef,
  frontEndMockRef,
  frontEndSwaggerField,
  frontEndTool,
  frontEndViewNameField,
  frontEndViewNavigationField,
  frontEndWorkFlow,
  disabled
}) => (
  <>
    <FieldRow
      classes={classes}
      label={"Programming tools"}
      name={"frontEndTool"}
      value={frontEndTool}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Description"}
      name={"frontEndDesc"}
      value={frontEndDesc}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
      rte={true}
    />
    <FieldRow
      classes={classes}
      label={"Mock Reference"}
      name={"frontEndMockRef"}
      value={frontEndMockRef}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Design mock Reference"}
      name={"frontEndDesignMockRef"}
      value={frontEndDesignMockRef}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Swagger reference"}
      name={"frontEndSwaggerField"}
      value={frontEndSwaggerField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />

    <FieldRow
      classes={classes}
      label={"View name"}
      name={"frontEndViewNameField"}
      value={frontEndViewNameField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"View navigation"}
      name={"frontEndViewNavigationField"}
      value={frontEndViewNavigationField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Work flow"}
      name={"frontEndWorkFlow"}
      value={frontEndWorkFlow}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
      rte={true}
    />
  </>
);

const FieldsDesignType = ({
  classes,
  onChange,
  readOnly,
  designActionElement,
  designControlField,
  designDesc,
  designElement,
  designMock,
  designTool,
  designViewNameField,
  designViewNavigationField,
  disabled
}) => (
  <>
    <FieldRow
      classes={classes}
      label={"Programming tools"}
      name={"designTool"}
      value={designTool}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Description"}
      name={"designDesc"}
      value={designDesc}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
      rte={true}
    />
    <FieldRow
      classes={classes}
      label={"Mock reference"}
      name={"designMock"}
      value={designMock}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Element on view"}
      name={"designElement"}
      value={designElement}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Actionable Elements"}
      name={"designActionElement"}
      value={designActionElement}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
      rte={true}
    />
    <FieldRow
      classes={classes}
      label={"Controls"}
      name={"designControlField"}
      value={designControlField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />

    <FieldRow
      classes={classes}
      label={"View name"}
      name={"designViewNameField"}
      value={designViewNameField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"View navigation"}
      name={"designViewNavigationField"}
      value={designViewNavigationField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
  </>
);

const FieldsBackendType = ({
  classes,
  onChange,
  readOnly,
  backEndTool,
  backEndDesignMock,
  backEndAuthenticatedField,
  backEndAccessibleField,
  backEndViewNameField,
  backEndViewNavigationField,
  backEndDesc,
  backEndWorkFlow,
  disabled
}) => (
  <>
    <FieldRow
      classes={classes}
      label={"Programming tools"}
      name={"backEndTool"}
      value={backEndTool}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Description"}
      name={"backEndDesc"}
      value={backEndDesc}
      disabled={disabled}
      readOnly={readOnly}
      rte={true}
    />
    <FieldRow
      classes={classes}
      label={"Authenticated"}
      name={"backEndAuthenticatedField"}
      value={backEndAuthenticatedField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Design mock reference"}
      name={"backEndDesignMock"}
      value={backEndDesignMock}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"Accessible by role"}
      name={"backEndAccessibleField"}
      value={backEndAccessibleField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"View name"}
      name={"backEndViewNameField"}
      value={backEndViewNameField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />
    <FieldRow
      classes={classes}
      label={"View navigation"}
      name={"backEndViewNavigationField"}
      value={backEndViewNavigationField}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
    />

    <FieldRow
      classes={classes}
      label={"Work flow"}
      name={"backEndWorkFlow"}
      value={backEndWorkFlow}
      readOnly={readOnly}
      onChange={onChange}
      disabled={disabled}
      rte={true}
    />
  </>
);

export default ({
  storyType,
  onChange,
  classes,
  readOnly = false,
  disabled,
  ...fieldStates
}) => {
  if (storyType === "design-card") {
    return (
      <FieldsDesignType
        readOnly={readOnly}
        onChange={onChange}
        classes={classes}
        readOnly={readOnly}
        disabled={disabled}
        {...fieldStates}
      />
    );
  }
  if (storyType === "frontend-card") {
    return (
      <FieldsFrontendType
        readOnly={readOnly}
        onChange={onChange}
        classes={classes}
        readOnly={readOnly}
        disabled={disabled}
        {...fieldStates}
      />
    );
  }
  if (storyType === "api-card") {
    return (
      <FieldsApiType
        readOnly={readOnly}
        onChange={onChange}
        classes={classes}
        readOnly={readOnly}
        disabled={disabled}
        {...fieldStates}
      />
    );
  }
  if (storyType === "backend-card") {
    return (
      <FieldsBackendType
        readOnly={readOnly}
        onChange={onChange}
        classes={classes}
        readOnly={readOnly}
        disabled={disabled}
        {...fieldStates}
      />
    );
  }
  return null;
};
