import {
  UPDATE_USERS_CREDITS_START,
  UPDATE_USERS_CREDITS_SUCCESS,
  UPDATE_USERS_CREDITS_ERROR,
  UPDATE_USERS_ROLE_START,
  UPDATE_USERS_ROLE_SUCCESS,
  UPDATE_USERS_ROLE_ERROR,
  DEDUCT_CREDITS_START,
  DEDUCT_CREDITS_SUCCESS,
  DEDUCT_CREDITS_ERROR,
  GET_USER_DETAILS_START,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  UPDATE_USERS_DATA_START,
  UPDATE_USERS_DATA_ERROR,
  UPDATE_USERS_DATA_SUCCESS,
  GET_USERS_START,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  GET_DOER_START,
  GET_DOER_SUCCESS,
  GET_DOER_ERROR,
  LOAD_COUNTRY_LIST_START,
  LOAD_COUNTRY_LIST_SUCCESS,
  LOAD_COUNTRY_LIST_ERROR,
  LOAD_TIMEZONE_LIST_START,
  LOAD_TIMEZONE_LIST_SUCCESS,
  LOAD_TIMEZONE_LIST_ERROR
  
} from "actions/constant";

import { toast } from "react-toastify";
import { GET_DOER_DETAILS_START } from "actions/constant";
import { GET_DOER_DETAILS_SUCCESS } from "actions/constant";
import { GET_USER_CONTRACTS_START } from "actions/constant";
import { GET_USER_CONTRACTS_SUCCESS } from "actions/constant";
import { GET_USER_CONTRACTS_ERROR } from "actions/constant";
import { UPDATE_DOER_START } from "actions/constant";
import { UPDATE_DOER_SUCCESS } from "actions/constant";
import { UPDATE_DOER_ERROR } from "actions/constant";
import { UPDATE_PASSWORD } from "actions/constant";
import { UPDATE_PASSWORD_SUCCESS } from "actions/constant";
import { UPDATE_PASSWORD_ERROR } from "actions/constant";

const userUpdate = (state = {}, action) => {
  switch (action.type) {

    case UPDATE_USERS_DATA_START:
      return Object.assign({}, state, {
        isFetching: true
      });
    case UPDATE_USERS_DATA_SUCCESS:
      return Object.assign({}, state, {
        userLists: action.payload && action.payload.data,
        isFetching: false

      });
    case UPDATE_USERS_DATA_ERROR:
      toast.error(action.errorMsg)

      return Object.assign({}, state, {
        userListError: action.errorMsg,
        isFetching: false

      });


    case LOAD_COUNTRY_LIST_START:
      return Object.assign({}, state, {
        isFetching: true
      });
    case LOAD_COUNTRY_LIST_SUCCESS:
      return Object.assign({}, state, {
        countryLists: action.payload && action.payload.data,
        isFetching: false

      });
    case LOAD_COUNTRY_LIST_ERROR:
      toast.error(action.errorMsg)

      return Object.assign({}, state, {
        countryListError: action.errorMsg,
        isFetching: false

      });



      case LOAD_TIMEZONE_LIST_START:
          return Object.assign({}, state, {
            isFetching: true
          });
      case LOAD_TIMEZONE_LIST_SUCCESS:
          return Object.assign({}, state, {
            timezoneLists: action.payload && action.payload.data,
            isFetching: false
    
          });
      case LOAD_TIMEZONE_LIST_ERROR:
          toast.error(action.errorMsg)
    
          return Object.assign({}, state, {
            timeZoneError: action.errorMsg,
            isFetching: false
    
          });



    case GET_USERS_START:
      return Object.assign({}, state, {
        isFetching: true
      });
    case GET_USERS_SUCCESS:
      return Object.assign({}, state, {
        userLists: action.payload && action.payload.data,
        isFetching: false
      });
    case GET_USERS_ERROR:
      toast.error(action.errorMsg)

      return Object.assign({}, state, {
        userListError: action.errorMsg,
        isFetching: false
      });

    case GET_USER_DETAILS_START:
      return {
        ...state, isFetching: true
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state, isFetching: false, userDetails: action.payload && action.payload.data
      }
    case GET_USER_DETAILS_ERROR:
      toast.error(action.errorMsg);
      return {
        ...state, isFetching: false
      }
    case UPDATE_USERS_CREDITS_START:
      return Object.assign({}, state, {});
    case UPDATE_USERS_CREDITS_SUCCESS:
      return Object.assign({}, state, {
        userCredits: action.payload && action.payload.data
      });
    case UPDATE_USERS_CREDITS_ERROR:
      toast.error(action.errorMsg)

      return Object.assign({}, state, {
        errorCredit: action.errorMsg
      });
    case DEDUCT_CREDITS_START:
      return Object.assign({}, state, {});
    case DEDUCT_CREDITS_SUCCESS:
      return Object.assign({}, state, {
        deductCredits: action.payload && action.payload.data
      });
    case DEDUCT_CREDITS_ERROR:
      toast.error(action.errorMsg)

      return Object.assign({}, state, {
        errorCredit: action.errorMsg
      });
    case UPDATE_USERS_ROLE_START:
      return Object.assign({}, state, {isUpdating: true});
    case UPDATE_USERS_ROLE_SUCCESS:
      return Object.assign({}, state, {
        userRole: action.payload && action.payload.data,
        isUpdating: false
      });
    case UPDATE_USERS_ROLE_ERROR:
      toast.error(action.errorMsg)

      return Object.assign({}, state, {
        errorCredit: action.errorMsg,
        isUpdating: false
      });




      case GET_DOER_START:
          return Object.assign({}, state, {
            isFetching: true
          });
      case GET_DOER_SUCCESS:
          return Object.assign({}, state, {
            doerLists: action.payload && action.payload.data,
            isFetching: false
          });
      case GET_DOER_ERROR:
        toast.error(action.errorMsg)

        return Object.assign({}, state, {
          doerListError: action.errorMsg,
          isFetching: false

      });
      
      case GET_DOER_DETAILS_START:
        return {...state, loading: true}
      case GET_DOER_DETAILS_SUCCESS:
        return {...state, loading: false, doerDetails: action.payload && action.payload.data}
      case GET_DOER_ERROR:
        toast.error(action.errorMsg)
        return {...state, loading: false, doerDetailsError: action.errorMsg}

      case GET_USER_CONTRACTS_START:
        return {...state, loading: true}
      case GET_USER_CONTRACTS_SUCCESS:
        return {...state, loading: false, contracts: action.payload && action.payload.data}
      case GET_USER_CONTRACTS_ERROR:
        return {...state, loading: false, contractsError: action.errorMsg }
      case UPDATE_DOER_START:
        return {...state, doerUpdating: true}
      case UPDATE_DOER_SUCCESS:
        return {...state, doerUpdating: false, roleChange: action.payload && action.payload.data}
      case UPDATE_DOER_ERROR:
        return {...state, doerUpdating: false, roleError: action.errorMsg}

      case UPDATE_PASSWORD:
        console.log('updating password =', action);
        return {...state, loading: true, isUpdatingPassword: true}
      case UPDATE_PASSWORD_SUCCESS:
        return {...state, loading: false, isUpdatingPassword: false, passChange: action.payload && action.payload.data}
      case UPDATE_PASSWORD_ERROR:
          console.log('updating password fail=', action);
        toast.error(action.errorMsg)
      return {...state, loading: false, isUpdatingPassword: false, passChangeErr: action.errorMsg}
    default:
      return state;
  }
};

export default userUpdate;