import React, { Component } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import Person from "@material-ui/icons/Person";
import Close from "@material-ui/icons/Close";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { pieChart } from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";
import WorkIcon from '@material-ui/icons/Work';


const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};

export class Dashboard extends Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={5} md={5} lg={5}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Icon>contacts</Icon>
                </CardIcon>
                <h4 className={classes.cardTitle}>
                  Sprints
                </h4>
              </CardHeader>
              <CardBody>
              <ChartistGraph
                data={pieChart.data}
                type="Pie"
                options={pieChart.options}
              />
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats}>
               
                
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={7} md={7} lg={7}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <WorkIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Doers</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHead={[
                    {
                      Header: "#",
                      accessor: "0",
                      filterable: false,
                      headerClassName: classes.tableHearders,
                      width: 70
                    },
                    {
                      Header: "Name",
                      accessor: "1",
                      filterable: false,
                      headerClassName: classes.tableHearders,
                    },
                    {
                      Header: "Base Hourly Rate",
                      accessor: "2",
                      filterable: false,
                      headerClassName: classes.tableHearders,
                    },
                    {
                        Header: "Status",
                        accessor: "3",
                        filterable: false,
                        headerClassName: classes.tableHearders,
                    }
                  ]}
                  tableData={[
                    ["1", "Andrew Mike", "$ 20", "Pending"],
                    ["2", "John Doe", "$ 30", 'Audition'],
                    ["3", "Alex Mike", "$ 30", 'Audition'],
                    [ "4", "Mike Monday", "$ 30", 'Audition'],
                    [ "5", "Paul Dickens", "$ 30", 'Audition']
                  ]}
                  customCellClasses={[classes.center, classes.right, classes.right]}
                  customClassesForCells={[0, 4, 5]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 4, 5]}
                />
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  dashboard: state
});


export default connect(mapStateToProps)(withStyles(styles)(Dashboard));