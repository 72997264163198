import {
  GET_DISCOUNT_MANAGEMENT_START,
  GET_DISCOUNT_MANAGEMENT_SUCCESS,
  GET_DISCOUNT_MANAGEMENT_ERROR,
  SAVE_CREATE_USER_SUCCESS,
  SAVE_CREATE_USER_START,
  CREATE_USER_SAVE_ERROR,
  ADD_DISCOUNT_MANAGEMENT_START,
  ADD_DISCOUNT_MANAGEMENT_SUCCESS,
  ADD_DISCOUNT_MANAGEMENT_ERROR,
  DELETE_DISCOUNT_MANAGEMENT_START,
  DELETE_DISCOUNT_MANAGEMENT_SUCCESS,
  DELETE_DISCOUNT_MANAGEMENT_ERROR,
  APPLY_VOUCHER_START,
  APPLY_VOUCHER_SUCCESS,
  APPLY_VOUCHER_ERROR,
  PAYMENT_START,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  UPDATE_DISCOUNT_MANAGEMENT_START,
  UPDATE_DISCOUNT_MANAGEMENT_SUCCESS,
  UPDATE_DISCOUNT_MANAGEMENT_ERROR
} from "../actions/constant";
import { toast } from "react-toastify";

const discountManagement = (
  state = {
    isFetching: false,
    data: []
  }, action) => {
  switch (action.type) {
    case GET_DISCOUNT_MANAGEMENT_START:
      return Object.assign({}, state, {
        isFetching: true
      });
    case GET_DISCOUNT_MANAGEMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.getDiscountResponse
      });
    case GET_DISCOUNT_MANAGEMENT_ERROR:
      toast.error(action.errorMsg);

      return Object.assign({}, state, {
        isFetching: false
      });

      case UPDATE_DISCOUNT_MANAGEMENT_START:
        return Object.assign({}, state, {
          isFetching: true
        });
      case UPDATE_DISCOUNT_MANAGEMENT_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          data: action.getDiscountResponse
        });
      case UPDATE_DISCOUNT_MANAGEMENT_ERROR:
        toast.error(action.errorMsg);
  
        return Object.assign({}, state, {
          isFetching: false
        });

    case SAVE_CREATE_USER_START:
      return Object.assign({}, state, {});
    case SAVE_CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        getDiscount: action.getDiscountResponse
      });
    case CREATE_USER_SAVE_ERROR:
      return Object.assign({}, state, {
        error: action.errorMsg
      });
    case ADD_DISCOUNT_MANAGEMENT_START:
      return Object.assign({}, state, {});
    case ADD_DISCOUNT_MANAGEMENT_SUCCESS:
      return Object.assign({}, state, {
        addDiscount: action.addDiscountResponse
      });
    case ADD_DISCOUNT_MANAGEMENT_ERROR:
      return Object.assign({}, state, {
        error: action.errorMsg
      });
    case DELETE_DISCOUNT_MANAGEMENT_START:
      return Object.assign({}, state, {});
    case DELETE_DISCOUNT_MANAGEMENT_SUCCESS:
      return Object.assign({}, state, {});
    case DELETE_DISCOUNT_MANAGEMENT_ERROR:
      return Object.assign({}, state, {
        discountDeleteError: action.errorMsg
      });
    case APPLY_VOUCHER_START:
      return Object.assign({}, state, {});
    case APPLY_VOUCHER_SUCCESS:
      return Object.assign({}, state, {
        vocherResponse: action.vocher
      });
    case APPLY_VOUCHER_ERROR:
      return Object.assign({}, state, {
        error: action.errorMsg
      });
    case PAYMENT_START:
      return Object.assign({}, state, {});
    case PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        paymentResponse: action.payment
      });
    case PAYMENT_ERROR:
      return Object.assign({}, state, {
        error: action.errorMsg
      });
    default:
      return state;
  }
};

export default discountManagement;
