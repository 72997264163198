import axios from "axios";
import { BASE_URL } from "../common/constants";
import { getToken } from "../utils/common";

export async function saveUserStoriesAPI(data, projectId, sprintId) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}projects/${projectId}/sprints/${sprintId}/stories`;
  const response = await axios.post(url, data, config);
  return response.data;
}

export async function getUserStoriesAPI(projectId, sprintId) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}projects/${projectId}/sprints/${sprintId}/stories`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function updateUserStoriesAPI(data, projectId, sprintId) {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let promises = [];
  return new Promise((resolve, reject) => {
    data.forEach(story => {
      let url = `${BASE_URL}projects/${projectId}/sprints/${sprintId}/stories/${story._id}`;
      if(story.moveTo) {
        promises.push(moveUserStoriesAPI(projectId, sprintId, story._id, story.moveTo))
      }
      promises.push(axios.put(url, story, config));
    })
    Promise.all(promises).then(responses => {
      resolve(responses);
    }).catch(err => {
        reject();
      }
    )
  })
}

export async function getOneUserStoriesAPI( projectId, sprintId, storyId) {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}projects/${projectId}/sprints/${sprintId}/stories/${storyId}`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function moveUserStoriesAPI(projectId, sprintId, storyId, newSprintId) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}projects/${projectId}/sprints/${sprintId}/stories/${storyId}/move/${newSprintId}`;
  const response = await axios.put(url, {}, config);
  return response.data;
}

export async function deleteUserStoriesAPI(projectId, sprintId, storyId) {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}projects/${projectId}/sprints/${sprintId}/stories/${storyId}`;
  const response = await axios.delete(url, config);
  return response.data;
}


export async function getUsersAPI(userID) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}projects/users?userIds=${userID}`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function getUserStoryByIdAPI(storyId) {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}stories/${storyId}`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function getCustomersAPI() {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}users/customers`;
  const response = await axios.get(url, config);
  return response.data;
}
export async function getContractsAPI(contractId) {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/${contractId}`;
  const response = await axios.get(url, config);
  return response.data;
}
export async function getContractsByKeyAPI(contractId) {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/key/${contractId}`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function  getFilteredTableDataAPI(requestor, sprint, project, status) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };

  let url = `${BASE_URL}stories/search?${requestor ? 'user=' + requestor : ''}${project ? '&project=' + project : ''}${status ? '&storyStatus=' + status : ''}${sprint ? '&sprint=' + sprint : ''}`;
  const response = await axios.get(url, config);
  return response.data;
}
export async function getStoryAPI(storyId) {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}stories/${storyId}`;
  const response = await axios.get(url, config);
  return response.data;
}
