import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RESETPASSWORD_SUCCESS, 
  RESETPASSWORD_ERROR,
  FORGOTPASSWORD_ERROR, 
  FORGOTPASSWORD_SUCCESS,
  VALIDATION_TOKEN_SUCCESS,
  VALIDATION_TOKEN_ERROR
} from 'actions/constant';
import { toast } from "react-toastify";





const Login = (
  state = {
    auth: {},
  },
  action,
) => {
  switch(action.type) {
    // Any new Error actions needs to be added here
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        auth: action.payload,
        error: ""
      })
    }

    case LOGIN_ERROR:
        toast.error(action.errorMsg)

      return Object.assign({}, state, {
        error: action.errorMessage,
        auth: {}
      });

    case RESETPASSWORD_SUCCESS: {
      return Object.assign({}, state,{
        resetPasswordError: "",
        resetPassword: action.payload
      });
    }

    case RESETPASSWORD_ERROR: {
      toast.error(action.errorMsg)
      return Object.assign({}, state, {
        resetPasswordError: action.errorMessage
      });
    }

    case VALIDATION_TOKEN_SUCCESS: {
      return Object.assign({}, state,{
        validationTokenError: "",
        validationToken: action.payload
      });
    }

    case VALIDATION_TOKEN_ERROR: {
      toast.error(action.errorMsg)
      return Object.assign({}, state, {
        validationTokenError: action.errorMessage
      });
    }

    case FORGOTPASSWORD_SUCCESS: {
      return Object.assign({}, state,{
        forgotPasswordError: "",
        forgotPassword: action.payload
      });
    }

    case FORGOTPASSWORD_ERROR: {
      toast.error(action.errorMsg)
      return Object.assign({}, state, {
        forgotPasswordError: action.errorMessage
      });
    }

    default:
      return state;
  }
}

export default Login;
