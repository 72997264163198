import {
  CLEAR_CONTRACT_DATA,
  DELETE_COMMENT_ERROR,
  DELETE_COMMENT_START,
  DELETE_COMMENT_SUCCESS,
  GET_ALL_CONTRACTS_ERROR,
  GET_ALL_CONTRACTS_START,
  GET_ALL_CONTRACTS_SUCCESS,
  GET_ASSIGNEE_LIST_ERROR,
  GET_ASSIGNEE_LIST_START,
  GET_ASSIGNEE_LIST_SUCCESS,
  GET_COMMENT_ERROR,
  GET_COMMENT_START,
  GET_COMMENT_SUCCESS,
  GET_CONTRACT_DETAILS_ERROR,
  GET_CONTRACT_DETAILS_START,
  GET_CONTRACT_DETAILS_SUCCESS,
  GET_CONTRACT_LIST_START,
  GET_CONTRACT_LIST_ERROR,
  GET_CONTRACT_STATUS_ERROR,
  GET_CONTRACT_STATUS_START,
  GET_CONTRACT_STATUS_SUCCESS,
  GET_OPEN_CONTRACT_LIST_SUCCESS,
  GET_PAYMENT_CONTRACT_LIST_SUCCESS,
  POST_COMMENT_ERROR,
  POST_COMMENT_START,
  POST_COMMENT_SUCCESS,
  POST_CREATE_CONTRACT_ERROR,
  POST_CREATE_CONTRACT_START,
  POST_CREATE_CONTRACT_SUCCESS,
  PUT_AWARDED_BID_ERROR,
  PUT_AWARDED_BID_START,
  PUT_AWARDED_BID_SUCCESS,
  PUT_COMMENT_ERROR,
  PUT_COMMENT_START,
  PUT_COMMENT_SUCCESS,
  PUT_CONTRACT_STATUS_ERROR,
  PUT_CONTRACT_STATUS_START,
  PUT_CONTRACT_STATUS_SUCCESS,
  SAVE_CONTRACT_ERROR,
  SAVE_CONTRACT_START,
  SAVE_CONTRACT_SUCCESS,
  SET_CONTRACT_DATA,
  UPDATE_CONTRACT_ERROR,
  UPDATE_CONTRACT_START,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_STORY_STATUS_ERROR,
  UPDATE_STORY_STATUS_START,
  UPDATE_STORY_STATUS_SUCCESS
} from "actions/constant";
import {toast} from "react-toastify";


const contract = (
  state = {
    isFetching: false,
    isSavingContract: false,
    isUpdatingContract: false,
    paymentContracts: [],
    openContracts:[],
    contractSavedData: {},
    contractInfo: {},
  },
  action,
) => {
  switch (action.type) {
    case GET_CONTRACT_LIST_START:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case GET_OPEN_CONTRACT_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        openContracts: action.payload ,
      });
    case GET_PAYMENT_CONTRACT_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        paymentContracts: action.payload ,
      });
    case GET_CONTRACT_LIST_ERROR:
        toast.error(action.errorMsg)

      return Object.assign({}, state, {
        isFetching: false,
      });
    case GET_CONTRACT_DETAILS_START:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case GET_CONTRACT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        contractInfo: action.projectInfoResponse,
      });
    case GET_CONTRACT_DETAILS_ERROR:
        toast.error(action.errorMsg)

      return Object.assign({}, state, {
        isFetching: false,
      });
    case SAVE_CONTRACT_START:
      return Object.assign({}, state, {
        isSavingContract: true,
      });
    case SAVE_CONTRACT_SUCCESS:
      return Object.assign({}, state, {
        isSavingContract: false,
        contractSavedData: action.saveResponse,
      });
    case SAVE_CONTRACT_ERROR:
        toast.error(action.errorMsg)

      return Object.assign({}, state, {
        isSavingContract: false,
      });
    case UPDATE_CONTRACT_START:
      return Object.assign({}, state, {
        isUpdatingProject: true,
      });
    case UPDATE_CONTRACT_SUCCESS:
      return Object.assign({}, state, {
        isUpdatingProject: false,
        contractSavedData: action.updateResponse,
      });
    case UPDATE_CONTRACT_ERROR:
        toast.error(action.errorMsg)

      return Object.assign({}, state, {
        isUpdatingProject: false,
      });
    case CLEAR_CONTRACT_DATA:
      return Object.assign({}, state, {
        contractSavedData: {},
      });
    case SET_CONTRACT_DATA:
      return Object.assign({}, state, {
        contractSavedData: action.projectDetails,
      });
      case PUT_CONTRACT_STATUS_START:
        return Object.assign({}, state, {
         // isUpdatingProject: true,
        });
      case PUT_CONTRACT_STATUS_SUCCESS:
        return Object.assign({}, state, {
         // isUpdatingProject: false,
          updateStatus: action.payload,
        });
      case PUT_CONTRACT_STATUS_ERROR:
          toast.error(action.errorMsg)
  
        return Object.assign({}, state, {
         // isUpdatingProject: false,
        });   
        case GET_ASSIGNEE_LIST_START:
          return Object.assign({}, state, {
            isFetching: true,
          });
        case GET_ASSIGNEE_LIST_SUCCESS:
          return Object.assign({}, state, {
            isFetching: false,
            assigneeList: action.payload,
          });
        case GET_ASSIGNEE_LIST_ERROR:
            toast.error(action.errorMsg)
    
          return Object.assign({}, state, {
            isFetching: false,
          });   
          case GET_COMMENT_START:
            return Object.assign({}, state, {
              isFetching: true,
            });
          case GET_COMMENT_SUCCESS:
            return Object.assign({}, state, {
              isFetching: false,
              commentList: action.payload,
            });
          case GET_COMMENT_ERROR:
              toast.error(action.errorMsg)
      
            return Object.assign({}, state, {
              isFetching: false,
            });  
            case POST_COMMENT_START:
            return Object.assign({}, state, {
              isFetching: true,
            });
          case POST_COMMENT_SUCCESS:
            return Object.assign({}, state, {
              isFetching: false,
              postComment: action.payload,
            });
          case POST_COMMENT_ERROR:
              toast.error(action.errorMsg)
      
            return Object.assign({}, state, {
              isFetching: false,
            }); 
            case PUT_COMMENT_START:
        return Object.assign({}, state, {
         // isUpdatingProject: true,
        });
      case PUT_COMMENT_SUCCESS:
        return Object.assign({}, state, {
         // isUpdatingProject: false,
          putComment: action.payload,
        });
      case PUT_COMMENT_ERROR:
          toast.error(action.errorMsg)
  
        return Object.assign({}, state, {
         // isUpdatingProject: false,
        });   
        case DELETE_COMMENT_START:
        return Object.assign({}, state, {
         // isUpdatingProject: true,
        });
      case DELETE_COMMENT_SUCCESS:
        return Object.assign({}, state, {
         // isUpdatingProject: false,
          deleteComment: action.payload,
        });
      case DELETE_COMMENT_ERROR:
          toast.error(action.errorMsg)
  
        return Object.assign({}, state, {
         // isUpdatingProject: false,
        });  
        case PUT_AWARDED_BID_START:
          return Object.assign({}, state, {
           // isUpdatingProject: true,
          });
        case PUT_AWARDED_BID_SUCCESS:
          return Object.assign({}, state, {
           // isUpdatingProject: false,
            awardedBid: action.payload,
          });
        case PUT_AWARDED_BID_ERROR:
            toast.error(action.errorMsg)
    
          return Object.assign({}, state, {
           // isUpdatingProject: false,
          }); 
          case GET_CONTRACT_STATUS_START:
            return Object.assign({}, state, {
              isFetching: true,
            });
          case GET_CONTRACT_STATUS_SUCCESS:
            return Object.assign({}, state, {
              isFetching: false,
              contractStatusList: action.payload,
            });
          case GET_CONTRACT_STATUS_ERROR:
              toast.error(action.errorMsg)
      
            return Object.assign({}, state, {
              isFetching: false,
            });
            case POST_CREATE_CONTRACT_START:
              return Object.assign({}, state, {
                isFetching: true,
              });
            case POST_CREATE_CONTRACT_SUCCESS:
              return Object.assign({}, state, {
                isFetching: false,
                createContract: action.payload,
              });
            case POST_CREATE_CONTRACT_ERROR:
                toast.error(action.errorMsg)
        
              return Object.assign({}, state, {
                isFetching: false,
              });  
              case UPDATE_STORY_STATUS_START:
                return Object.assign({}, state, {
                 // isUpdatingProject: true,
                });
              case UPDATE_STORY_STATUS_SUCCESS:
                return Object.assign({}, state, {
                 // isUpdatingProject: false,
                  storyStatus: action.payload,
                });
              case UPDATE_STORY_STATUS_ERROR:
                  toast.error(action.errorMsg)
          
                return Object.assign({}, state, {
                 // isUpdatingProject: false,
                });
                case GET_ALL_CONTRACTS_START:
                  return Object.assign({}, state, {
                    isFetching: true,
                  });
                case GET_ALL_CONTRACTS_SUCCESS:
                  return Object.assign({}, state, {
                    isFetching: false,
                    allContractsList: action.payload,
                  });
                case GET_ALL_CONTRACTS_ERROR:
                    toast.error(action.errorMsg)
            
                  return Object.assign({}, state, {
                    isFetching: false,
                  });                                                     
    default:
      return state;
  }
};

export default contract;
