import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { grayColor, infoColor } from "../../material-dashboard-pro-react";
import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";

const DoerListStyle = {
    ...styles,
    tableActionIcon: {
        color: grayColor[9]
    },
    tableHearders: {
        color: infoColor[0]
    },
    right: {
        textAlign: "right !important"
    },
    cardTitle:{
       // paddingTop: '3px',
        ...cardTitle,
        color: 'white',
        fontSize: '20px',
        flex: '1'
    },
    display:{
        display:'flex',
        alignItems: 'center'
    },
    // addBtn1:{

    //     color: 'black',
    //     textAlign: "right !important",
        

    // }
}

export default DoerListStyle;