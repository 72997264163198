import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { connect } from "react-redux";

// @material-ui/icons

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import CardIcon from "components/Card/CardIcon.js";
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import { toast } from "react-toastify";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import CustomInput from "components/CustomInput/CustomInput.js";

import { getUserList } from '../../actions/userUpdate';
import { updateContractModal } from "../../actions/contract";
import { getUserData } from "../../utils/common";
import { getMarginsForContract, updateMarginForContracts } from "actions/margins.js";
import { couldStartTrivia } from "typescript";
import MarginField from './MarginField';
import { replace } from "connected-react-router";


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const OverrideCredits = (props) => {
    const { modalState, closeModal, contractData } = props;
    const [largeModal, setLargeModal] = useState(modalState);
    const [ marginList, setMarginList] = useState([]);
    const [ updatedMargins, setUpdatedMargins] = useState([]);

    const classes = useStyles();

    const closeModalHandler = () => {
        setLargeModal(false);
        setUpdatedMargins([]);
        closeModal();
    };

    useEffect(() => {
        props.dispatch(getMarginsForContract(contractData.id)).then((margins) => 
            setMarginList(margins.data.map((margin) => {
                let newMargin = [];
                newMargin = margin;
                newMargin['edit'] = false;
                return newMargin;
            }))
        );
    }, []);

    const submitOverridenMargins = () => {
        if(Object.keys(updatedMargins).length != 0) {
            const data = {
                margins: updatedMargins
            }
            props.dispatch(updateMarginForContracts(contractData.id, data, ()=>{
                toast.success("Margins Updated Successfully");
            }));
        }
        closeModalHandler();
    }

    const updateMargins = (marginId, baseCredits, percent) => {
        const updateMarginData =  { 
            id: marginId, 
            baseCredits: baseCredits, 
            percent: percent 
        }
        setUpdatedMargins([
            ...updatedMargins,
            updateMarginData
        ])
    }

    return (
        <div>
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modalscroll
                }}
                open={largeModal}
                transition={Transition}
                keepMounted
                scroll={"body"}
                maxWidth={"md"}
                onClose={closeModalHandler}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >

                    <h4 className={classes.modalTitle}>Override Credits</h4>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}>
                    <GridContainer className={classes.selectPadding} style={{ paddingTop: '27px' }}>
                        <GridItem className={classes.selectPadding} xs={12} sm={12} md={6}>
                            <FormLabel className={classes.labelHorizontal}>
                                Roles
                        </FormLabel>
                        </GridItem>
                        <GridItem className={classes.selectPadding} xs={12} sm={12} md={2}>
                            <FormLabel className={classes.labelHorizontal}>
                                Base Credits
                        </FormLabel>
                        </GridItem>
                        <GridItem className={classes.selectPadding} xs={12} sm={12} md={2}>
                            <FormLabel className={classes.labelHorizontal}>
                                Effort Percent
                        </FormLabel>
                        </GridItem>
                        <GridItem className={classes.selectPadding} xs={12} sm={12} md={1}>
                            <FormLabel className={classes.labelHorizontal}>
                            </FormLabel>
                        </GridItem>
                    </GridContainer>
                    {
                        marginList.map((margin) => {
                            return(
                                <MarginField 
                                    key = {margin.id} 
                                    margin = {margin} 
                                    updateMargins = {(marginId, baseCredits, percent) => updateMargins(marginId, baseCredits, percent)}
                                />
                            )
                        })
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooterClr} >
                    <GridContainer justify="center">
                        <Button color='transparent' round onClick={closeModalHandler}>Cancel</Button>
                        <Button color='info' round className={classes.btn} onClick = {submitOverridenMargins} >Submit</Button>
                    </GridContainer>
                </DialogActions>
            </Dialog>
        </div>
    );
};
const mapStatetoProps = state => state;

export default connect(mapStatetoProps)(OverrideCredits);
