import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { connect } from "react-redux";

// @material-ui/icons

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import CardIcon from "components/Card/CardIcon.js";
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';
import { toast } from "react-toastify";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { getUserList } from '../../actions/userUpdate';
import { updateContractModal } from "../../actions/contract";
import { getUserData } from "../../utils/common";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SaveBid = (props) => {
  const { modalState, closeModal, contractData } = props;
  const [largeModal, setLargeModal] = React.useState(modalState);

  const [dueDate, setDueDate] = React.useState(contractData && contractData.contractBidExpiry ? moment(new Date(moment(contractData.contractBidExpiry))) : Date.now());
  const [dueDateState, setDueDateState] = React.useState("");
  const [ disableSubmit, setDisableSubmit ] = React.useState(true);

  const classes = useStyles();
  const dateInp = React.createRef();
  // const doers = [];
  const [selectedDoer, setSelectedDoer] = React.useState([])
  const [doerState, setDoerState] = React.useState([])
  let yesterday = Datetime.moment().subtract( 1, 'day' );
  let valid = function( current ){
    return current.isAfter( yesterday );
  };
  const users = props.userUpdate &&  props.userUpdate.userLists && props.userUpdate.userLists.length && props.userUpdate.userLists.filter(e => e.userStatus === 'Active') || [];

  useEffect(() => {
    props.dispatch(getUserList())
  },[]);

  const closeModalHandler = () => {
    setLargeModal(false);
    closeModal();
  };

  const openCal = () => {
    dateInp.current.openCalendar()
  }

  // const handleChange = (event) => {
  //   setSelectedDoer(event.target.value);
  // };

  const handleToggelSubmit = () => {
    setDisableSubmit(!disableSubmit);
  }

  const registerClick = () => {
    console.log("dueDate",dueDate);
    console.log('doerrrrrrrrr',selectedDoer);
    // if (!dueDate) {
    //   setDueDateState("error");
    // } else {
    //   setDueDateState("success");
    // }
    // if (!selectedDoer) {
    //   setDoerState("error");
    // } else {
    //   setDoerState("success");
    // }

    if (dueDate && selectedDoer) {
      const data = selectedDoer && selectedDoer.length && selectedDoer.map(d => ({
        type: "Individual_User",
        id: d._id
      }));

      const userData = getUserData();

      props.dispatch(updateContractModal({
        ...props.contractData,
        visible: data,
        contractBidExpiry:dueDate
      }, props.contractData.id, () => {
        toast.success("Bidder added successfully")
        modalState.callback && modalState.callback();
        setLargeModal(false);
        props.onAssigneeFetch()
      }))
    }
  };

  return (
      <div>
        <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={largeModal}
            transition={Transition}
            keepMounted
            onClose={closeModalHandler}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            className={classes.dialogBoxy}
        >
          <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
          >

            <CardIcon className={classes.tag} color="info">
              <LocalActivityIcon />
            </CardIcon>

            <h4 className={classes.modalTitle}>{modalState._id?'Edit Bid' : 'Request Estimate'}</h4>
          </DialogTitle>
          <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}>
            <GridContainer className={classes.selectPadding} style={{paddingTop:'27px'}}>
              <GridItem className={classes.selectPadding} xs={12} sm={12} md={4}>
                <FormLabel className={classes.labelHorizontal}>
                  Doer Name
                </FormLabel>
              </GridItem>
              <GridItem className={classes.selectPadding} xs={12} sm={12} md={8}>
                <FormControl fullWidth className={classes.formControl} error
                             success={setDoerState === "success"}
                             error={setDoerState === "error"}>
                  <Select multiple
                          inputProps={{
                            name: "selectedDoer",
                            id: 'selectedDoer',
                          }}
                          className={classes.slSize}
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          value={ selectedDoer }
                          renderValue={ selected => selected.map(item => item.firstName +' '+ item.lastName).join(', ')}
                          onChange={(event) => {
                            setSelectedDoer(event.target.value);
                          }}>
                    {
                      users.map(
                          user =>
                              <MenuItem  value={ user } key={ user._id } classes={{ root: classes.selectMenuItem }} primaryText={user.firstName} >
                                <Checkbox checked={ selectedDoer.some(j => j._id === user._id) } />
                                {/*<ListItemText primary={user.firstName +' '+ user.lastName} />*/}
                                {user.firstName +' '+ user.lastName}
                              </MenuItem>
                      )
                    }

                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.selectPadding}  style={{paddingTop:'27px'}}>
              <GridItem className={classes.selectPadding} xs={12} sm={12} md={4}>
                <FormLabel className={classes.labelHorizontal}>
                  Estimate Due Date
                </FormLabel>
              </GridItem>
              <GridItem className={classes.selectPadding} xs={12} sm={12} md={8}>
                <FormControl
                    fullWidth
                    className={classes.selectPadding}
                    success={dueDateState === "success"}
                    error={dueDateState === "error"}>
                  <div className={classes.dateLogo}>
                    <div className={classes.dateInputFullWidth}>
                      <Datetime
                        defaultValue = { dueDate }
                        selected={ dueDate }
                        isValidDate={ valid }
                        timeFormat={true}
                        onChange={(event) => { setDueDate(event); }}
                        ref={dateInp}
                      />
                    </div>
                    <EventIcon onClick={()=>openCal()}/>
                  </div>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.selectPadding}  style={{paddingTop:'20px'}}>
            <GridItem className={classes.selectPadding} xs={12} sm={12} md={12}>
                <FormControlLabel
                  className={
                    classes.CheckboxContainer +
                    ' ' +
                    classes.selectPadding
                  }
                  control={
                    <Checkbox
                      className={classes.textEdit}
                      value='checkedB'
                      color='primary'
                      onClick={() => handleToggelSubmit()}
                    />
                  }
                  label={
                    <div
                      className={classes.labelHorizontal}
                    >
                      Please confirm if the correct Credit Margin is in place
                    </div>
                  }
                  style={{
                    color: 'rgba(0, 0, 0, 0.54);',
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={ classes.modalFooterClr} >
            <GridContainer justify="center">
              <Button color='transparent' round onClick={closeModalHandler}>Cancel</Button>
              <Button color='info' round disabled = {disableSubmit} className={classes.btn} onClick={registerClick}>{modalState._id ? 'Update' : 'Submit'}</Button>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </div>
  );
};
const mapStatetoProps = state => state;

export default connect(mapStatetoProps)(SaveBid);
