import React, { Component } from 'react'
import { BreadCrumb, sortUsersAscendingOrder } from 'utils/common';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import Table from 'components/Table/Table';
import CardBody from 'components/Card/CardBody';
import Buttons from 'views/Components/Buttons';
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import { Select } from '@material-ui/core';
import GridItem from "components/Grid/GridItem.js";
import FormLabel from "@material-ui/core/FormLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/UserStoriesStyle";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from 'components/CustomInput/CustomInput';
import { getCustomers, getUsers, getFilteredTableData } from 'actions/userStories';
import { getAllSprintList } from 'actions/sprint';
import {developmentStatus} from 'common/constants';


//import { Grid, Paper, TablePagination, Typography } from '@material-ui/core';



class UserStories extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             requestor: 'a',
             sprint: 'a',
             project: 'a',
             status: 'a',
             disabled: true
        }
    }
    componentDidMount(){
      this.props.dispatch(getCustomers())
    }
    componentWillReceiveProps(props){
      console.log('propsssssssssssss', props)
      if(props.userStories.customerList){
        this.setState({
            customers: sortUsersAscendingOrder(props.userStories.customerList.filter(e => e.user))
        })
      }
      if(props.userStories.projectList){
        this.setState({
          projects: props.userStories.projectList
        })
      }
      if(props.sprint.data){
        this.setState({
          sprints: props.sprint.data
        })
      }
      if(props.userStories.filteredTableDataList){
        this.setState({
          filteredTableData: props.userStories.filteredTableDataList
        })
        
      }
      
    }

  //   const openContract = (i, data) => {
  //     return(
  //         <a href="#" onClick={() => this.onEditContract(data)}>{ data }</a>
  //     )
  //  }

    onSelectChange(event, type){
      event.persist();
      const value = event.target.value === 'a' ? undefined : event.target.value; 

      this.setState({[type]: value})
      if(type === 'requestor')
      {
        this.props.dispatch(getUsers(value))
        this.setState({disabled: value? false : true, sprint: 'a', project: 'a'})
      }
      else if(type === 'project')
      {
        this.props.dispatch(getAllSprintList(value))
        this.setState({ sprint:'a'})
      }
    }

    gotoUserId(value){
        this.props.history.push('/admin/userStory-detail/'+ value)
    }

    getTableData(requestor, sprint, project, status){
        if(requestor || sprint || project || status){
            this.props.dispatch(getFilteredTableData(requestor === 'a' ? undefined : requestor, sprint === 'a' ? undefined : sprint, project === 'a' ? undefined : project, status === 'a' ? undefined : status))
        }
    }

    render() {
        const { classes } = this.props;
        const {customers, projects, sprints, filteredTableData} = this.state;
        console.log(filteredTableData);
        return (
        <div>
        <BreadCrumb pathArray={[
          {name: 'Requestor'},
          {name: ' User Stories'}
        ]} />
        <Card>
        <CardHeader color="info" className={classes.display} >
          <div className={classes.cardTitle}>
          Filters
          </div>
          </CardHeader>
          {customers &&  <CardBody>
                    <GridContainer justify='flex-end'> 
                            {/* <GridItem xs={12} sm={12} md={12}> */}
                              {/* <GridItem xs={6} sm={6} md={6}> */}
                                <GridItem xs={4} sm={3} md={3} className={classes.title}>
                                    <FormLabel>
                                        <div>Requestor</div>
                                        <div>(*required)</div>
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={3} md={3}>
                                    <Select  className={classes.selectItem}
                                        onChange={(event) => this.onSelectChange(event, 'requestor')} 
                                        value={this.state.requestor} 
                                        // className={classes.slSize + ' ' + classes.textColor} 
                                        // MenuProps={{ className: classes.selectMenu }}
                                        // classes={{ select: classes.select }}
                                        >
                                            <MenuItem value='a' disabled classes={{root: classes.selectMenuItem }}>Select Requestor</MenuItem>
                                            {
                                                    customers.map(e => <MenuItem key={e.user._id} value={e.user._id} classes={{root: classes.selectMenuItem }}>{[e.user.firstName, e.user.lastName].join(' ')}</MenuItem>)
                                                }
                                                     {/* USER_STATUSES.map(e => <MenuItem value={e} classes={{root: classes.selectMenuItem }}>{e}</MenuItem>) */}
                                               
                                    </Select>
                                </GridItem> 
                                {/* </GridItem> */}
                                
                                {/* <GridItem xs={6} sm={6} md={6}> */}
                                <GridItem xs={4} sm={3} md={3} className={classes.title}>
                                    <FormLabel>
                                        Sprint
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={3} md={3}>
                                    <Select  value={this.state.sprint} className={classes.selectItem}
                                    onChange={(event) => this.onSelectChange(event, 'sprint')}
                                        // onChange={(event) => this.onStatusChange(event)}  
                                        // className={classes.slSize + ' ' + classes.textColor} 
                                        // MenuProps={{ className: classes.selectMenu }}
                                        // classes={{ select: classes.select }}
                                        >
                                             <MenuItem value='a' disabled classes={{root: classes.selectMenuItem }}>Select Sprint</MenuItem>
                                                { sprints &&
                                                    sprints.map(e => <MenuItem value={e._id} classes={{root: classes.selectMenuItem }}>{e.title}</MenuItem>)
                                                } 
                                    </Select>
                                </GridItem>
                              {/* </GridItem> */}
                            {/* </GridItem> */}
                            
                                
                      </GridContainer>
                      <GridContainer className={classes.btnStyle}>
                                <GridItem xs={4} sm={3} md={3} className={classes.title}> 
                                    <FormLabel>
                                        Project
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={3} md={3}>
                                  <Select  value={this.state.project} className={classes.selectItem} onChange={(event) => this.onSelectChange(event, 'project')}>
                                  <MenuItem value='a' disabled classes={{root: classes.selectMenuItem }}>Select Project</MenuItem>
                                  {projects &&
                                                   projects.map(e => <MenuItem value={e.id} classes={{root: classes.selectMenuItem }}>{e.title}</MenuItem>)
                                                }
                                  </Select>
                                </GridItem>

                                <GridItem xs={4} sm={3} md={3} className={classes.title}>
                                    <FormLabel>
                                        Status
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={3} md={3}>
                                  <Select value={this.state.status} className={classes.selectItem} onChange={(event) => this.onSelectChange(event, 'status')}>
                                  <MenuItem value='a' disabled classes={{root: classes.selectMenuItem }}>Select Status</MenuItem>
                                  {
                                                    developmentStatus.map((e,i) => <MenuItem key={i} value={e.value} classes={{root: classes.selectMenuItem }}>{e.name}</MenuItem>)
                                                }
                                  </Select>
                                </GridItem> 
                            
                      </GridContainer>
                    <Button disabled={this.state.disabled} className={[classes.filterBtn,classes.btnStyle].join(' ')} color='info' round onClick={() => this.getTableData(this.state.requestor, this.state.sprint, this.state.project, this.state.status)}>Apply</Button>
            </CardBody>}
        </Card>
        
        <Card className={classes.distance}>
          <CardHeader color="info" className={classes.display} >
          <div className={classes.cardTitle}>
          User Stories
          </div>
          <Button className={classes.addBtn} round size='sm' color='white'><AddIcon/>Add User Story</Button>
          </CardHeader>
          <CardBody>
          <Table className={classes.minimumheight}
              tableHead={[
                {
                  Header: "#",
                  accessor: "index",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                //   className: classes.left,
                  width: 80
                },
                {
                  Header: "User Story ID",
                  accessor: "storyId",
                  filterable: false,
                  Cell: (props) => <span className={classes.idLinkStyle} onClick={() => this.gotoUserId(props.value.id)}>{props.value.readableId}</span>,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "User Story Type",
                  accessor: "storyType",
                  filterable: false,
                  // Cell: (props) => <span className={classes.idLinkStyle} onClick={() => this.gotoUserId(props.value.id)}>{props.value.readableId}</span>,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "User Story",
                  accessor: "story",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  // className: classes.cellPadding,
                },
                {
                  Header: "Status",
                  accessor: "status",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "Project",
                  accessor: "project",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                 // Cell: (props) => <span>{moment(props.value).format('D MMM YYYY, h:mm A')}</span>
                },
                {
                  Header: "Sprint",
                  accessor: "sprint",
                  filterable: false,
                //   className: classes.right,
                  headerClassName: [classes.right, classes.tableHearders].join(' ')
                },
                {
                    Header: "Requestor",
                    accessor: 'requestor',
                    filterable: false,
                    headerClassName: classes.tableHearders,
                }
              ]}

              tableData={filteredTableData && filteredTableData.length ? filteredTableData.map((e,i) => ({
               index: i+1,
               storyId: {
                id: e.id,
                readableId: e.storyKey
               },
               storyType: e.storyType?.replace(' Card','') || e.storyType || 'Not Specified',
               story: e.title,
               status: e.development,
               project: e.project && e.project.title,
               sprint: e.sprint && e.sprint.title,
               requestor: e.project && e.project.owner && (e.project.owner.firstName + ' ' + e.project.owner.lastName)
                
              })) : undefined}
            
            //   loading={isFetching}
            />
            
        
            </CardBody>
            </Card>
      </div>
        )
    }
}

export const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(styles)(UserStories));

