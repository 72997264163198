import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  VALIDATION_TOKEN_ERROR,
  VALIDATION_TOKEN_START,
  VALIDATION_TOKEN_SUCCESS,
  RESETPASSWORD_START,
  RESETPASSWORD_ERROR,
  RESETPASSWORD_SUCCESS,
  FORGOTPASSWORD_START,
  FORGOTPASSWORD_ERROR,
  FORGOTPASSWORD_SUCCESS
} from "./constant";

import { loginAPI, validationTokenAPI, logoutAPI, resetPasswordAPI, forgotPasswordAPI } from "../api/usersApi";
import { LOGOUT_SUCCESS_MSG } from "../common/constants";
import { removeAllUserinfo } from "../utils/common";

const loginSuccess = response => {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
  }
}

const loginError = error => {
  return {
    type: LOGIN_ERROR,
    errorMsg: error.errorMessage
  }};

export const login = (data) => {
  return async (dispatch) => {
    try {
      const response = await loginAPI(data);
      dispatch(loginSuccess(response));
      return response;
    } catch (error) {
      dispatch(loginError(error.response.data));
      return error;
    }
  }
};


const validationTokenStart = () =>{
  return{
    type : VALIDATION_TOKEN_START
  }
}

const validationTokenSuccess = response => {
  return {
    type: VALIDATION_TOKEN_SUCCESS,
    // payload: response,
  }
}

const validationTokenError = error => {
  return {
    type: VALIDATION_TOKEN_ERROR,
    errorMsg: error.errorMessage
  }};

export const validationToken = (code,callback) => {
  return async (dispatch) => {
    dispatch(validationTokenStart())
    try {
      const response = await validationTokenAPI(code);
      response && callback && callback(response);
      dispatch(validationTokenSuccess(response));
      return response;
    } catch (error) {
      dispatch(validationTokenError(error.response.data));
      return error;
    }
  }
};



const resetPasswordStart = () =>{
  return{
    type : RESETPASSWORD_START
  }
}

const resetPasswordSuccess = response => {
  return {
    type: RESETPASSWORD_SUCCESS,
    payload: response,
  }
};

const resetPasswordError = error => {
  return {
    type: RESETPASSWORD_ERROR,
    errorMsg: error.errorMessage
  }};

export const resetPassword = (data, callback) => {
  return async (dispatch) => {
    dispatch(resetPasswordStart())
    try {
      const response = await resetPasswordAPI(data);
      dispatch(resetPasswordSuccess(response));
      response && callback && callback();
      return response;
    } catch (error) {
      dispatch(resetPasswordError(error));
      return error;
    }
  }
};


const forgotPasswordStart = () =>{
  return{
    type : FORGOTPASSWORD_START
  }
}

const forgotPasswordSuccess = response => {
  return {
    type: FORGOTPASSWORD_SUCCESS,
    payload: response,
  }
};

const forgotPasswordError = error => {
  return {
    type: FORGOTPASSWORD_ERROR,
    errorMsg: error.errorMessage
  }};

export const forgotPassword = (data, callback) => {
  return async (dispatch) => {
    dispatch(forgotPasswordStart())
    try {
      const response = await forgotPasswordAPI(data);
      dispatch(forgotPasswordSuccess(response));
      response && callback && callback();
      return response;
    } catch (error) {
      dispatch(forgotPasswordError(error));
      return error;
    }
  }
};


const logoutSuccess = response => {
  return {
    type: LOGOUT_SUCCESS,
    successMsg: LOGOUT_SUCCESS_MSG,
  }
}

const logoutError = error => {
  return {
    type: LOGOUT_ERROR,
    errorMsg: error.message
  }
};

export const logout = () => {
  return async (dispatch) => {
    try {
      const response = await logoutAPI();
      removeAllUserinfo();
      dispatch(logoutSuccess(response));
      return response;
    } catch (error) {
      dispatch(logoutError(error));
      return error;
    }
  }
};


