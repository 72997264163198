import {
  GET_MARGINS_START,
  GET_MARGINS_ERROR,
  GET_MARGINS_SUCCESS,
  UPDATE_MARGIN_START,
  UPDATE_MARGIN_SUCCESS,
  UPDATE_MARGIN_ERROR,
  GET_MARGINS_FOR_CONTRACT_START,
  GET_MARGINS_FOR_CONTRACT_SUCCESS,
  GET_MARGINS_FOR_CONTRACT_ERROR,
  GET_MARGINS_UPDATE_FOR_CONTRACT_START,
  GET_MARGINS_UPDATE_FOR_CONTRACT_SUCCESS,
  GET_MARGINS_UPDATE_FOR_CONTRACT_ERROR
} from "actions/constant";
import { toast } from "react-toastify";

const margin = (state = {}, action) => {
  switch (action.type) {
    case GET_MARGINS_START:
      return Object.assign({}, state, {
        isFetching: true
      });
    case GET_MARGINS_SUCCESS:
      return Object.assign({}, state, {
        marginsList: action.payload && action.payload.data,
        isFetching: false
      });
    case GET_MARGINS_ERROR:
      toast.error(action.errorMsg)

      return Object.assign({}, state, {
        marginsListError: action.errorMsg,
        isFetching: false
      });

    case UPDATE_MARGIN_START:
      return Object.assign({}, state, {
      });
    case UPDATE_MARGIN_SUCCESS:
      return Object.assign({}, state, {
        marginUpdate: action.payload,
      });
    case UPDATE_MARGIN_ERROR:
      toast.error(action.errorMsg)

      return Object.assign({}, state, {
      });

    default:
      return state;
  }
};

export default margin;