import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, FormLabel, DialogActions, Slide, makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import CustomInput from 'components/CustomInput/CustomInput';
import LoadingOverlay from 'react-loading-overlay';
import { createNewOrganization } from 'actions/organizationUpdates';
import { updateOrganizationData } from 'actions/organizationUpdates';
import CloseIcon from "@material-ui/icons/Close";
import { toast } from 'react-toastify';

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export default function OrganizationDetailsModal({ selectedOrganization, modalOpen, closeModal, isSavingCreate, isUpdating, isEditingOrganization, dispatch }) {
    const [id, setId] = useState('')
    const [organizationName, setOrganizationName] = useState('')
    const [organizationNameError, setOrganizationNameError] = useState(false)
    const [location, setLocation] = useState('')
    const [locationError, setLocationError] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        setId(selectedOrganization.id)
        setOrganizationName(selectedOrganization.name)
        setLocation(selectedOrganization.location)
        if (isEditingOrganization) {
            setOrganizationNameError('')
            setLocationError('')
        }
    }, [selectedOrganization])

    const onClose = () => {
        setId('')
        setLocation('')
        setOrganizationName('')
        setOrganizationNameError(false)
        setLocationError(false)
        closeModal()
    }

    const validateEntries = (type, value) => {

        switch (type) {
            case 'organization-name':
                if (!value) {
                    setOrganizationNameError('Organization name is required')
                } else if (new RegExp('^.{0,2}$').test(value)) {
                    setOrganizationNameError('Name must contain at least 3 letters')
                }
                else{
                    setOrganizationNameError('')
                }
                setOrganizationName(value)
                break;
             case 'location':
                if (!value) {
                    setLocationError('Location is required')
                } else if (new RegExp('^.{0,2}$').test(value) || !new RegExp('^[a-zA-Z0-9 ]*$').test(value)) {
                    setLocationError('Location must contain at least 3 letters and must not contain special symbols')
                } else {
                    setLocationError('')
                }
                setLocation(value)
                    break;
            
            default:
                break;
        }
    }
    const submitValues = () => {
        let values = {
            name: organizationName,
            location
        }

        if (!isEditingOrganization) {
            dispatch(createNewOrganization(values, () => {
                toast.success('Created Successfully!')
                onClose();
            }))
        } else {
            if (selectedOrganization.name === organizationName && selectedOrganization.location === location) {
                return false
            }
            else {
                dispatch(updateOrganizationData(values, id, () => {
                    toast.success('Updated Successfully!')
                    onClose();
                }))
            }
        }
    }

    return (
        <Dialog
            classes={{
                root: classes.center,
                paper: classes.modalscroll
            }}
            open={modalOpen}
            transition={Transition}
            keepMounted
            scroll={"body"}
            maxWidth={"md"}
            onClose={() => onClose()}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeaderFlex}
            >

                <h4 className={classes.modalTitle}>{isEditingOrganization ? "Edit Organization" : "Add Organization"} </h4>
                <CloseIcon className={classes.closeIcon} onClick={() => onClose()} />
            </DialogTitle>
            <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}>
                <LoadingOverlay
                    active={isUpdating || isSavingCreate}
                    spinner
                    text='Loading...'
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormLabel className={classes.labelHorizontal}>
                                Organization Name
                </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput error={organizationNameError} helperText={organizationNameError} inputProps={{ value: organizationName, type: "text", onChange: (event) => validateEntries('organization-name',event.target.value) }} formControlProps={{ fullWidth: true }} />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormLabel className={classes.labelHorizontal}>
                                Location
                </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput error={locationError} helperText={locationError} inputProps={{ value: location, type: "text", onChange: (event) => validateEntries('location',event.target.value) }} formControlProps={{ fullWidth: true }} />
                        </GridItem>
                    </GridContainer>
                </LoadingOverlay>
            </DialogContent>
            <DialogActions className={classes.modalFooterClr} >
                <GridContainer justify="center">
                    <Button disabled={organizationNameError !== '' || locationError !== "" } color='info' round className={classes.actionBtn}
                        onClick={() => submitValues()}
                    >{isEditingOrganization ? "Update" : "Add"}</Button>
                </GridContainer>
            </DialogActions>
        </Dialog>
    )
}
