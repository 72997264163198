import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/sprintListStyle.js";
import Pagination from 'components/Pagination/Pagination';
import { getAllSprintList } from 'actions/sprint';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { BreadCrumb } from 'utils/common';

class SprintList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            sprintList: null
        }
        this.props.dispatch(getAllSprintList(this.props.match.params.projectId));
    }

    componentWillReceiveProps(props) {
        console.log('spruint list', props)
        this.setState({sprintList: props.sprint.data})
    }


    gotoSprintEditPage(sprint) {
        this.props.history.push(`/admin/${this.props.match.params.projectId}/editSprint/${sprint._id}`)
      }
    gotoSprintVisibilityPage(sprint){
        this.props.history.push(`/admin/${this.props.match.params.projectId}/viewSprint/${sprint._id}`)
      }

      gotoProjectPage() {
        this.props.history.goBack()
      }

    render() {
        const { classes } = this.props;
        const { sprintList } = this.state;
        const isFetching  = this.props.sprint.isFetching;
        const simpleButtons =(sprint) =>( [
            { color: "info", icon: VisibilityIcon },
            { color: "success", icon: CreateIcon },
            { color: "danger", icon: DeleteIcon }
        ].map((prop, key) => {
            return (
                <Button
                    color={prop.color}
                    simple
                    className={classes.actionButton}
                    key={key}
                    onClick={() => key === 1 ? this.gotoSprintEditPage(sprint) : (key === 0 ? this.gotoSprintVisibilityPage(sprint) : '')}
                >
                    <prop.icon className={[classes.icon, classes.actionButtonRound, classes.tableActionIcon].join(' ')} />
                </Button>
            );
        })
        )
        let tableData;
        if(sprintList) {
            tableData = sprintList.map((e, i) => ({
                ...e, 
                index: i+1, 
                updatedAt: new Date(e.uptimestampdAt || e.createdAt).getTime(), 
                action: simpleButtons(e)
            }))
        }

        return (
            <div>
                <BreadCrumb pathArray={[
                    {name: 'Requestor'},
                    {name: 'Projects', path: '/admin/projects'},
                    {name: localStorage.getItem('projectSelected')}
                ]} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader className={classes.display} color="info">
                                <Button justIcon color='transparent' onClick={()=> this.gotoProjectPage()}> 
                                    <ArrowBackIcon/> 
                                </Button>
                                <h4 className={classes.cardTitle}>
                                    Sprints
                                </h4>
                                
                            </CardHeader>
                            <CardBody>
                                { tableData && <Table
                                    tableData={tableData}
                                    loading={isFetching}
                                    tableHead={[
                                        {
                                            Header: "#",
                                            accessor: "index",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                            className: classes.left,
                                            width: 70
                                        },
                                        {
                                            Header: "Sprint Title",
                                            accessor: "title",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                            className: classes.capitalize
                                        },
                                        {
                                            Header: "Type",
                                            accessor: "sprintType",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                            Cell: (props) => <span>{ props.value || 'N/A' }</span>,
                                            className: classes.capitalize
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "sprintStatus.status",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                            // className: classes.cellPadding,
                                        },
                                        {
                                            Header: "Update",
                                            accessor: "updatedAt",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                            Cell: (props) => <span>{moment(props.value).format('D MMM YYYY, h:mm A')}</span>
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "action",
                                            sortable: false,
                                            filterable: false,
                                            headerClassName: [classes.right, classes.tableHearders].join(' '),
                                            className: classes.right
                                        }
                                    ]}
                                />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(styles)(SprintList));
