import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import { getUserList } from 'actions/userUpdate.js';
import UserListStyle from 'assets/jss/material-dashboard-pro-react/views/UserListStyle'

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/UserListStyle.js";
import Pagination from 'components/Pagination/Pagination';

import { BreadCrumb } from 'utils/common';
import AddIcon from '@material-ui/icons/Add';
import { ROLES } from 'common/constants';



class UserList extends Component {

    constructor(props) {
        super(props);
        this.props.dispatch(getUserList());
        this.state = {
            userList: undefined,
            
            // lastName: [Hardy, Hardy],
            // userRole
        }
    }


    componentWillReceiveProps(props) {
        if(props.userUpdate.userLists){
        this.setState({userList: props.userUpdate.userLists})
        }
    }


    gotoAdd() {
        this.props.history.push('/admin/createUser')
    }

    gotoEdit(user) {
        this.props.history.push('/admin/editUser/' + user._id);
    }

    render() {
        const { classes } = this.props;
        const { userList } = this.state;
        const isFetching  = this.props.userUpdate.isFetching;
        const simpleButtons =(user) =>( [
            {icon: VisibilityIcon },
            {icon: CreateIcon },
            {icon: DeleteIcon }
        ].map((prop, key) => {
            return (
                <Button
                    color={prop.color}
                    simple
                    className={classes.actionButton}
                    key={key}
                    onClick={() => key === 1 ? this.gotoEdit(user) :  ''}
                >
                    <prop.icon className={[classes.icon, classes.actionButtonRound, classes.tableActionIcon].join(' ')} />
                </Button>
            );
        })
        )
        let tableData;
        if(userList && userList.length) {
            const users = userList.sort((a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            tableData = users.map((e, i) => {
                const roles = e.userRoles.filter(e => ROLES.includes(e)).map(r => r === ROLES[1] ? 'Requestor' : r === ROLES[2]? 'Doer' : r === ROLES[0] ? 'Admin' : '');
                return {
                index: i+1, 
                firstName: e.firstName,
                lastName: e.lastName,
                action: simpleButtons(e),
                userRole: roles.join(', '),
                company:e.company,
                role: e.companyRole,
                joined: new Date(e.createdAt).getTime(),
                Email: e.email
            }
        })
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader className={classes.display} color="info">
                                <div className={classes.cardTitle}>
                                    User List
                                </div>
                                <Button className={classes.addBtn1} round size='sm' color='white' onClick={()=>this.gotoAdd()}><AddIcon/> Add User</Button>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableData={tableData}
                                    loading={isFetching}
                                    tableHead={[
                                        {
                                            Header: "#",
                                            accessor: "index",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                            className: classes.left,
                                            width: 70
                                        },
                                        {
                                            Header: "First Name ",
                                            accessor: "firstName",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                        },
                                        {
                                            Header: "Last Name ",
                                            accessor: "lastName",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                        },
                                        {
                                            Header: "User Role ",
                                            accessor: "userRole",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                        },
                                        {
                                            Header: "Email ",
                                            accessor: "Email",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                        },
                                        {
                                            Header: "Company ",
                                            accessor: "company",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                        },
                                        {
                                            Header: "Role",
                                           accessor: "role",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                        },
                                        {
                                            Header: "Joined",
                                            accessor: "joined",
                                            filterable: false,
                                            // sortable: true,
                                            headerClassName: classes.tableHearders,
                                            Cell: (props) => <span>{
                                                moment(props.value).format('D MMM YYYY, h:mm A')}</span>
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "action",
                                            sortable: false,
                                            filterable: false,
                                            headerClassName: [classes.right, classes.tableHearders].join(' '),
                                            className: classes.right
                                        }
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(UserListStyle)(UserList));
