/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
import { Link } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { fluid, white, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left+" "+classes.full}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              {/* <a href="#home" className={block}>
                {rtlActive ? "الصفحة الرئيسية" : "Terms"}
              </a> */}
              <Link to={`/`} className={ classes.underLine }>Terms</Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              {/* <a href="#company" className={block}>
                {rtlActive ? "شركة" : "Privacy Policy"}
              </a> */}
                <Link to={`/`} className={ classes.underLine }>Privacy Policy</Link>
            </ListItem>
            <ListItem className={classes.inlineBlock+" "+classes.logo}>
              <img src={require('./../../assets/img/logo.ico')}/>
            </ListItem>
          </List>
        </div>
        {/* <p className={classes.right}>
          A &#9400;
          <a
            href="https://www.creative-tim.com?ref=mdpr-footer"
            className={anchor}
            target="_blank"
          >
            &nbsp;Root NYC&nbsp;
          </a>
          product
        </p> */}
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
