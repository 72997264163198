import {
  getOrgsAPI,
  getOrgsUsersAPI,
  createOrgsUsersAPI,
  getOrganizationAPI
} from "../api/organization";
import {
  GET_ORGS_USERS_START,
  GET_ORGS_USERS_SUCCESS,
  GET_ORGS_USERS_ERROR,
  CREATE_ORGS_USERS_START,
  CREATE_ORGS_USERS_SUCCESS,
  CREATE_ORGS_USERS_ERROR,
  GET_ORGANIZATIONS_LIST_START,
  GET_ORGANIZATIONS_LIST_SUCCESS,
  GET_ORGANIZATIONS_LIST_ERROR,
  GET_ORGANIZATION_START,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_ERROR
} from "./constant";
import { inviteUser } from "../api/inviteUser";


const getOrgsUsersStart = () => ({
  type: GET_ORGS_USERS_START
});
const getOrgsUsersSuccess = data => ({
  type: GET_ORGS_USERS_SUCCESS,
  payload: data
});
const getOrgsUsersError = error => ({
  type: GET_ORGS_USERS_ERROR,
  errorMsg: error?.response?.data?.errorMessage
});
export const getOrgsUsersList = orgId => {
  return async dispatch => {
    dispatch(getOrgsUsersStart());
    try {
      const response = await getOrgsUsersAPI(orgId);
      console.log(response);
      dispatch(getOrgsUsersSuccess(response));
      return response;
    } catch (error) {
      dispatch(getOrgsUsersError(error));
      return error;
    }
  };
};

const createOrgsUsersStart = () => ({
  type: CREATE_ORGS_USERS_START
});
const createOrgsUsersSuccess = data => ({
  type: CREATE_ORGS_USERS_SUCCESS,
  payload: data
});
const createOrgsUsersError = error => ({
  type: CREATE_ORGS_USERS_ERROR,
  errorMsg: error?.response?.data?.errorMessage
});
export const createNewUser = (data, invite, callback) => {
  return async dispatch => {
    dispatch(createOrgsUsersStart());
    try {
      const response = await createOrgsUsersAPI(data);
      console.log(response, invite);
      if (invite) {
        try {
          const inviteResponse = await inviteUser({
            userId: response.data._id,
            userRole: response.data.userRoles[0],
            resend: false
          });
        } catch (error) {
          dispatch(createOrgsUsersError(error.response, "invite"));
          return error;
        }
      }
      dispatch(createOrgsUsersSuccess(response));
      response && callback && callback();
      return response;
    } catch (error) {
      dispatch(createOrgsUsersError(error));
      return error;
    }
  };
};

const getOrganizationsListStart = () => ({
  type: GET_ORGANIZATIONS_LIST_START
});

const getOrganizationsListSuccess = data => ({
  type: GET_ORGANIZATIONS_LIST_SUCCESS,
  payload: data
});

const getOrganizationsListError = error => ({
  type: GET_ORGANIZATIONS_LIST_ERROR,
  errorMsg: error?.response?.data?.errorMessage
});

export const getOrganizationsList = () => {
  return async dispatch => {
    dispatch(getOrganizationsListStart());
    try {
      const response = await getOrgsAPI();
      dispatch(getOrganizationsListSuccess(response));
      return response;
    } catch (error) {
      dispatch(getOrganizationsListError(error));
      return error;
    }
  };
};

const getOrganizationStart = () => ({
  type: GET_ORGANIZATION_START
});

const getOrganizationSuccess = data => ({
  type: GET_ORGANIZATION_SUCCESS,
  payload: data
});

const getOrganizationError = error => ({
  type: GET_ORGANIZATION_ERROR,
  errorMsg: error?.response?.data?.errorMessage
});

export const getOrganization = (orgId) => {
  return async dispatch => {
    dispatch(getOrganizationStart());
    try {
      const response = await getOrganizationAPI(orgId);
      dispatch(getOrganizationSuccess(response));
      return response;
    } catch (error) {
      dispatch(getOrganizationError(error));
      return error;
    }
  }
}
