import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
// import Organizations from "views/Organizations/Organizations.js";
import OrgUsers from "views/Organizations/Users.js";
import OrgUserDetails from "views/Organizations/UserDetails.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import projectList from "views/projectList/projectList.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import SprintEdit from "views/sprintEdit/sprintEdit.js";
import EditProfile from "views/Pages/EditProfile.js";
import UserList from "views/UserList/UserList.js";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PaymentIcon from "@material-ui/icons/Payment";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ContactsIcon from "@material-ui/icons/Contacts";
import BusinessIcon from "@material-ui/icons/Business";
import WorkIcon from "@material-ui/icons/Work";
import GroupIcon from "@material-ui/icons/Group";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import SprintList from "views/SprintList/SprintList";
import VoucherList from "./views/Voucher/VoucherList";
import UserDetails from "views/UserDetails/UserDetails";
// import OrganizationDetails from "views/OrganizationDetails/OrganizationDetails";
// import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import DoerList from "views/DoerList/DoerList";
import DoerEdit from "views/DoerEdit/DoerEdit";
import FilePopUp from "components/FilePopUp/FilePopUp";
import ResetPassword from "views/Pages/ResetPassword";
import ForgotPassword from "views/Pages/ForgotPassword";
import Credit from "views/Pages/Credit";
import openContractList from "views/marketPlace/openContractList";
import EditContract from "./views/marketPlace/EditContract";
import UserStories from "views/Pages/UserStories";
import PaymentList from "views/Payment/PaymentList";
import UserStoryDetail from "views/Pages/UserStoryDetail";
import Margins from "views/Margins/Margins.js";
import OrganizationsList from "views/OrganizationsList/OrganizationsList";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    isDashBoardMenuItem: true,
    layout: "/admin"
  },
  {
    path: "/createDeveloper",
    name: "Developers",
    rtlName: "لوحة القيادة",
    component: UserDetails,
    layout: "/admin"
  },
  {
    path: "/organization",
    name: "Organization",
    rtlName: "لوحة القيادة",
    icon: BusinessIcon,
    component: OrganizationsList,
    isDashBoardMenuItem: true,
    layout: "/admin"
  },
  {
    path: "/user-org/:orgId",
    name: "Organization Users",
    rtlName: "لوحة القيادة",
    component: OrgUsers,
    layout: "/admin"
  },
  {
    path: "/create/:orgId",
    name: "Users",
    rtlName: "لوحة القيادة",
    component: OrgUserDetails,
    layout: "/admin"
  },
  {
    path: "/popUp",
    component: FilePopUp,
    layout: "/admin"
  },
  {
    path: "/edit-profile",
    name: "User Profile",
    rtlName: "لوحة القيادة",
    component: EditProfile,
    //isDashBoardMenuItem: true,
    layout: "/admin"
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    rtlName: "لوحة القيادة",
    component: ResetPassword,
    layout: "/auth"
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    rtlName: "لوحة القيادة",
    component: ForgotPassword,
    layout: "/auth"
  },
  {
    path: "/requestor",
    name: "Requestor",
    rtlName: "لوحة القيادة",
    icon: ContactsIcon,
    collapse: true,
    state: "pageCollapse",
    isDashBoardMenuItem: true,
    views: [
      {
        path: "/projects",
        name: "Projects",
        rtlName: "عالتسعير",
        icon: LocalLibraryIcon,
        pageTitle: "Requestor",
        mini: "PP",
        rtlMini: "ع",
        component: projectList,
        layout: "/admin"
      },
      {
        path: "/user-stories",
        name: "User Stories",
        rtlName: "عالتسعير",
        icon: FileCopyIcon,
        pageTitle: "Requestor",
        mini: "PP",
        rtlMini: "ع",
        component: UserStories,
        layout: "/admin"
      },
      {
        path: "/vouchers",
        name: "Vouchers",
        // rtlName: "عالتسعير",
        icon: LocalActivityIcon,
        pageTitle: "Requestor",
        mini: "PP",
        rtlMini: "ع",
        component: VoucherList,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/doer",
    name: "Developers",
    rtlName: "لوحة القيادة",
    icon: WorkIcon,
    component: DoerList,
    isDashBoardMenuItem: true,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "لوحة القيادة",
    icon: GroupIcon,
    component: UserList,
    isDashBoardMenuItem: true,
    layout: "/admin"
  },
  {
    path: "/credits",
    name: "Credits",
    rtlName: "لوحة القيادة",
    icon: CreditCardIcon,
    collapse: true,
    isDashBoardMenuItem: true,
    layout: "/admin",
    component: Credit,
    views: [
      {
        path: "/credits",
        name: "Dashboard",
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        pageTitle: "Credits Dashbaord",
        mini: "PP",
        rtlMini: "ع",
        component: Credit,
        layout: "/admin"
      },
      {
        path: "/margin",
        name: "Margin",
        rtlName: "هوامش",
        icon: GroupIcon,
        pageTitle: "Margin",
        mini: "PP",
        rtlMini: "ع",
        component: Margins,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/market-place",
    name: "Market Place",
    rtlName: "لوحة القيادة",
    icon: ContactsIcon,
    collapse: true,
    state: "openContract",
    isDashBoardMenuItem: true,
    views: [
      {
        path: "/open-contracts",
        name: "Open Contracts",
        rtlName: "عالتسعير",
        icon: LocalLibraryIcon,
        pageTitle: "Market Place",
        mini: "PP",
        rtlMini: "ع",
        component: openContractList,
        layout: "/admin"
      },
      {
        path: "/payments",
        name: "Payments",
        // rtlName: "عالتسعير",
        icon: PaymentIcon,
        pageTitle: "Market Place / Payments",
        mini: "PP",
        rtlMini: "ع",
        component: PaymentList,
        layout: "/admin"
      },
      {
        path: "/vouchers",
        name: "TBD",
        // rtlName: "عالتسعير",
        icon: LocalActivityIcon,
        pageTitle: "Requestor",
        mini: "PP",
        rtlMini: "ع",
        component: VoucherList,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/sprints/:projectId",
    name: "Sprints",
    rtlName: "لوحة القيادة",
    component: SprintList,
    layout: "/admin"
  },
  {
    path: "/:projectId/editSprint/:sprintId",
    name: "Update Sprint",
    rtlName: "لوحة القيادة",
    component: SprintEdit,
    layout: "/admin"
  },
  {
    path: "/:projectId/viewSprint/:sprintId",
    name: "Sprint Details ",
    rtlName: "لوحة القيادة",
    component: SprintEdit,
    layout: "/admin"
  },
  {
    path: "/createUser",
    name: "Usersc",
    rtlName: "لوحة القيادة",
    component: UserDetails,
    layout: "/admin", 
  },
  {
    path: "/createDeveloper",
    name: "Developers",
    rtlName: "لوحة القيادة",
    component: UserDetails,
    layout: "/admin"
  },
  // {
  //   path: "/createOrganization",
  //   name: "Organization",
  //   rtlName: "لوحة القيادة",
  //   component: OrganizationDetails,
  //   layout: "/admin"
  // },
  {
    path: "/editUser/:userId",
    name: "Users",
    rtlName: "لوحة القيادة",
    component: OrgUserDetails,
    layout: "/admin"
  },
  {
    path: "/editDoer/:doerId",
    name: "Doer",
    rtlName: "لوحة القيادة",
    component: DoerEdit,
    layout: "/admin"
  },
  {
    path: "/editContract/:contractKey",
    name: "Contract",
    rtlName: "لوحة القيادة",
    component: EditContract,
    layout: "/admin",
    pageTitle: "Market Place"
  },
  {
    //path: "/user-stories/:storyId",
    path: "/userStory-detail/:storyId",
    name: "Requestor",
    rtlName: "لوحة القيادة",
    component: UserStoryDetail,
    layout: "/admin",
    mini: "PP",
    rtlMini: "ع"
  },
  {
    path: "/login-page",
    name: "LoginPage",
    component: LoginPage,
    layout: "/auth"
  }
];

export default dashRoutes;
