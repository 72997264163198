import React, { useEffect, useState } from "react";
import { BreadCrumb } from "utils/common";
import { connect } from "react-redux";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "components/Card/CardHeader.js";
import UserListStyle from "assets/jss/material-dashboard-pro-react/views/UserListStyle";
import GridContainer from "components/Grid/GridContainer.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateIcon from "@material-ui/icons/Create";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.js";
import { useParams } from "react-router-dom";
import { getOrgsUsersList } from "actions/orgUsers";

const OrgUsers = ({
  classes,
  history,
  dispatch,
  orgsUserReducer,
  organizationUpdates
}) => {
  let { orgId } = useParams();

  useEffect(() => {
    dispatch(getOrgsUsersList(orgId));
  }, []);
  const gotoAdd = () => {
    history.push(`/admin/create/${orgId}`);
  };
  const gotoEdit = org => {
    history.push({
      pathname: "/admin/editOrg/" + org.index,
      state: { isEditOrg: true }
    });
  };
  const isEditOrg = orgId;
  const simpleButtons = user =>
    [{ icon: CreateIcon }].map((prop, key) => {
      return (
        <Button
          color={prop.color}
          simple
          className={classes.actionButton}
          key={key}
          onClick={() => {
            if (key === 0) {
              history.push(`/admin/editUser/${user?.user?.id}`);
            }
          }}
        >
          <prop.icon
            className={[
              classes.icon,
              classes.actionButtonRound,
              classes.tableActionIcon
            ].join(" ")}
          />
        </Button>
      );
    });
  const { isFetchingUsers, users } = orgsUserReducer;

  const formattedData = users?.map((item, index) => {
    const role = item?.user?.userRoles
      ?.map(item => {
        return item.role;
      })
      .join(", ");
    return {
      ...item.user,
      index: index + 1,
      role,
      action: simpleButtons(item)
    };
  });
  const tableHead = [
    {
      Header: "#",
      accessor: "index",
      filterable: false,
      headerClassName: classes.tableHearders,
      className: classes.left,
      width: 70
    },
    {
      Header: "First Name",
      accessor: "firstName",
      filterable: false,
      headerClassName: classes.tableHearders
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      filterable: false,
      headerClassName: classes.tableHearders
    },
    {
      Header: "User Role",
      accessor: "role",
      filterable: false,
      headerClassName: classes.tableHearders
    },
    {
      Header: "Email",
      accessor: "email",
      filterable: false,
      headerClassName: classes.tableHearders
    },
    {
      Header: "Added on",
      accessor: "createdAt",
      filterable: false,
      // sortable: true,
      headerClassName: classes.tableHearders,
      Cell: props => (
        <span>{moment(props.value).format("D MMM YYYY, h:mm A")}</span>
      )
    },
    {
      Header: "Actions",
      accessor: "action",
      sortable: false,
      filterable: false,
      headerClassName: [classes.right, classes.tableHearders].join(" "),
      className: classes.right
    }
  ];
  return (
    <div>
      <BreadCrumb
        pathArray={[
          {
            name: organizationUpdates?.activeOrg?.name || "Organizations",
            path: "/admin/organization"
          },
          { name: "users" }
        ]}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}></GridItem>
        <Card>
          <CardHeader className={classes.display} color='info'>
            <div className={classes.cardTitle}>Users</div>
            <Button
              className={classes.addBtn1}
              round
              size='sm'
              color='white'
              onClick={() => gotoAdd()}
            >
              <AddIcon /> Add User
            </Button>
          </CardHeader>
          <CardBody>
            <Table
              loading={isFetchingUsers}
              tableData={formattedData || []}
              tableHead={tableHead}
            />
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(UserListStyle)(OrgUsers));

// export default Organizations;
