import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { isLoggedIn } from "../../utils/common";

class AuthenticatedRoute extends Component {
  render() {
    const { children } = this.props;
    // User is logged in
    if (isLoggedIn()) {
      return [...children, <Redirect to={window.location.pathname !== '/' ? window.location.pathname : 'admin/dashboard'} />];
    }else{
      return <Redirect to="/auth/login-page" />;
    }
  }
}

export default AuthenticatedRoute;
