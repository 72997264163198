
import CommentSectionStyle from '../../material-dashboard-pro-react/views/CommentSectionStyle'
import customCheckboxRadioSwitch from "../../material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
    cardTitle,
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
const ContractEditStyle = {
    ...customCheckboxRadioSwitch,
    actionButton: {
        margin: "0 0 0 5px !important",
        padding: "5px !important",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
            marginRight: "0px !important"
        }
    },
    actionButtonRound: {
        width: "auto",
        height: "auto",
        minWidth: "auto"
    },
    icon: {
        verticalAlign: "middle",
        width: "17px",
        height: "17px",
        top: "-1px",
        position: "relative"
    },
    tableActionIcon: {
        color: grayColor[9]
    },

    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    staticFormGroup: {
        marginLeft: "0",
        marginRight: "0",
        paddingBottom: "10px",
        margin: "8px 0 0 0",
        position: "relative",
        "&:before,&:after": {
            display: "table",
            content: '" "'
        },
        "&:after": {
            clear: "both"
        }
    },
    activity: {
        float: 'left',
        display: 'flex'
    },
    display:{
        display:'block',
        alignItems: 'center'
    },
    staticFormControl: {
        marginBottom: "0",
        paddingTop: "8px",
        paddingBottom: "8px",
        minHeight: "34px"
    },
    inputAdornment: {
        marginRight: "8px",
        position: "relative"
    },
    inputAdornmentIconSuccess: {
        color: successColor[0] + "!important"
    },
    inputAdornmentIconError: {
        color: dangerColor[0] + "!important"
    },
    btnStyle:{
        justifyContent: "flex-end"
    },
    cancleBtn:{
        color: "gray",
        background: "none",
        boxShadow: "none"
    },
    updateBtn:{
        backgroundColor: infoColor[0],
        borderRadius: "50px"
    },
    selectPadding: {
        alignSelf: "flex-end !important",

    },
    textArea: {
        width: '100%',
        minHeight: '150px',
        border: 'solid 1px #00acc1',
        marginTop: '40px'

    },
    removeMargin: {
        '& p': {
            margin: '0px'
        }
    },
    headings:{

        color: infoColor[0],
        fontSize: "15px"
    },
    slSize:{
        width: "150px"
    },
    dateLogo: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    dateInput: {
        width: '200px'
    },
    dateInputFullWidth: {
        width: '100%'
    },
    bottom: {
        paddingBottom:"24px"
    },
    up: {
        paddingTop: "24px",
        paddingLeft: "253px",
        fontSize: "11px",
        color: infoColor[2]
    },
    backBtn: {
        background: "transparent",
        /* border: none; */
        boxShadow: "none"
    },
    creditWidth: {
        width: "150px"
    },

    flex1: {
        flex:1
    },
    tableHeight: {
        minHeight: '250px !important'
    },
    tableHearders: {
        color: infoColor[0]
    },
    designButton:{
        padding:"10px 15px",
        fontSize:"12px"
    },
    load: {
        position: 'absolute',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0',
        background: 'center noRepeat',
        url: '(https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif)'
    },
    paddingTop6: {
        paddingTop: '6px !important'
    },
    clickable: CommentSectionStyle.clickable,
    editorSave: CommentSectionStyle.editorSave

}

export default ContractEditStyle;
