import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import OrganizationListStyle from 'assets/jss/material-dashboard-pro-react/views/OrganizationListStyle'

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from "components/CustomButtons/Button.js";
import { toast } from 'react-toastify';
import styles from "assets/jss/material-dashboard-pro-react/views/UserListStyle.js";
import Pagination from 'components/Pagination/Pagination';
import { BreadCrumb } from 'utils/common';
import AddIcon from '@material-ui/icons/Add';
import { ROLES } from 'common/constants';
import OrganizationDetailsModal from './OrganizationDetailsModal';
import { getOrganizationsList, setActiveOrganization } from 'actions/organizationUpdates';

class OrganizationsList extends PureComponent {
    constructor(props) {
        super(props);
        this.props.dispatch(getOrganizationsList());

        this.state = {
            organizationList: [
            ],
            modalOpen: false,
            isEditingOrganization: false,
            selectedOrganization: {},
        }
    }

    componentWillReceiveProps(props) {
        if (props.organizationUpdates.organizationLists) {
            this.setState({ organizationList: props.organizationUpdates.organizationLists })
        }
        const { createOrganizationError, updateOrganizationError, getOrganizationListError,
        } = props.organizationUpdates;

        if (createOrganizationError || updateOrganizationError || getOrganizationListError) {
            if (createOrganizationError) {
                toast.error(createOrganizationError)
            } else if (updateOrganizationError) {
                toast.error(updateOrganizationError)
            } else if (getOrganizationListError) {
                toast.error(getOrganizationListError)
            }
        }
    }
    handleCreateUser(organization) {
        this.props.dispatch(setActiveOrganization(organization))
        this.props.history.push(`/admin/user-org/${organization.id}`)
    }
    render() {
        const { classes } = this.props;
        const { organizationList, selectedOrganization } = this.state;
        const { isFetching, isUpdating } = this.props.organizationUpdates;
        const { isSavingCreate } = this.props.organizationUpdates;
        const simpleButtons = (organization) => ([
            { icon: VisibilityIcon },
            { icon: CreateIcon },
            { icon: AddIcon }
        ].map((prop, key) => {
            return (
                <Button
                    color={prop.color}
                    simple
                    className={classes.actionButton}
                    key={key}
                    onClick={() => key === 1 ? this.setState({ isEditingOrganization: true, selectedOrganization: organization, modalOpen: true }) : key === 0 ? this.handleCreateUser(organization) : this.props.history.push(`/admin/create/${organization.id}`)}
                >
                    <prop.icon className={[classes.icon, classes.actionButtonRound, classes.tableActionIcon].join(' ')} />
                </Button>
            );
        })
        )
        let tableData = [];
        if (organizationList && organizationList.length) {
            tableData = organizationList.map((e, i) => {
                return {
                    index: i + 1,
                    organization: e.name,
                    location: e.location,
                    action: simpleButtons(e),
                    created: new Date(e.createdAt).getTime(),
                }
            })
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader className={classes.display} color="info">
                                <div className={classes.cardTitle}>
                                    Organization List
                                </div>
                                <Button className={classes.addBtn1} round size='sm' color='white' onClick={() => this.setState({ selectedOrganization: {}, isEditingOrganization: false, modalOpen: true })}><AddIcon /> Add Organization</Button>
                                <OrganizationDetailsModal selectedOrganization={selectedOrganization} isSavingCreate={isSavingCreate} isUpdating={isUpdating} dispatch={this.props.dispatch} isEditingOrganization={this.state.isEditingOrganization} closeModal={() => this.setState({ modalOpen: false, selectedOrganization: {} })} modalOpen={this.state.modalOpen} />
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableData={tableData}
                                    loading={isFetching}
                                    tableHead={[
                                        {
                                            Header: "#",
                                            accessor: "index",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                            className: classes.left,
                                            width: 70
                                        },
                                        {
                                            Header: "Organization",
                                            accessor: "organization",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                        },
                                        {
                                            Header: "Location",
                                            accessor: "location",
                                            filterable: false,
                                            headerClassName: classes.tableHearders,
                                        },

                                        {
                                            Header: "Created",
                                            accessor: "created",
                                            filterable: false,
                                            // sortable: true,
                                            headerClassName: classes.tableHearders,
                                            Cell: (props) => <span>{
                                                moment(props.value).format('D MMM YYYY, h:mm A')}</span>
                                        },
                                        {
                                            Header: "Actions",
                                            accessor: "action",
                                            sortable: false,
                                            filterable: false,
                                            headerClassName: [classes.right, classes.tableHearders].join(' '),
                                            className: classes.right
                                        }
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(OrganizationListStyle)(OrganizationsList));
