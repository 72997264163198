import axios from 'axios';
import { BASE_URL } from "../common/constants";
import { getToken} from "../utils/common";

export async function createNewOrganizationApi(data) {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getToken(),

        },
    }
    let url = `${BASE_URL}organizations`;
    const response = await axios.post(url, data, config);
    return response.data;
}

export async function organizationUpdateAPI(data, id) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    }
    let url = `${BASE_URL}organizations/${id}`;
    const response = await axios.put(url, data, config);
    return response.data;
  }

  export async function getOrganizations() {
    const config = {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    }
    let url = `${BASE_URL}organizations?page=1&limit=1000`;
    const response = await axios.get(url, config);
    return response.data;
  }
  
  