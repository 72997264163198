import { combineReducers } from "redux";
import simpleReducer from "./simpleReducer";
import project from "./project";
import contract from "./contract";
import sprint from "./sprint";
import userStories from "./userStories";
import error from "./error";
import success from "./success";
import login from "./login";
import deleteUser from "./deleteUser";
import userUpdate from "./userUpdate";
import margins from "./margins";
import createUsers from "./createUsers";
import organizationUpdates from "./organizationUpdates";
import discountManagement from "./discount";
import userCredit from "./credits";
import getPackages from "./package";
import orgsUserReducer from "./organizationUsers";

export default combineReducers({
  simpleReducer,
  contract,
  project,
  sprint,
  userStories,
  error,
  success,
  login,
  deleteUser,
  userUpdate,
  margins,
  createUsers,
  organizationUpdates,
  discountManagement,
  userCredit,
  getPackages,
  orgsUserReducer
});
