import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import projectListStyle from "assets/jss/material-dashboard-pro-react/views/projectListStyle.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { BreadCrumb } from "utils/common";
import Table from "components/Table/Table";
import Button from "../../components/CustomButtons/Button";
import SaveVoucher from "./SaveVoucher";
import { connect } from "react-redux";
import { getDiscountManagement } from "../../actions/discount";
import Visibility from "@material-ui/icons/Visibility";
import Create from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from 'moment';

class VoucherList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalState: false,
      coupons: []
    };
  }

  componentDidMount() {
    this.props.dispatch(getDiscountManagement());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.discountManagement.data) {
      this.setState({ coupons: nextProps.discountManagement.data });
    }
  }

  modalClickHandler = (data) => {
   if (data) {
      data.callback = () => {
        this.props.dispatch(getDiscountManagement());
      }
    }
    this.setState({ modalState: !this.state.modalState && data });
  };

  render() {
    const { classes } = this.props;
    const { coupons } = this.state;
    const isFetching  = this.props.discountManagement.isFetching;
    const simpleButtons = (coupon) => ([
        { icon: Visibility },
        { icon: Create },
        { icon: DeleteIcon }
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={() => key === 1 ? this.modalClickHandler(coupon) : (key === 0 ? '' : (key === 2 ? '' : ''))}
          >
            <prop.icon className={[classes.icon, classes.actionButtonRound, classes.tableActionIcon].join(' ')} />
          </Button>
        );
      })
    );
    let tableData;
    if (coupons) {
      window.moment = moment;
      coupons.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      tableData = coupons.map((item, index) => {
        let expiryDate = moment(item.createdAt).add(item.expiry, 'days');
        return {
          index: index + 1,
          name: item.name,
          code: item.code,
          expiry: item.expiry,
          amount: item.amount / 100,
          expiredOn: expiryDate.toDate().getTime(),
          isValid: item.expired || (Date.now() > new Date(expiryDate).getTime()) ? 'False' : 'True',
          action: simpleButtons(item)
        }
      });
    }

    return (
      <div>
        <BreadCrumb pathArray={[
          { name: "Requestor" },
          { name: "Vouchers" }
        ]}/>
        <Card>
          <CardHeader color="info" className={classes.cardHdr}>
            <div className={classes.voucherHeading}>
         
            Discount Code List
            </div>
            <Button className={classes.addVoucherBtn} color="primary" onClick={this.modalClickHandler}> + Add
            Voucher</Button></CardHeader>
          <CardBody>
            {tableData && <Table
              tableHead={[
                {
                  Header: "#",
                  accessor: "index",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  className: classes.left,
                  width: 70
                },
                {
                  Header: "Discount Name",
                  accessor: "name",
                  filterable: false,
                  headerClassName: classes.tableHearders
                },
                {
                  Header: "Discount Code",
                  accessor: "code",
                  filterable: false,
                  headerClassName: classes.tableHearders
                  // className: classes.cellPadding,
                },
                {
                  Header: "Discount Amount",
                  accessor: "amount",
                  filterable: false,
                  headerClassName: classes.tableHearders
                },
                {
                  Header: "Is Valid",
                  accessor: "isValid",
                  filterable: false,
                  headerClassName: classes.tableHearders
                },
                {
                  Header: "Expire Days",
                  accessor: "expiry",
                  filterable: false,
                  headerClassName: classes.tableHearders
                },

                {
                  Header: "Expires On",
                  accessor: "expiredOn",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  Cell: (props) => <span>{
                    moment(props.value).format('D MMM YYYY, h:mm A')}</span>
                },
                {
                  Header: "Actions",
                  accessor: "action",
                  sortable: false,
                  filterable: false,
                  className: classes.right,
                  headerClassName: [classes.right, classes.tableHearders].join(" ")
                }
              ]}
              tableData={tableData}
              loading={isFetching}
            />
            }
          </CardBody>
        </Card>
        {this.state.modalState && (
          <SaveVoucher closeModal={this.modalClickHandler} modalState={this.state.modalState}/>)}
      </div>
    );
  }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(projectListStyle)(VoucherList));
