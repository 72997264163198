import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Grid, Paper, TablePagination, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
// import CustomTable from "components/Table/Table";
import { CreditStyle, BuyMoreButton, BorderlessPanel } from '../../assets/jss/material-dashboard-pro-react/views/CreditStyle';
import Card from 'components/Card/Card';
import { getCredit } from 'actions/credits';
import { getUserData } from 'utils/common';
import { getCreditByProject } from 'actions/credits';
import Pagination from 'components/Pagination/Pagination';
// import Pagination from 'components/Pagination/Pagination';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';



const totalSprintCredits = (project) => {
    let total;
    if (project && project.sprints) {
        total = project.sprints.reduce((acc, sprint) => { return acc + sprint.totalCredits }, 0);
    }
    return total;
}

class Credit extends Component {

    state = {
        expanded: false,
        activeTablePage: 0,
        rowsPerPage: 5,
       
       
        activeTablePage: 1,
        defaultPageSize: 10,
        paginationOpts: null,
        bunch: 1,
        bunchSize: 10
    }





    componentWillReceiveProps(props){
        let {bunchSize} = this.state
    if(props.projects) {
        // console.log('total data = ', props.tableData);
        let noOfPages = Math.ceil(props.projects.length / bunchSize) ;
        this.setState({
            paginationOpts:this.createPagination(
                noOfPages,
                 1),
            bunchSize
        })
    }}





    componentDidMount() {
        const user = getUserData();
        this.props.dispatch(getCredit(user._id));
        this.props.dispatch(getCreditByProject(user._id));
    }

    handlePanelChange = panel => {
        this.setState({ expanded: (this.state.expanded === panel) ? !this.state.expanded : panel  });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ activeTablePage: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ bunchSize: parseInt(event.target.value, 10), activeTablePage: 1 });
    };

    labelDisplayedRows = (from, to, count) => {

    }








    createPagination(dataSize, activeIndex) {
      
        const result = [];
        if(this.state.bunch !== 1) {
            result[0] = { text: <ChevronLeftIcon />, onClick: () => this.onTablePageChange('prev', dataSize)}
        }
        if((this.state.bunch * this.state.bunchSize < dataSize)) {
            result[dataSize + 1] = { text: <ChevronRightIcon />, onClick: () => this.onTablePageChange('next', dataSize) }
        }
        
        const {bunch, bunchSize} = this.state;
        const loopSize = (bunch * bunchSize ) >= dataSize ? dataSize : (bunch * bunchSize);
        for (let i = ((this.state.bunch - 1) * this.state.bunchSize + 1); i <= loopSize; i++) {
            result[i] = { text: i, onClick: () => this.onTablePageChange(i, dataSize), active: i === activeIndex }
        }
        //console.log('create page =', dataSize, activeIndex, this.state, loopSize, result);
        return result;
    }

    onTablePageChange(event, dataSize) {
        let activeTablePage = this.state.activeTablePage;
        let bunch = this.state.bunch;
        let bunchSize = this.state.bunchSize;
        switch (event) {
            case 'prev':
                if(activeTablePage > (bunch - 1) * bunchSize + 1) {
                    activeTablePage = activeTablePage === 1 ? 1 : activeTablePage - 1;
                    // this.goPrev();
                } else {
                    bunch = bunch - 1 ? bunch - 1 : bunch;
                    activeTablePage = activeTablePage === 1 ? 1 : activeTablePage - 1; 
                }
                //this.goPrev()
                break;
            case 'next':
                if(activeTablePage < bunch * bunchSize) {
                    activeTablePage = activeTablePage === this.state.paginationOpts.length - 2 ? activeTablePage : activeTablePage + 1;
                    // this.goNext();
                } else {
                    bunch = bunch + 1;
                    activeTablePage = (bunch - 1) * bunchSize + 1 
                }
                // activeTablePage = (bunch - 1) * bunchSize + 1 
                //this.goNext()
                break;
            default:
                activeTablePage = event;
                console.log(this.table)
                // this.table.current.onPageChange(activeTablePage - 1)
        }
        this.setState({
            activeTablePage,
            bunch: bunch,
        }, () => {
            this.setState({
                paginationOpts: this.createPagination(dataSize, activeTablePage)
            }, () => {
                // this.table.current.onPageChange(activeTablePage - 1)
            })
        })
    }







    renderProjects = () => {
        const { activeTablePage,expanded, bunchSize } = this.state;
        const { classes, projects } = this.props;
        return (
            projects &&
            projects.slice((activeTablePage - 1) * bunchSize, (activeTablePage - 1)* bunchSize + bunchSize)
                .map((project, rowIndex) => (
                    <TableRow key={project.key}>
                        <TableCell align="left">{rowIndex + 1}</TableCell>
                        <TableCell component="th" scope="row" align="left" className={classes.projectCell}>
                            <BorderlessPanel expanded={expanded === project.key} onChange={() => this.handlePanelChange(project.key)}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${project.title}-contetns`}
                                    id={`${project.key}-panel`}
                                >
                                    {project.title}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid
                                        container
                                        direction="column">
                                        {this.renderSprints(project)}
                                        <Grid container direction="row" justify="flex-end">
                                            <div className={classes.stat}> {totalSprintCredits(project)} <p className={classes.sub}>Total Sprint</p>
                                            </div>
                                            <div className={classes.creditCount}>{project.totalCredits} <p className={classes.creditSub}>Total Credits</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </BorderlessPanel>
                        </TableCell>
                        <TableCell className={classes.creditCell}>{project.totalCredits}</TableCell>
                    </TableRow>
                ))
        );
    }

    renderSprints = project => {
        const { classes } = this.props;
        return (
            project.sprints && project.sprints.map(sprint => {
                return (
                    <BorderlessPanel key={sprint.key}>
                        <ExpansionPanelSummary
                            aria-controls={`${sprint.title}-contetns`}
                            id={`${sprint.key}-panel`}
                        >
                            <div className={classes.sprintContainer}>
                                <Typography className={classes.sprintHeading}>{sprint.title}</Typography>
                                <Typography className={classes.sprintHeading}>Credits Used - {sprint.totalCredits}</Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ padding: '0px' }}>
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.sprintHeader}>#</TableCell>
                                        <TableCell className={classes.sprintHeader}>User Story</TableCell>
                                        <TableCell className={classes.sprintHeader}>Descrption</TableCell>
                                        <TableCell className={classes.sprintHeader}>Date</TableCell>
                                        <TableCell className={classes.sprintHeader}>Status</TableCell>
                                        <TableCell className={classes.sprintHeader}>Credits</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.renderStories(sprint)}
                                </TableBody>
                            </Table>
                        </ExpansionPanelDetails>
                    </BorderlessPanel>
                );
            })
        );
    }

    renderStories = sprint => {
        return (
            sprint.stories && sprint.stories.map(story => (
                <TableRow key={story.key}>
                    <TableCell component="th" scope="row">
                        {story.key}
                    </TableCell>
                    <TableCell >{story.title}</TableCell>
                    <TableCell >{story.description}</TableCell>
                    <TableCell >{story.updatedAt}</TableCell>
                    <TableCell >{story.review}</TableCell>
                    <TableCell >{story.creditCost}</TableCell>
                </TableRow>
            ))
        );
    }



    render() {
        const { activeTablePage, bunchSize, paginationOpts, defaultPageSize } = this.state;
        const { classes, creditList: { credits }, projects } = this.props;
        const user = getUserData();
        

        return (
            <React.Fragment>
                <GridContainer>
                    <GridItem md={12} lg={12}>
                        <Paper>
                            <Grid container spacing={3} alignItems="center" justify="space-around">
                                <Grid item>
                                    <div className={classes.floatIcon}>
                                        <CardIcon color="info"><CreditCardIcon
                                            fontSize="large" /></CardIcon>
                                    </div>
                                    <div className={classes.cardTitle}>
                                        Credits Managment
                                </div>
                                </Grid>
                                <Grid item >
                                    <div className={classes.stat}>
                                        {credits && credits.available}
                                        <p className={classes.sub}>User Credits Available</p>
                                    </div>
                                </Grid>
                                <Grid item >
                                    <div className={classes.stat}>
                                        {credits && credits.reserved}
                                        <p className={classes.sub}>User Credits Reserved</p>
                                    </div>
                                </Grid>
                                <Grid item >
                                    <div className={classes.stat}>
                                        {credits && credits.total}
                                        <p className={classes.sub}>Total Credits Purchased</p>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <BuyMoreButton variant="extended" size="small" color="primary" disabled={user.userRoles.includes('Admin')}>
                                        $ Buy More Credits
                                     </BuyMoreButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    </GridItem>
                </GridContainer>

                <div style={{ marginTop: '52px' }}>
                    <GridContainer>
                        <GridItem md={12} lg={12}>
                            <Card>
                                <CardHeader color="info">
                                    <Typography variant="h6" style={{ fontWeight: '100' }}>
                                        Credit By Project</Typography>
                                </CardHeader>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHearders}>#</TableCell>
                                            <TableCell align="left" className={classes.tableHearders}>Project Name</TableCell>
                                            <TableCell className={classes.tableHearders}>Total Credits</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.renderProjects()}
                                    </TableBody>
                                </Table>
                               {/* <Pagination pages={this.state.paginationOpts}></Pagination> */}


                                 <div className={classes.right}>
                                    {   this.state.paginationOpts && 
                                        <Pagination pages={this.state.paginationOpts} color='info'/>
                                    }
                                </div>


                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
    }

const mapSateToProps = state => ({
    creditList: state.userCredit.creditList,
    projects: state.userCredit.creditsByProjectList,
});

export default connect(mapSateToProps)(withStyles(CreditStyle)(Credit));
