import React, {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card";
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from "components/CustomButtons/Button.js";
import CardFooter from 'components/Card/CardFooter';
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import GridItem from "components/Grid/GridItem.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {BreadCrumb} from 'utils/common';
import styles from "assets/jss/material-dashboard-pro-react/views/DoerEditStyle";
import {toast} from 'react-toastify';
import FilePopUp from 'components/FilePopUp/FilePopUp';
import {getDoerDetails} from 'actions/userUpdate';
import {USER_STATUSES, ROLES, SKILLS} from 'common/constants';
import {getUserContracts} from 'actions/userUpdate';
import {updateDoerData, updateDoerStatus} from 'actions/userUpdate';
import LoadingOverlay from '../../../node_modules/react-loading-overlay';

class DoerEdit extends Component {

  constructor(props) {
    super(props)

    this.state = {
      name: '',
      fName: '',
      lName: '',
      baseRate: '',
      roles: [],
      skills: [],
      status: '',
      invite: false,
      showPopUp: false,
      currentDoer: {},
      statusChanged: false,
    };

    this.emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  componentDidMount() {
    this.props.dispatch(getDoerDetails(this.props.match.params.doerId, (response) => {
      if (response && response.data) {
        let currentDoer = response.data;
        let roles = currentDoer.user.userRoles.map(userRole => ({id: userRole.id, role: userRole.role}));
        let skills = currentDoer.skills.map(skillDetail => ({
          id: skillDetail._id,
          baseRate: skillDetail.baseRate,
          skill: skillDetail.skill.skill
        }));
        this.setState({
          currentDoer: currentDoer,
          roles: roles,
          skills: skills,
          firstName: currentDoer.user.firstName,
          lastName: currentDoer.user.lastName,
          status: currentDoer.user.userStatus,
          id: currentDoer.user.id || currentDoer.user._id
        });
        this.props.dispatch(getUserContracts(currentDoer.user.id || currentDoer.user._id));
      }
    }));
  }

  componentWillReceiveProps(props) {
    if (props.userUpdate.contracts && props.userUpdate.contracts.length) {
      const result = props.userUpdate.contracts;
      this.setState({
        contracts: result,
        MSA: result.filter(e => e.uploadFileType === 'MSA'),
        NDA: result.filter(e => e.uploadFileType === 'NDA'),
        govId: result.filter(e => e.uploadFileType && (e.uploadFileType !== 'NDA' && e.uploadFileType !== 'MSA'))
      });
    }
  }

  onStatusChange(event) {
    this.setState({
      status: event.target.value,
      statusChanged: true
    });
  }

  onRoleChange(event, id) {
    const {roles} = this.state;
    let updatedRoles = roles.map(roleDetails => {
      if (roleDetails.id === id) {
        return {...roleDetails, role: event.target.value}
      }
      return roleDetails;
    });
    this.setState({
      roles: updatedRoles
    });
  }

  onSkillChange(event, id) {
    const {skills} = this.state;
    let updatedSkills = skills.map(skill => {
      if (skill.id === id) {
        return {...skill, skill: event.target.value}
      }
      return skill;
    });
    this.setState({
      skills: updatedSkills
    });
  }

  onBaseRateChange(event, id) {
    const {skills} = this.state;
    let baseRate = event.target.value;
    if (isNaN(baseRate)) {
      return;
    }
    let updatedSkills = skills.map(skill => {
      if (skill.id === id) {
        return {...skill, baseRate: parseInt(baseRate)}
      }
      return skill;
    });
    this.setState({
      skills: updatedSkills
    })
  }

  onFirstNameChange(event) {
    this.setState({
      firstName: event.target.value
    })
  }

  onLastNameChange(event) {
    this.setState({
      lastName: event.target.value
    })
  }

  saveChanges() {
    let skills = [];
    if (this.state.skills && this.state.skills.length > 0) {
      skills = this.state.skills.map(skill => ({
        type: skill.skill,
        baseRate: skill.baseRate,
        rateCurrency: "USD"
      }))
    }
    const updatePayload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      profiles: [
          {
              type: "User",
              skills: skills
          }
      ]
    };

    let updateCallbackFn = () => {
      this.updateCallback()
    };

    if (this.state.statusChanged) {
      updateCallbackFn = () => {
        this.props.dispatch(updateDoerStatus({status: this.state.status}, this.state.id, () => {
          this.updateCallback()
        }));
      }
    }

    this.props.dispatch(updateDoerData(updatePayload, this.state.id, updateCallbackFn))
  }

  updateCallback() {
    toast.success("Doer updated successfully");
    this.props.history.goBack();
  }

  validate(validators, value) {
    let err;
    for (let i in validators) {
      const validator = validators[i];
      switch (validator.type) {
        case 'required':
          err = value ? '' : validator.msg;
          break;
        case 'pattern':
          err = new RegExp(validator.pattern).test(value) ? '' : validator.msg;
          break;
        case 'maxlength':
          err = value.length <= validator.maxLength ? '' : validator.msg;
          break;
      }
      if (err) {
        return err
      }
    }
    return err;
  }

  hasError() {
    for (let i in this.state) {
      if (i.includes('Err') && this.state[i]) {
        return true;
      }
    }
  }


  showPopUp(type, i) {
    this.setState({
      showPopUp: true,
      selectedContracts: this.state[type],
      activeIndex: i
    })
  }

  closePopUp() {
    this.setState({
      showPopUp: false
    })
  }

  goBack() {
    this.props.history.goBack();
  }

  getFileNameFromUrl (url) {
    const splitUrl = url.split('/');
    const namePart = splitUrl[splitUrl.length - 1];
    let name = namePart.substr(36); // removes the uuid in front of the name
    name = name.substring(0, name.length - 13); // removes the timestamp at the end of the name

    return decodeURIComponent(name);
  }

  getExtensionFromFileName (url) {
    const splitUrl = url.split('/');
    const namePart = splitUrl[splitUrl.length - 1];

    return namePart.split('.')[1];
  }

  render() {
    const {classes} = this.props;
    const {loading, doerUpdating} = this.props.userUpdate;
    const {
      firstName, lastName, roles, skills, nameErr, status,
      baseRateErr, showPopUp, govId, NDA, selectedContracts,
      activeIndex
    } = this.state;

    return (
      <div>
        {showPopUp &&
        <FilePopUp fileList={selectedContracts} activeIndex={activeIndex} onClosePopUp={() => this.closePopUp()}/>}
        <BreadCrumb pathArray={[
          {name: 'Doer', path: '/admin/doer'},
          {name: 'Profile'},
        ]}/>
        <LoadingOverlay
          active={loading || doerUpdating}
          spinner
          text={loading ? 'Loading...' : 'Updating...'}
        >
          <Card>
            <CardHeader color="info" className={classes.cardHeader}>
              <Button justIcon color='transparent' onClick={() => this.goBack()}>
                <ArrowBackIcon/>
              </Button>
              <div className={classes.cardTitle}>
                Profile
              </div>
            </CardHeader>
            <CardBody>
              {!loading &&
              <form>
                <GridContainer justify="flex-end">
                  <GridItem xs={12} sm={12} md={3}></GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <div className={classes.headings + ' ' + classes.titleMargin}>PROFILE DETAILS</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      First Name
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      className={classes.textColor}
                      error={nameErr}
                      helperText={nameErr}
                      inputProps={{
                        defaultValue: firstName,
                        type: "text",
                        onChange: (event) => this.onFirstNameChange(event)
                      }}
                      formControlProps={{fullWidth: true}}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      Last Name
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      className={classes.textColor}
                      error={nameErr}
                      helperText={nameErr}
                      inputProps={{
                        defaultValue: lastName,
                        type: "text",
                        onChange: (event) => this.onLastNameChange(event)
                      }}
                      formControlProps={{fullWidth: true}}
                    />
                  </GridItem>
                </GridContainer>
                <>
                  {roles && roles.length > 0 && roles.map(role => (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Role
                        </FormLabel>
                      </GridItem>
                      <GridItem className={classes.selectPadding} xs={12} sm={12} md={9}>
                        <Select
                          onChange={(event) => this.onRoleChange(event, role.id)}
                          value={role.role}
                          className={classes.slSize + ' ' + classes.textColor}
                          MenuProps={{className: classes.selectMenu}}
                          classes={{select: classes.select}}>
                          <MenuItem disabled classes={{root: classes.selectMenuItem}}>
                            Choose Status
                          </MenuItem>
                          {
                            ROLES.map(role =>
                              <MenuItem value={role} classes={{root: classes.selectMenuItem}}>
                                {role}
                              </MenuItem>
                            )
                          }
                        </Select>
                      </GridItem>
                    </GridContainer>
                  ))
                  }
                  {skills && skills.length > 0 && skills.map(skill => (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <FormLabel className={classes.labelHorizontal}>
                            Skill
                          </FormLabel>
                        </GridItem>
                        <GridItem className={classes.selectPadding} xs={12} sm={12} md={6}>
                          <Select
                            onChange={(event) => this.onSkillChange(event, skill.id)}
                            value={skill.skill}
                            className={classes.slSize + ' ' + classes.textColor}
                            MenuProps={{className: classes.selectMenu}}
                            classes={{select: classes.select}}>
                            <MenuItem disabled classes={{root: classes.selectMenuItem}}>
                              Choose Status
                            </MenuItem>
                            {
                              SKILLS.map(skill =>
                                <MenuItem value={skill} classes={{root: classes.selectMenuItem}}>
                                  {skill}
                                </MenuItem>
                              )
                            }
                          </Select>
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3} className={classes.sectionSpacing}>
                          <FormLabel className={classes.labelHorizontal}>
                            Base Rate (in $)
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            className={classes.textColor}
                            error={baseRateErr}
                            helperText={baseRateErr}
                            inputProps={{
                              defaultValue: skill.baseRate,
                              type: "text",
                              onChange: (event) => this.onBaseRateChange(event, skill.id)
                            }}
                            formControlProps={{fullWidth: true}}
                          />
                        </GridItem>
                      </GridContainer>
                    </>
                  ))}
                </>
                <hr></hr>

                <GridContainer justify="flex-end">
                  <GridItem xs={12} sm={12} md={3}></GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <div className={classes.headings + ' ' + classes.titleMargin}>DOCUMENTS UPLOADED</div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Contract
                    </FormLabel>
                  </GridItem>
                  <GridItem className={classes.selectPadding + ' d-block'} xs={12} sm={12} md={9}>
                    {NDA && NDA.map((doc, index) => (
                      <span className={classes.textColor + ' mr-5 d-flex float-left ' + classes.marginAdj}
                        onClick={() => this.showPopUp('NDA', index)}>
                        <img height='25px' src={require('./../../assets/img/img_th.png')} className='mr-3'/>
                        {this.getFileNameFromUrl(doc.value)}
                      </span>
                    ))}
                    {(!NDA || !NDA.length) &&
                    <span className={classes.textColor + ' mr-3 d-flex float-left align-items-center'}>
                      No files uploaded
                    </span>
                    }
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Government ID
                    </FormLabel>
                  </GridItem>
                  <GridItem className={classes.selectPadding + ' d-block'} xs={12} sm={12} md={9}>
                    {govId && govId.map((doc, index) => (
                      <span className={classes.textColor + ' mr-5 d-flex float-left ' + classes.marginAdj}
                        onClick={() => this.showPopUp('govId', index)}>
                        <img height='25px'
                          src={this.getExtensionFromFileName(doc.value) === 'pdf' ? require('./../../assets/img/pdf_th.jpg') : require('./../../assets/img/img_th.png')}
                          className='mr-3'/> {this.getFileNameFromUrl(doc.value)}
                      </span>
                    ))}
                    {(!govId || !govId.length) &&
                    <span className={classes.textColor + ' mr-3 d-flex float-left'}>
                      No files uploaded
                    </span>
                    }
                  </GridItem>
                  <GridItem className={classes.selectPadding} xs={12} sm={12} md={9}>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Status
                    </FormLabel>
                  </GridItem>
                  <GridItem className={classes.selectPadding} xs={12} sm={12} md={9}>
                    <Select
                      onChange={(event) => this.onStatusChange(event)}
                      value={status}
                      className={classes.slSize + ' ' + classes.textColor}
                      MenuProps={{className: classes.selectMenu}}
                      classes={{select: classes.select}}>
                      <MenuItem disabled classes={{root: classes.selectMenuItem}}>Choose Status</MenuItem>
                      {
                        USER_STATUSES.map(userStatus =>
                          <MenuItem value={userStatus} classes={{root: classes.selectMenuItem}}>
                            {userStatus}
                          </MenuItem>
                        )
                      }
                    </Select>
                  </GridItem>
                </GridContainer>
              </form>
              }
            </CardBody>

            <CardFooter className={classes.btnStyle}>
              <Button color='transparent' round onClick={() => this.goBack()}>Cancel</Button>
              <Button color='info' round disabled={this.hasError()} onClick={() => this.saveChanges()}>
                Save Changes
              </Button>
            </CardFooter>
          </Card>
        </LoadingOverlay>
      </div>
    )
  }
}

export const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(styles)(DoerEdit));
