import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import projectListStyle from "assets/jss/material-dashboard-pro-react/views/projectListStyle.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table";

import { connect } from "react-redux";
import { getContractList } from "../../actions/contract";
import { getDoerDetails } from "../../actions/userUpdate";
import moment from 'moment';
import { paymentStatus } from "../../common/constants";

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: []
    }
  }

  componentDidMount() {
    if(this.props.contract && this.props.contract.paymentContracts && this.props.contract.paymentContracts.length > 0){
      console.log("Contract already exists")
      return;
    }
    this.props.dispatch(getContractList('?status=AWAITING_PAYMENT,PAID,DONE&detailed=true', false, true));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contract.paymentContracts) {
      this.setState({contracts: nextProps.contract.paymentContracts})
    }
  }

  onEditContract(id, contractKey) {
      this.props.history.push({
          pathname: `/admin/editContract/${contractKey}`,
          state: { contractId:id }
      })
  }

  render() {
    const { classes } = this.props;
    let { contracts } = this.state;
    const isFetching = this.props.contract.isFetching;
    let tableData;

    const openContract = (i,  id, contractKey) => {
      return(
          <a href="#" className={ classes.contractHeading } onClick={() => this.onEditContract(id, contractKey)}>{ contractKey }</a>
      )
    }

    if(contracts) {
      window.moment = moment;
      contracts.sort((a,b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      if(contracts && contracts.length){
        contracts = contracts.filter(f => paymentStatus.includes(f.contractStatus)) || []

        tableData = contracts.map((contract, index) => {
            return {
                index: index + 1,
                contractId: openContract(index, contract.id, contract.contractKey),
                userStory: contract.stories && contract.stories[0] && contract.stories[0].title || "",
                status: contract.contractStatus,
                project: contract.stories && contract.stories[0].project && contract.stories[0].project.title,
                sprint: contract.stories && contract.stories[0].sprint && contract.stories[0].sprint.title,
                doer: contract.userDetails && contract.userDetails
            }
          })
      }
    }
    return (
      <div>
        <Card>
          <CardHeader color="info" className={classes.cardHdr}>
            <div className={classes.voucherHeading}>
              Contracts
            </div>
          </CardHeader>
          <CardBody>
             <Table
              tableHead={[
                {
                  Header: "#",
                  accessor: "index",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  className: classes.left,
                  width: 70
                },
                {
                  Header: "ContractID",
                  accessor: "contractId",
                  filterable: false,
                  headerClassName: classes.tableHearders
                },
                {
                  Header: "User Story",
                  accessor: "userStory",
                  filterable: false,
                  headerClassName: classes.tableHearders
                  // className: classes.cellPadding,
                },
                {
                  Header: "Status",
                  accessor: "status",
                  filterable: false,
                  headerClassName: classes.tableHearders
                },
                {
                  Header: "Project",
                  accessor: "project",
                  filterable: false,
                  headerClassName: classes.tableHearders
                },
                {
                  Header: "Sprint",
                  accessor: "sprint",
                  filterable: false,
                  headerClassName: classes.tableHearders
                },
                {
                  Header: "Doer",
                  accessor: "doer",
                  filterable: false,
                  headerClassName: classes.tableHearders
                }
              ]}
              loading={isFetching}
              tableData={tableData}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}


const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(projectListStyle)(PaymentList));