import React, { Component } from "react";
import { connect } from "react-redux";


import withStyles from "@material-ui/core/styles/withStyles";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from 'components/Card/CardFooter';
import Info from "components/Typography/Info.js";
import Divider from "@material-ui/core/Divider";

import FormLabel from "@material-ui/core/FormLabel";

import styles from "assets/jss/material-dashboard-pro-react/views/userDetailsStyle.js";
import { getUserDetails } from 'actions/userUpdate';
import { getUserId } from "../../utils/common";
import { updateUserData } from "actions/userUpdate";
import { Select, MenuItem } from "@material-ui/core";
import { loadCountryList } from "actions/userUpdate";
import { loadTimeZoneList } from "actions/userUpdate";
import { updatePassword } from "actions/userUpdate";
import { toast } from "../../../node_modules/react-toastify";



export class EditProfile extends Component {
  isEditUser = true;
  constructor(props) {
    super(props);
    this.isEditUser = true;
    this.state = {

      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      company: '',
      city: '',
      country: '',
      password: '',
      cPassword: '',
      timezone: '',
      // roles: [],
      // invite: false,
      fNameErr: !this.isEditUser,
      lNameErr: !this.isEditUser,
      emailErr: !this.isEditUser,
      passwordErr: !this.isEditUser,
      cPasswordErr: !this.isEditUser,
    }
    if (this.isEditUser) {
      this.props.dispatch(getUserDetails(getUserId()))
    }

    this.passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/
  }

  componentWillReceiveProps(props) {
    console.log('user details', props);
    if (props.userUpdate.userDetails && props.userUpdate.timezoneLists && props.userUpdate.countryLists) {
      const userDetails = props.userUpdate.userDetails.length ? props.userUpdate.userDetails[0]: props.userUpdate.userDetails;
      this.userData = userDetails;
      const timeZn = userDetails.profiles && userDetails.profiles.findIndex(e => e.type==='Admin')
      this.setState({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        company: userDetails.company,
        companyRole: userDetails.companyRole,
        city: userDetails.city,
        country: userDetails.country,
        countryList: props.userUpdate.countryLists,
        // timezone: userDetails.timezone,
        timezone: userDetails.profiles && userDetails.profiles[timeZn].location && userDetails.profiles[timeZn].location.timezone,
        timezoneList: props.userUpdate.timezoneLists,
        loading: false,
      }, () => {
        console.log('state', this.state)
      })
    } else {
      this.setState({ loading: true })
    }
  }

  componentDidMount() {
    this.props.dispatch(loadCountryList());
    this.props.dispatch(loadTimeZoneList());
  }

  gotoDashboardPage() {
    this.props.history.push('/admin/dashboard')
  }

  validate(validators, value) {
    let err;
    for (let i in validators) {
      const validator = validators[i];
      switch (validator.type) {
        case 'required':
          err = value ? '' : validator.msg;
          break;
        case 'pattern':
          err = new RegExp(validator.pattern).test(value) ? '' : validator.msg;
          break;
        case 'maxlength':
          err = value.length <= validator.maxLength ? '' : validator.msg;
          break;
        default:
          err = validator.validate(value) ? '' : validator.msg;
      }
      if (err) {
        return err
      }
    }
    return err;
  }

  submitData() {
    let state = Object.assign({}, this.state);
    // const userDetail = this.props.userUpdate.userDetails[0].profiles[0].location;
    let pIndex = this.props.userUpdate.userDetails.profiles.findIndex(e => e.type==='Admin');
    if(pIndex === -1) {
      pIndex = 0;
      this.userData.profiles.push({type: 'Admin'})
    }
    this.userData.profiles[pIndex].location = {
          city: this.state.city,
          country: this.state.country,
          timezone: this.state.timezone
        } 
    this.props.dispatch(updateUserData({
      ...this.userData, ...{
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        company: this.state.company,
        companyRole: this.state.companyRole,
        city: this.state.city,
        country: this.state.country,
        timezone: this.state.timezone,
        // location: {
        //   city: this.state.city,
        //   country: this.state.country,
        //   timeZone: this.state.timezone
        // },
        // profile: this.state.location
      }
    }, (response) => {
      toast.success('User updated successfully')
      console.log('callback called ', response, this.state, state)
      // this.props.history.goBack();

      if (state.password && !state.passwordErr) {
        setTimeout(() => {
          this.props.dispatch(updatePassword({
            password: this.state.Npass,
            oldpassword: this.state.password,
            email: this.state.email
          }, () => {
            toast.success('Password changed successfully')
            this.setState({ Npass: '', NpassErr: false })
          }))
        }, 0)

      }
    }, true));


  }

  render() {
    const { classes } = this.props;
    const { isFetching, isUpdating, isUpdatingPassword } = this.props.userUpdate;
    const {
      firstName, lastName, email, company, companyRole, city,
      country, timezone, password,
      NpassErr, fnameErr, lnameErr, emailErr, Cpass,
      passwordErr, CpassErr, cityRoleErr, cityErr, companyRoleErr, companyErr

    } = this.state;
    return (

      <div>
        {(isFetching || this.state.loading || isUpdatingPassword) ?
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  {!isUpdatingPassword ? 'Loading...' : 'Updating Password..'} </CardHeader>
              </Card>
            </GridItem>
          </GridContainer>
          : null}
        {(!isFetching && !this.state.loading && !isUpdatingPassword) &&
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  Edit Profile </CardHeader>

                <CardBody>
                  <GridContainer justify="flex-end">
                    <GridItem xs={12} sm={12} md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <div className={classes.headings}>PROFILE DETAILS</div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Email
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <CustomInput error={emailErr} helperText={emailErr} inputProps={{ defaultValue: email, disabled: this.isEditUser, type: "text", onChange: (event) => this.onFormChange('email', event) }} formControlProps={{ fullWidth: true }} />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        First Name
                         </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        id="firstName"
                        error={fnameErr}
                        helperText={fnameErr}
                        inputProps={{
                          defaultValue: firstName,
                          type: "text",
                          onChange: (event) => this.onFormChange('firstName', event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Last Name
                         </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        id="lastName"
                        error={lnameErr}
                        helperText={lnameErr}
                        inputProps={{
                          defaultValue: lastName,
                          type: "text",
                          onChange: (event) => this.onFormChange('lastName', event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Company Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        id="company"
                        error={companyErr}
                        helperText={companyErr}
                        inputProps={{
                          defaultValue: company,
                          type: "text",
                          onChange: (event) => this.onFormChange('company', event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}

                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Company Role
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        id="companyRole"
                        error={companyRoleErr}
                        helperText={companyRoleErr}
                        inputProps={{
                          defaultValue: companyRole,
                          type: "text",
                          onChange: (event) => this.onFormChange('companyRole', event)
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}

                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Country
                      </FormLabel>
                    </GridItem>
                    <GridItem className={classes.selectPadding} xs={12} sm={12} md={3}>

                      <Select onChange={(event) => this.onFormChange('country', event)}
                        value={this.state.country} MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }} className={classes.dropdown}>
                        <MenuItem disabled selected={!this.state.country} classes={{ root: classes.selectMenuItem }}>Country</MenuItem>
                        {
                          this.state.countryList && this.state.countryList.map(e => <MenuItem value={e.name} classes={{ root: classes.selectMenuItem }}>{e.name}</MenuItem>)
                        }
                      </Select>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        City
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        id="city"
                        error={cityErr}
                        helperText={cityRoleErr}
                        inputProps={{
                          defaultValue: city,
                          type: "text",
                          onChange: (event) => this.onFormChange('city', event)
                        }}

                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Time Zone
                      </FormLabel>
                    </GridItem>
                    <GridItem className={classes.selectPadding} xs={12} sm={12} md={3}>

                      <Select onChange={(event) => this.onFormChange('timezone', event)}
                        value={this.state.timezone} MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }} className={classes.dropdown}>
                        <MenuItem disabled selected={!this.state.timezone} classes={{ root: classes.selectMenuItem }}>Time Zone</MenuItem>
                        {
                          this.state.timezoneList && this.state.timezoneList.map(e => <MenuItem value={e.value} classes={{ root: classes.selectMenuItem }}>{e.text}</MenuItem>)
                        }
                      </Select>


                      {/* <Select>
                    <CustomInput
                      labelText="Time Zone"
                      id="timeZone"
                      inputProps={{
                        defaultValue: timeZone,
                        type: "text",
                        onChange: (event) => this.onFormChange('timeZone', event)
                      }}
                      formControlProps={{
                        fullWidth: true

                      }}
                    /></Select> */}
                    </GridItem>

                  </GridContainer>
                  <Divider className={classes.divider} light />
                  <GridContainer justify="flex-end">
                    <GridItem xs={12} sm={12} md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <div className={classes.headings}>CHANGE PASSWORD</div>
                    </GridItem>
                  </GridContainer>
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Old Password
                      </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          id="old-password"
                          error={passwordErr}
                          helperText={passwordErr}
                          inputProps={{
                            type: "password",
                            onChange: (event) => this.onFormChange('password', event)
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          New Password
                      </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          id="password"
                          error={NpassErr}
                          helperText={NpassErr}
                          inputProps={{
                            type: "password",
                            onChange: (event) => this.onFormChange('Npass', event)
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Confirm Password222
                      </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          id="confirm-password"
                          error={CpassErr}
                          helperText={CpassErr}
                          inputProps={{
                            type: "password",
                            onChange: (event) => this.onFormChange('Cpass', event)
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>


                  </div>




                  <Divider className={classes.divider} light />



                  <Clearfix />
                </CardBody>
                <CardFooter className={classes.btnStyle}>
                  <Button color='transparent' round size="sm" onClick={() => this.gotoDashboardPage()}>Cancel</Button>
                  <Button color='info'
                    round
                    size="sm"
                    onClick={() => this.submitData()}>Update
                        </Button>
                </CardFooter>
              </Card>
            </GridItem>

          </GridContainer>
        }
      </div>
    );
  }

  onFormChange(type, event) {
    event.persist();
    const result = {};
    let validators;
    switch (type) {
      case 'email':
        validators = [
          { type: 'required', msg: 'Email is required' },
          { type: 'pattern', pattern: this.emailPattern, msg: 'Email is not valid' }
        ]
        result['email'] = event.target.value;
        result['emailErr'] = this.validate(validators, event.target.value)
        break;

      case 'firstName':
        validators = [
          { type: 'required', msg: 'First Name is required' },
        ]
        result['firstName'] = event.target.value;
        result['fNameErr'] = this.validate(validators, event.target.value)
        break;

      case 'lastName':
        validators = [
          { type: 'required', msg: 'Last Name is required' },
        ]
        result['lastName'] = event.target.value;
        result['lNameErr'] = this.validate(validators, event.target.value)
        break;

      case 'company':
        validators = [{
          type: 'required', msg: 'Company Name is required'
        }]
        result['company'] = event.target.value;
        result['companyErr'] = this.validate(validators, event.target.value)
        break;

      case 'companyRole':
        validators = [{ type: 'required', msg: 'Company Name is required' }]
        result['companyRole'] = event.target.value;
        result['companyRoleErr'] = this.validate(validators, event.target.value)
        break;

      case 'country':
        validators = [{ type: 'required', msg: 'country is required' }]
        result['country'] = event.target.value;
        result['countryErr'] = this.validate(validators, event.target.value)
        break;
      case 'city':
        validators = [{ type: 'required', msg: 'city is required' }]
        result['city'] = event.target.value;
        result['cityErr'] = this.validate(validators, event.target.value)
        break;
      case 'timezone':
        validators = [{ type: 'required', msg: 'timeZone is required' }]
        result['timezone'] = event.target.value;
        result['timezoneErr'] = this.validate(validators, event.target.value)
        break;
      case 'password':
        validators = [{ type: 'required', msg: 'Current password is required' }]
        result['password'] = event.target.value;
        result['passwpordErr'] = this.validate(validators, event.target.value)
        break;
      case 'Npass':
        validators = [
          { type: 'required', msg: 'New Password is required' },
          { type: 'pattern', pattern: this.passPattern, msg: 'Password must contain atleast 8 characters including 1 Uppercase, 1 Lowercase, 1 Number and 1 special symbol' }
        ]
        result['Npass'] = event.target.value;
        result['NpassErr'] = this.validate(validators, event.target.value)
        break;
      case 'Cpass':
        validators = [
          { type: 'required', msg: 'Confirm Password is required' },
          { type: 'passMatch', msg: "Passwords don't match", validate: (value) => this.state.Npass === event.target.value }
        ]
        result['Cpass'] = event.target.value;
        result['CpassErr'] = this.validate(validators, event.target.value)
        break;

    }
    this.setState({ ...result, untouched: false });
  }

}


const mapStateToProps = state => state;
export default connect(mapStateToProps)(withStyles(styles)(EditProfile));
