import {
  GET_PROJECT_LIST_START,
  GET_PROJECT_LIST_SUCCESS,
  SAVE_PROJECT_START,
  SAVE_PROJECT_SUCCESS,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_SUCCESS,
  SAVE_SPRINT_START,
  SAVE_SPRINT_SUCCESS,
  SAVE_USER_STORIES_START,
  SAVE_USER_STORIES_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  CLEAR_SUCCESS,
  LOGIN_ERROR,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  UPDATE_USERS_CREDITS_SUCCESS,
  UPDATE_USERS_CREDITS_ERROR,
  ADD_DISCOUNT_MANAGEMENT_SUCCESS,
  ADD_DISCOUNT_MANAGEMENT_ERROR,
  DELETE_DISCOUNT_MANAGEMENT_SUCCESS,
  DELETE_DISCOUNT_MANAGEMENT_ERROR,
  UPDATE_USER_STORY_SUCCESS,
  UPDATE_USER_STORY_ERROR,
  MOVE_USER_STORY_TO_ANOTHER_SPRINT_SUCCESS,
  MOVE_USER_STORY_TO_ANOTHER_SPRINT_ERROR,
  UPDATE_SPRINT_SUCCESS,
  UPDATE_SPRINT_ERROR,
  APPLY_VOUCHER_SUCCESS,
  APPLY_VOUCHER_ERROR,
  DELETE_USER_STORY_SUCCESS,
  DELETE_USER_STORY_ERROR,
  SAVE_CREATE_USER_SUCCESS,
  CREATE_USER_SAVE_ERROR,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
} from "actions/constant";

export default (state = null, action) => {
  switch (action.type) {
    // Any new Error actions needs to be added here
    case GET_PROJECT_LIST_SUCCESS:
    case SAVE_PROJECT_SUCCESS:
    case UPDATE_PROJECT_SUCCESS:
    case SAVE_SPRINT_SUCCESS:
    case SAVE_USER_STORIES_SUCCESS:
    case LOGOUT_SUCCESS:
    case DELETE_USER_SUCCESS:
    case UPDATE_USERS_CREDITS_SUCCESS:
    case ADD_DISCOUNT_MANAGEMENT_SUCCESS:
    case DELETE_DISCOUNT_MANAGEMENT_SUCCESS:
    case UPDATE_USER_STORY_SUCCESS:
    case MOVE_USER_STORY_TO_ANOTHER_SPRINT_SUCCESS:
    case UPDATE_SPRINT_SUCCESS:
    case APPLY_VOUCHER_SUCCESS:
    case DELETE_USER_STORY_SUCCESS:
    case SAVE_CREATE_USER_SUCCESS:
    case PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        successMsg: action.successMsg
      });
    // Any new Fetch or clear error actions needs to be added here
    case CLEAR_SUCCESS:
    case GET_PROJECT_LIST_START:
    case SAVE_PROJECT_START:
    case UPDATE_PROJECT_START:
    case SAVE_SPRINT_START:
    case SAVE_USER_STORIES_START:
    case LOGIN_SUCCESS:
    case LOGIN_ERROR:
    case DELETE_USER_ERROR:
    case UPDATE_USERS_CREDITS_ERROR:
    case ADD_DISCOUNT_MANAGEMENT_ERROR:
    case DELETE_DISCOUNT_MANAGEMENT_ERROR:
    case UPDATE_USER_STORY_ERROR:
    case MOVE_USER_STORY_TO_ANOTHER_SPRINT_ERROR:
    case UPDATE_SPRINT_ERROR:
    case DELETE_USER_STORY_ERROR:
    case CREATE_USER_SAVE_ERROR:
    case PAYMENT_ERROR:
      return null;
    default:
      return state;
  }
};
