/**
 * This file contains all of the action constants.
 */

export const GET_PROJECT_LIST_START = 'GET_PROJECT_LIST_START';
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS';
export const GET_PROJECT_LIST_ERROR = 'GET_PROJECT_LIST_ERROR';
export const SAVE_PROJECT_START = 'SAVE_PROJECT_START';
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS';
export const SAVE_PROJECT_ERROR = 'SAVE_PROJECT_ERROR';
export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';
export const GET_PROJECT_DETAILS_START = 'GET_PROJECT_DETAILS_START';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const GET_PROJECT_DETAILS_ERROR = 'GET_PROJECT_DETAILS_ERROR';
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA';

export const GET_OPEN_CONTRACT_LIST_SUCCESS = "GET_OPEN_CONTRACT_LIST_SUCCESS";
export const GET_PAYMENT_CONTRACT_LIST_SUCCESS = "GET_PAYMENT_CONTRACT_LIST_SUCCESS";
export const GET_CONTRACT_LIST_START = 'GET_CONTRACT_LIST_START';
export const GET_CONTRACT_LIST_SUCCESS = 'GET_CONTRACT_LIST_SUCCESS';
export const GET_CONTRACT_LIST_ERROR = 'GET_CONTRACT_LIST_ERROR';
export const SAVE_CONTRACT_START = 'SAVE_CONTRACT_START';
export const SAVE_CONTRACT_SUCCESS = 'SAVE_CONTRACT_SUCCESS';
export const SAVE_CONTRACT_ERROR = 'SAVE_CONTRACT_ERROR';
export const UPDATE_CONTRACT_START = 'UPDATE_CONTRACT_START';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_ERROR = 'UPDATE_CONTRACT_ERROR';
export const GET_CONTRACT_DETAILS_START = 'GET_CONTRACT_DETAILS_START';
export const GET_CONTRACT_DETAILS_SUCCESS = 'GET_CONTRACT_DETAILS_SUCCESS';
export const GET_CONTRACT_DETAILS_ERROR = 'GET_CONTRACT_DETAILS_ERROR';
export const SET_CONTRACT_DATA = 'SET_CONTRACT_DATA';


export const SAVE_SPRINT_START = 'SAVE_SPRINT_START';
export const SAVE_SPRINT_SUCCESS = 'SAVE_SPRINT_SUCCESS';
export const SAVE_SPRINT_ERROR = 'SAVE_SPRINT_ERROR';
export const GET_ALL_SPRINT_LIST_START = 'GET_ALL_SPRINT_LIST_START';
export const GET_ALL_SPRINT_LIST_SUCCESS = 'GET_ALL_SPRINT_LIST_SUCCESS';
export const GET_ALL_SPRINT_LIST_ERROR = 'GET_ALL_SPRINT_LIST_ERROR';
export const UPDATE_SPRINT_START = 'UPDATE_SPRINT_START';
export const UPDATE_SPRINT_SUCCESS = 'UPDATE_SPRINT_SUCCESS';
export const UPDATE_SPRINT_ERROR = 'UPDATE_SPRINT_ERROR';
export const GET_SPRINT_DETAILS_START = 'GET_SPRINT_DETAILS_START';
export const GET_SPRINT_DETAILS_SUCCESS = 'GET_SPRINT_DETAILS_SUCCESS';
export const GET_SPRINT_DETAILS_ERROR = 'GET_SPRINT_DETAILS_ERROR';
export const SET_SPRINT_DATA = 'SET_SPRINT_DATA';

export const SAVE_USER_STORIES_START = 'SAVE_USER_STORIES_START';
export const SAVE_USER_STORIES_SUCCESS = 'SAVE_USER_STORIES_SUCCESS';
export const SAVE_USER_STORIES_ERROR = 'SAVE_USER_STORIES_ERROR';
export const ADD_SUCCESS_CRITERIA = 'ADD_SUCCESS_CRITERIA';
export const REMOVE_SUCCESS_CRITERIA = 'REMOVE_SUCCESS_CRITERIA';
export const CLEAR_SUCCESS_CRITERIA = 'CLEAR_SUCCESS_CRITERIA';
export const GET_USER_STORIES_START = 'GET_USER_STORIES_START';
export const GET_USER_STORIES_SUCCESS = 'GET_USER_STORIES_SUCCESS';
export const GET_USER_STORIES_ERROR = 'GET_USER_STORIES_ERROR';
export const SET_USER_STORIES_DATA = 'SET_USER_STORIES_DATA';

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA';
export const CLEAR_CONTRACT_DATA = 'CLEAR_CONTRACT_DATA';
export const CLEAR_SPRINT_DATA = 'CLEAR_SPRINT_DATA';
export const CLEAR_USER_STORIES_DATA = 'CLEAR_USER_STORIES_DATA';

export const UPDATE_USERS_CREDITS_START = 'UPDATE_USERS_CREDITS_START';
export const UPDATE_USERS_CREDITS_SUCCESS = 'UPDATE_USERS_CREDITS_SUCCESS';
export const UPDATE_USERS_CREDITS_ERROR = 'UPDATE_USERS_CREDITS_ERROR';

export const UPDATE_USERS_ROLE_START = 'UPDATE_USERS_ROLE_START';
export const UPDATE_USERS_ROLE_SUCCESS = 'UPDATE_USERS_ROLE_SUCCESS';
export const UPDATE_USERS_ROLE_ERROR = 'UPDATE_USERS_ROLE_ERROR';

export const LOAD_COUNTRY_LIST_START = 'LOAD_COUNTRY_LIST_START';
export const LOAD_COUNTRY_LIST_SUCCESS = 'LOAD_COUNTRY_LIST_SUCCESS';
export const LOAD_COUNTRY_LIST_ERROR = 'LOAD_COUNTRY_LIST_ERROR';

export const LOAD_TIMEZONE_LIST_START = 'LOAD_TIMEZONE_LIST_START';
export const LOAD_TIMEZONE_LIST_SUCCESS = 'LOAD_TIMEZONE_LIST_SUCCESS';
export const LOAD_TIMEZONE_LIST_ERROR = 'LOAD_TIMEZONE_LIST_ERROR';

export const GET_USERS_START = 'GET_USERS_START'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'

export const CLEAR_ORGANIZATION_ERRORS = 'CLEAR_ORGANIZATION_ERRORS'

export const GET_ORGANIZATIONS_START = 'GET_ORGANIZATIONS_START'
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS'
export const GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR'

export const SET_ACTIVE_ORG = 'SET_ACTIVE_ORG'

export const GET_USER_DETAILS_START = 'GET_USER_DETAILS_START';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';

export const RESETPASSWORD_START = 'RESETPASSWORD_START';
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS';
export const RESETPASSWORD_ERROR = 'RESETPASSWORD_ERROR';

export const FORGOTPASSWORD_START = 'FORGOTPASSWORD_START';
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS';
export const FORGOTPASSWORD_ERROR = 'FORGOTPASSWORD_ERROR';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const SAVE_CREATE_USER_SUCCESS = 'SAVE_CREATE_USER_SUCCESS';
export const SAVE_CREATE_USER_START = 'SAVE_CREATE_USER_START';
export const CREATE_USER_SAVE_ERROR = 'CREATE_USER_SAVE_ERROR';

export const SAVE_CREATE_ORGANIZATION_SUCCESS = 'SAVE_CREATE_ORGANIZATION_SUCCESS';
export const SAVE_CREATE_ORGANIZATION_START = 'SAVE_CREATE_ORGANIZATION_START';
export const CREATE_ORGANIZATION_SAVE_ERROR = 'CREATE_ORGANIZATION_SAVE_ERROR';

export const GET_DISCOUNT_MANAGEMENT_START = 'GET_DISCOUNT_MANAGEMENT_START';
export const GET_DISCOUNT_MANAGEMENT_SUCCESS = 'GET_DISCOUNT_MANAGEMENT_SUCCESS';
export const GET_DISCOUNT_MANAGEMENT_ERROR = 'GET_DISCOUNT_MANAGEMENT_ERROR';

export const ADD_DISCOUNT_MANAGEMENT_START = 'ADD_DISCOUNT_MANAGEMENT_START';
export const ADD_DISCOUNT_MANAGEMENT_SUCCESS = 'ADD_DISCOUNT_MANAGEMENT_SUCCESS';
export const ADD_DISCOUNT_MANAGEMENT_ERROR = 'ADD_DISCOUNT_MANAGEMENT_ERROR';

export const UPDATE_DISCOUNT_MANAGEMENT_START = 'UPDATE_DISCOUNT_MANAGEMENT_START';
export const UPDATE_DISCOUNT_MANAGEMENT_SUCCESS = 'UPDATE_DISCOUNT_MANAGEMENT_SUCCESS';
export const UPDATE_DISCOUNT_MANAGEMENT_ERROR = 'UPDATE_DISCOUNT_MANAGEMENT_ERROR';

export const DELETE_DISCOUNT_MANAGEMENT_START = 'DELETE_DISCOUNT_MANAGEMENT_START';
export const DELETE_DISCOUNT_MANAGEMENT_SUCCESS = 'DELETE_DISCOUNT_MANAGEMENT_SUCCESS';
export const DELETE_DISCOUNT_MANAGEMENT_ERROR = 'DELETE_DISCOUNT_MANAGEMENT_ERROR';

export const MOVE_USER_STORY_TO_ANOTHER_SPRINT_START = 'MOVE_USER_STORY_TO_ANOTHER_SPRINT_START';
export const MOVE_USER_STORY_TO_ANOTHER_SPRINT_SUCCESS = 'MOVE_USER_STORY_TO_ANOTHER_SPRINT_SUCCESS';
export const MOVE_USER_STORY_TO_ANOTHER_SPRINT_ERROR = 'MOVE_USER_STORY_TO_ANOTHER_SPRINT_ERROR';

export const UPDATE_USER_STORY_START = 'UPDATE_USER_STORY_START';
export const UPDATE_USER_STORY_SUCCESS = 'UPDATE_USER_STORY_SUCCESS';
export const UPDATE_USER_STORY_ERROR = 'UPDATE_USER_STORY_ERROR';

export const GET_ONE_SPRINT_LIST_START = 'GET_ONE_SPRINT_LIST_START';
export const GET_ONE_SPRINT_LIST_SUCCESS = 'GET_ONE_SPRINT_LIST_SUCCESS';
export const GET_ONE_SPRINT_LIST_ERROR = 'GET_ONE_SPRINT_LIST_ERROR';

export const GET_CREDIT_START = 'GET_CREDIT_START';
export const GET_CREDIT_SUCCESS = 'GET_CREDIT_SUCCESS';
export const GET_CREDIT_ERROR = 'GET_CREDIT_ERROR';

export const GET_CREDIT_HISTORY_START = 'GET_CREDIT_HISTORY_START';
export const GET_CREDIT_HISTORY_SUCCESS = 'GET_CREDIT_HISTORY_SUCCESS';
export const GET_CREDIT_HISTORY_ERROR = 'GET_CREDIT_HISTORY_ERROR';

export const GET_CREDIT_BY_PROJECT_START = 'GET_CREDIT_BY_PROJECT_START';
export const GET_CREDIT_BY_PROJECT_SUCCESS = 'GET_CREDIT_BY_PROJECT_SUCCESS';
export const GET_CREDIT_BY_PROJECT_ERROR = 'GET_CREDIT_BY_PROJECT_ERROR';

export const GET_PACKAGE_START = 'GET_PACKAGE_START';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_ERROR = 'GET_PACKAGE_ERROR';

export const APPLY_VOUCHER_START = 'APPLY_VOUCHER_START';
export const APPLY_VOUCHER_SUCCESS = 'APPLY_VOUCHER_SUCCESS';
export const APPLY_VOUCHER_ERROR = 'APPLY_VOUCHER_ERROR';

export const DELETE_USER_STORY_START = 'DELETE_USER_STORY_START';
export const DELETE_USER_STORY_SUCCESS = 'DELETE_USER_STORY_SUCCESS';
export const DELETE_USER_STORY_ERROR = 'DELETE_USER_STORY_ERROR';

export const PAYMENT_START = 'PAYMENT_START';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';

export const GET_ONE_USER_STORY_START = 'GET_ONE_USER_STORY_START';
export const GET_ONE_USER_STORY_SUCCESS = 'GET_ONE_USER_STORY_SUCCESS';
export const GET_ONE_USER_STORY_ERROR = 'GET_ONE_USER_STORY_ERROR';

export const UPDATE_ONE_USER_STORY_START = 'UPDATE_ONE_USER_STORY_START';
export const UPDATE_ONE_USER_STORY_SUCCESS = 'UPDATE_ONE_USER_STORY_SUCCESS';
export const UPDATE_ONE_USER_STORY_ERROR = 'UPDATE_ONE_USER_STORY_ERROR';

export const MOVE_UNSELECTED_STORIES_ANOTHER_SPRINT_ERROR = 'MOVE_UNSELECTED_STORIES_ANOTHER_SPRINT_ERROR';
export const NOT_ENOUGH_CREDITS_ERROR = 'NOT_ENOUGH_CREDITS_ERROR';

export const RESERVE_CREDITS_START = 'RESERVE_CREDITS_START';
export const RESERVE_CREDITS_SUCCESS = 'RESERVE_CREDITS_SUCCESS';
export const RESERVE_CREDITS_ERROR = 'RESERVE_CREDITS_ERROR';

export const DEDUCT_CREDITS_START = 'DEDUCT_CREDITS_START';
export const DEDUCT_CREDITS_SUCCESS = 'DEDUCT_CREDITS_SUCCESS';
export const DEDUCT_CREDITS_ERROR = 'DEDUCT_CREDITS_ERROR';

export const VALIDATION_TOKEN_START = 'VALIDATION_TOKEN_START';
export const VALIDATION_TOKEN_SUCCESS = 'VALIDATION_TOKEN_SUCCESS';
export const VALIDATION_TOKEN_ERROR = 'VALIDATION_TOKEN_ERROR';

export const UPDATE_USERS_DATA_START = 'UPDATE_USERS_DATA_START';
export const UPDATE_USERS_DATA_ERROR = 'UPDATE_USERS_DATA_ERROR';
export const UPDATE_USERS_DATA_SUCCESS = 'UPDATE_USERS_DATA_SUCCESS'

export const UPDATE_ORGANIZATIONS_DATA_START = 'UPDATE_ORGANIZATIONS_DATA_START';
export const UPDATE_ORGANIZATIONS_DATA_ERROR = 'UPDATE_ORGANIZATIONS_DATA_ERROR';
export const UPDATE_ORGANIZATIONS_DATA_SUCCESS = 'UPDATE_ORGANIZATIONS_DATA_SUCCESS'


export const GET_DOER_START = 'GET_DOER_START';
export const GET_DOER_ERROR = 'GET_DOER_ERROR';
export const GET_DOER_SUCCESS = 'GET_DOER_SUCCESS'

export const GET_DOER_DETAILS_START = 'GET_DOER_DETAILS_START';
export const GET_DOER_DETAILS_SUCCESS = 'GET_DOER_DETAILS_SUCCESS';
export const GET_DOER_DETAILS_ERROR = 'GET_DOER_DETAILS_ERROR';

export const GET_USER_CONTRACTS_START = 'GET_USER_CONTRACTS_START';
export const GET_USER_CONTRACTS_SUCCESS = 'GET_USER_CONTRACTS_SUCCESS';
export const GET_USER_CONTRACTS_ERROR = 'GET_USER_CONTRACTS_ERROR';

export const UPDATE_DOER_START = 'UPDATE_DOER_START';
export const UPDATE_DOER_SUCCESS = 'UPDATE_DOER_SUCCESS';
export const UPDATE_DOER_ERROR = 'UPDATE_DOER_ERROR';

export const UPDATE_DOER_STATUS_START = 'UPDATE_DOER_START';
export const UPDATE_DOER_STATUS_SUCCESS = 'UPDATE_DOER_SUCCESS';
export const UPDATE_DOER_STATUS_ERROR = 'UPDATE_DOER_ERROR';


export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';


export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_CUSTOMERS_START = 'GET_CUSTOMERS_START';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';

export const GET_FILTERED_TABLE_DATA_START = 'GET_FILTERED_TABLE_DATA_START';
export const GET_FILTERED_TABLE_DATA_SUCCESS = 'GET_FILTERED_TABLE_DATA_SUCCESS';
export const GET_FILTERED_TABLE_DATA_ERROR = 'GET_FILTERED_TABLE_DATA_ERROR';
export const GET_CONTRACTS_START = 'GET_CONTRACTS_START';
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_ERROR = 'GET_CONTRACTS_ERROR';

export const GET_STORIES_START = 'GET_STORIES_START';
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORIES_ERROR = 'GET_STORIES_ERROR';

export const GET_ONE_PROJECT_SUCCESS = 'GET_ONE_PROJECT_SUCCESS';


export const PUT_CONTRACT_STATUS_START = 'PUT_CONTRACT_STATUS_START';
export const PUT_CONTRACT_STATUS_SUCCESS = 'PUT_CONTRACT_STATUS_SUCCESS';
export const PUT_CONTRACT_STATUS_ERROR = 'PUT_CONTRACT_STATUS_ERROR';

export const GET_ASSIGNEE_LIST_START = 'GET_ASSIGNEE_LIST_START';
export const GET_ASSIGNEE_LIST_SUCCESS = 'GET_ASSIGNEE_LIST_SUCCESS';
export const GET_ASSIGNEE_LIST_ERROR = 'GET_ASSIGNEE_LIST_ERROR';

export const GET_BID_DETAIL_START = 'GET_BID_DETAIL_START';
export const GET_BID_DETAIL_SUCCESS = 'GET_BID_DETAIL_SUCCESS';
export const GET_BID_DETAIL_ERROR = 'GET_BID_DETAIL_ERROR';

export const GET_COMMENT_START = 'GET_COMMENT_START';
export const GET_COMMENT_SUCCESS = 'GET_COMMENT_SUCCESS';
export const GET_COMMENT_ERROR = 'GET_COMMENT_ERROR';

export const POST_COMMENT_START = 'POST_COMMENT_START';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_ERROR = 'POST_COMMENT_ERROR';

export const PUT_COMMENT_START = 'PUT_COMMENT_START';
export const PUT_COMMENT_SUCCESS = 'PUT_COMMENT_SUCCESS';
export const PUT_COMMENT_ERROR = 'PUT_COMMENT_ERROR';

export const DELETE_COMMENT_START = 'DELETE_COMMENT_START';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';

export const PUT_AWARDED_BID_START = 'PUT_AWARDED_BID_START';
export const PUT_AWARDED_BID_SUCCESS = 'PUT_AWARDED_BID_SUCCESS';
export const PUT_AWARDED_BID_ERROR = 'PUT_AWARDED_BID_ERROR';

export const GET_CONTRACT_STATUS_START = 'GET_CONTRACT_STATUS_START';
export const GET_CONTRACT_STATUS_SUCCESS = 'GET_CONTRACT_STATUS_SUCCESS';
export const GET_CONTRACT_STATUS_ERROR = 'GET_CONTRACT_STATUS_ERROR';

export const POST_CREATE_CONTRACT_START = 'POST_CREATE_CONTRACT_START';
export const POST_CREATE_CONTRACT_SUCCESS = 'POST_CREATE_CONTRACT_SUCCESS';
export const POST_CREATE_CONTRACT_ERROR = 'POST_CREATE_CONTRACT_ERROR';

export const UPDATE_STORY_STATUS_START = 'UPDATE_STORY_STATUS_START';
export const UPDATE_STORY_STATUS_SUCCESS = 'UPDATE_STORY_STATUS_SUCCESS';
export const UPDATE_STORY_STATUS_ERROR = 'UPDATE_STORY_STATUS_ERROR';

export const GET_ALL_CONTRACTS_START = 'GET_ALL_CONTRACTS_START';
export const GET_ALL_CONTRACTS_SUCCESS = 'GET_ALL_CONTRACTS_SUCCESS';
export const GET_ALL_CONTRACTS_ERROR = 'GET_ALL_CONTRACTS_ERROR';

export const GET_MARGINS_START = 'GET_MARGINS_START';
export const GET_MARGINS_SUCCESS = 'GET_MARGINS_SUCCESS';
export const GET_MARGINS_ERROR = 'GET_MARGINS_ERROR';

export const UPDATE_MARGIN_START = 'UPDATE_MARGIN_START';
export const UPDATE_MARGIN_SUCCESS = 'UPDATE_MARGIN_SUCCESS';
export const UPDATE_MARGIN_ERROR = 'UPDATE_MARGIN_ERROR';

export const GET_MARGINS_FOR_CONTRACT_START = 'GET_MARGINS_FOR_CONTRACT_START';
export const GET_MARGINS_FOR_CONTRACT_SUCCESS = 'GET_MARGINS_FOR_CONTRACT_SUCCESS';
export const GET_MARGINS_FOR_CONTRACT_ERROR = 'GET_MARGINS_FOR_CONTRACT_ERROR';

export const UPDATE_MARGINS_FOR_CONTRACT_START = 'GET_MARGINS_UPDATE_FOR_CONTRACT_START';
export const UPDATE_MARGINS_FOR_CONTRACT_SUCCESS = 'GET_MARGINS_UPDATE_FOR_CONTRACT_SUCCESS';
export const UPDATE_MARGINS_FOR_CONTRACT_ERROR = 'GET_MARGINS_UPDATE_FOR_CONTRACT_ERROR';


export const GET_ORGS_USERS_START = 'GET_ORGS_USERS_START';
export const GET_ORGS_USERS_SUCCESS = 'GET_ORGS_USERS_SUCCESS';
export const GET_ORGS_USERS_ERROR = 'GET_ORGS_USERS_ERROR';


export const CREATE_ORGS_USERS_START = 'CREATE_ORGS_USERS_START';
export const CREATE_ORGS_USERS_SUCCESS = 'CREATE_ORGS_USERS_SUCCESS';
export const CREATE_ORGS_USERS_ERROR = 'CREATE_ORGS_USERS_ERROR';

export const GET_ORGANIZATIONS_LIST_START = 'GET_ORGANIZATIONS_LIST_START';
export const GET_ORGANIZATIONS_LIST_SUCCESS = 'GET_ORGANIZATIONS_LIST_SUCCESS';
export const GET_ORGANIZATIONS_LIST_ERROR = 'GET_ORGANIZATIONS_LIST_ERROR';

export const GET_PROJECT_LIST_FOR_ORGANIZATION_START = 'GET_PROJECT_LIST_FOR_ORGANIZATION_START';
export const GET_PROJECT_LIST_FOR_ORGANIZATION_SUCCESS = 'GET_PROJECT_LIST_FOR_ORGANIZATION_SUCCESS';
export const GET_PROJECT_LIST_FOR_ORGANIZATION_ERROR = 'GET_PROJECT_LIST_FOR_ORGANIZATION_ERROR';

export const GET_ORGANIZATION_START = 'GET_ORGANIZATION_START';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';