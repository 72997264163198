import {
    cardTitle,
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    hexToRgb
  } from "assets/jss/material-dashboard-pro-react.js";


  const UserStoriesStyle ={
    filterBtn: {
        float: 'right'
    },
    addBtn: {
        float: 'right',
        color: 'black',
        textAlign: "right !important",
    },
    display:{
        display:'flex',
        alignItems: 'center'
    },
    cardTitle:{
        //  paddingTop: '3px',
        // fontSize: '20px',
        color:'white',
         flex: '1'
     },
     inpLength: {
        // width: '100px'
     },
     btnStyle: {
         marginTop: '15px'
     },
     distance: {
         marginTop: '60px'
     },
     title: {
        alignItems: 'center',
        display: 'flex',
        // paddingTop: '3px !important'
     },
     selectItem: {
        maxWidth: '135px',
        fontSize: '14px'
     },
     idLinkStyle: {
         color: '#9c27b0',
         '&:hover':{
            cursor: 'pointer !important',
            textDecoration: 'underline !important' 
         }
     },
     tableHearders: {
        color: infoColor[0]
    },
    minimumheight: {
        minHeight: '100%'
    }
}
export default UserStoriesStyle;
