
import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from "react-router-dom";
//import { DropDownList } from '@progress/kendo-react-dropdowns';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import cx from "classnames";
import userDropDownStyle from './UserDropDown'
import withStyles from "@material-ui/core/styles/withStyles";
import avatar from "assets/img/faces/avatar.jpg";
import { getUserData } from 'utils/common';
import { connect } from 'react-redux';




class UserDropDown extends React.Component {


    source = ["My profile", "Edit Profile", "Setting"];
    constructor(props) {
        super(props);
        this.state = { events: [], openAvatar: this.props.isOpen };
    }

    componentWillReceiveProps(props) {
        if(props.isOpen !== this.props.isOpen){
            this.setState({openAvatar : props.isOpen})
        }
    }

    onOpen = () => { console.log('open'); }
    onClose = () => { console.log('close'); }
    onFocus = () => { console.log('focus'); }
    onBlur = () => { console.log('blur'); }
    onChange = (event) => { console.log('change', event.target.value); }
    onFilterChange = (event) => { console.log('filterChange', event.filter.value); }

    logout() {
        console.log('jhgjgjh')
    }





    render() {

        const { classes } = this.props;


        const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: ''
      });
      const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: ''
      });

      let userData = getUserData();
      if(this.props.userUpdate && this.props.userUpdate.userDetails && this.props.userUpdate.userDetails[0]) {
          userData = this.props.userUpdate.userDetails[0];
      }
        return (
            <div className={classes.customClass}>
                {/*<div className={photo}>*/}
                {/*    <img src={avatar} className={classes.avatarImg} alt="..." />*/}
                {/*</div>*/}
                <div
                    to={"#"}
                    className={classes.itemLink + " " + classes.userCollapseButton}
                    // onClick={() => this.openCollapse("openAvatar")}
                >
                    <ListItemText
                        primary={[userData.firstName, userData.lastName].join(' ')}
                        secondary={
                            <b
                                className={
                                    caret +
                                    " " +
                                    classes.userCaret +
                                    " " +
                                    (this.state.openAvatar ? classes.caretActive : "")
                                }
                            />
                        }
                        disableTypography={true}
                        className={classes.itemText + " " + classes.userItemText}
                    />
                </div>




                {/* <div>
                <DropDownList
                    data={this.source}
                    onOpen={this.onOpen}
                    onClose={this.onClose}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFilterChange={this.onFilterChange}
                    filterable={true}
                />
                <br />
                <br />
                 <EventsLogger events={this.state.events} /> 
            </div> */}



            </div>
        );
    }






    log(event, arg) {
        const events = this.state.events;
        events.unshift(`${event} ${arg || ""}`);
    
        this.setState({
            events: events
        });
    }







}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(withStyles(userDropDownStyle)(UserDropDown));