import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardFooter from "components/Card/CardFooter.js";
import PropTypes from 'prop-types';

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

import image from "assets/img/bg7.jpg";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { connect } from "react-redux";
// import NavigationBar from "components/inc/NavigationBar.jsx";
import { login } from "actions/login";

import styles from "assets/jss/customStyle.jsx";
import Footer from "components/Footer/Footer.js";
import { isLoggedIn, getUserData } from "utils/common";
import Clearfix from "components/Clearfix/Clearfix";
import { Link } from "@material-ui/core";
import { toast } from "react-toastify";
import { ROLES } from "common/constants";
// import LoadingComponent from "../../components/inc/LoadingComponent";
import LoadingOverlay from 'react-loading-overlay';


export class LoginPage extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      cardHidden: {
        cardAnimaton: ''
      },
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      isLoggedIn: false,
      erMessage: "",
      isLoading: false
    }
  }

  goToForgotPassword(){
    this.props.history.push('/auth/forgot-password');
  }

  handleSubmit() {
    this.setState({
      isLoading: true
    }, async () => {
      if (!this.state.emailError && !this.state.passwordError && this.state.email && this.state.password) {
        const data = {
          'username': this.state.email,
          'password': this.state.password,
          'grant_type': 'password',
        }
        const response = await this.props.login(data);
        if (!(response && response.user)) {
          this.setState({ isLoading: false })
        } 
        else if (response && response.user && response.user.deleted) {
          this.setState({ erMessage: "User doesn't exist" })
        } 
        else {
          if (response && response.user && response.accessToken) {
            localStorage.setItem("accessToken", response.accessToken);
            localStorage.setItem("userData", JSON.stringify(response.user));
            localStorage.setItem('expireToken', response.accessTokenExpiresAt);
            this.setState({ isLoading: false })
            if (response.user.firstTimeUser) {
              this.props.history.push('/profile-page');
            } 
            else {
              if (response.user.userRoles && response.user.userRoles.length && response.user.userRoles.includes(ROLES[0])) {
                this.props.history.push('/admin/dashboard');
              } 
              else {
                toast.error("User is not an Admin")
              }
            }
          }
        }
      } else {
        if(this.state.email === '' && this.state.password === '')
        {
          toast.error("Email is required")
        }
        else if(this.state.password === '')
        {
          toast.error("Password is required")
        }
        else if(this.state.email === '')
        {
          toast.error("Email ID is required")
        }
      }
    })
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  handleUserValidation = (event, type = 'email') => {
    if (type === 'password') {
      if (!event.target.value) {
        this.setState({ passwordError: true });
      } else {
        this.setState({ passwordError: false });
      }
    } else {
      if (!event.target.value) {
        this.setState({ emailError: true });
      } else {
        const isValidEmail = event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (isValidEmail) {
          this.setState({ emailError: false });
        } else {
          this.setState({ emailError: true });
        }
      }
    }
  };

  onClose = () => {
    this.setState({ erMessage: "" })
  }
  
  render() {
  const {cardAnimaton} = this.state.cardHidden;
  const { classes } = this.props;
  return (
    <div className={classes.container}>
    {this.state.erMessage ?
          <div>
            <SnackbarContent
              message={
                <span>
                  <b>ERROR:</b> {this.state.erMessage}
                </span>
              }
              customNotificationClear={this.onClose}
              color="danger"
              close={true}
              icon="info_outline"
            />
            <Clearfix />
          </div> : null
        }
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
               <CustomInput
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "Email...",
                    type: "email",
                    name: "email",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    onChange: (event) => this.handleUserInput(event),
                    onBlur: (event) => this.handleUserValidation(event)
                  }}
                  value={this.state.email}
                  error={this.state.emailError ? true : false}
                />
                <CustomInput
                  id="pass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "Password",
                    type: "password",
                    name: "password",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className={classes.inputIconsColor}>
                          lock_utline
                        </Icon>
                      </InputAdornment>
                    ),
                    onChange: (event) => this.handleUserInput(event),
                    onBlur: (event) => this.handleUserValidation(event, 'password')
                  }}
                  value={this.state.password}
                  error={this.state.passwordError ? true : false}
                />
              </CardBody>
              
              <CardFooter className={classes.justifyContentCenter}>
              <LoadingOverlay
                            active={this.state.isLoading}
                            spinner
                            text='Loading...'
                        >
                <Button simple color="success" size="lg" onClick={() => this.handleSubmit()}>
                  SIGN IN
                </Button>
                </LoadingOverlay>
              </CardFooter>
            </Card>
            <div className={classes.forgotWrap}>
            <Link className={classes.forgot} onClick={()=>this.goToForgotPassword()}>FORGOT PASSWORD</Link>
            </div>
          </form>
        </GridItem>
      </GridContainer>
     
    </div>
  );
}
}

const mapStateToProps = state => ({
  userData: state.login.auth
});

const mapDispatchToProps = dispatch => ({
  login: (data) => dispatch(login(data)),
});

LoginPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(LoginPage));