import axios from 'axios';
import { BASE_URL } from "../common/constants";
import { getToken } from '../utils/common';

export async function getContractListAPI(query='') {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts${query}`;
  const response = await axios.get(url, config);
  return response.data;
}

export const getContractNoOfBidAPI = async (id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `https://virtserver.swaggerhub.com/dshamim/archimydes-api/1.0.0/team/${id}`;
  const response = await axios.get(url, config);
  if (response) {
    return response.data;
  }
}

export const getContractById = async (id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}contracts/${id}`;
  const response = await axios.get(url, config);
  if (response) {
    // return response.data;
    let contract = response.data && response.data.data
    const assigned = (response.data && response.data.data && response.data.data.assigned) || []

    const res = []
    for (const i in assigned) {
      const user = await getUserById(assigned[i].userId) || []
      const data = {
        ...assigned[i],
        userName: user && user.data && user.data.firstName
      }
      res.push(data)
    }
    contract = {
      ...contract,
      assigned : res
    }
    return contract
  }
}

export const getUserById = async (id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}oauth/users/${id}`;
  const response = await axios.get(url, config);
  if (response) {
    return response.data;
  }
}


export const getContractByStoryId = async (id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}stories/${id}`;
  const response = await axios.get(url, config);
  if (response) {
    return response.data;
  }
}

export const getContractBid = async (id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}contracts/${id}/bid`;
  const response = await axios.get(url, config);
  if (response) {
    // return response.data;
    const content = response.data.data
    const res =[]
    for (const i in content) {
      const user = await getUserById(content[i].userId) || []
      const data = {
        ...content[i],
        userFirstName: user && user.data && user.data.firstName,
        userLastName: user && user.data && user.data.lastName
      }
      res.push(data)
    }
    return res
  }
}


export async function updateContract(data, contractId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/${contractId}`;
  const response = await axios.put(url,data, config);
  return response.data;
}

export const getContractComments = async (id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}comments/entity/${id}`;
  const response = await axios.get(url, config);
  if (response) {
    return response.data;
  }
}

export const postContractComments = async (id, data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}comments/entity/${id}`;
  const response = await axios.post(url, data, config);
  if (response) {
    return response;
  }
}

export const postStory = async (projectId, sprintId, contract) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}projects/${projectId}/sprints/${sprintId}/stories`
  const response = await axios.put(url, contract, config)
  if (response) {
    return response.data;
  }
}


export async function updateContractStatusAPI(contractId, data) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/${contractId}`;
  const response = await axios.put(url,data, config);
  return response.data;
}


export async function getAssigneeListAPI(contractId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/${contractId}/bid?detailed=true`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function getBidDetails(contractId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/${contractId}/bid`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function getCommentAPI(contractId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}comments/entity/${contractId}`;
  const response = await axios.get(url, config);
  return response.data;
}

export const postCommentAPI = async (id, data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}comments/entity/${id}`;
  const response = await axios.post(url, data, config);
  if (response) {
    return response;
  }
}

export async function putCommentAPI(commentId, data) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}comments/${commentId}`;
  const response = await axios.put(url,data, config);
  return response.data;
}

export async function deleteCommentAPI(commentId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}comments/${commentId}`;
  const response = await axios.delete(url, config);
  return response.data;
}

export async function putAwardedBidAPI(bid,id) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/${id}/bid/${bid.id}`;
  const response = await axios.put(url, bid, config);
  return response.data;
}

export async function getContractStatusAPI() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}contracts/status`;
  const response = await axios.get(url, config);
  return response.data;
}

export const postCreateContractAPI = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }

  let url = `${BASE_URL}contracts`;
  const response = await axios.post(url, data, config);
  if (response) {
    return response;
  }
}

export async function updateStoryAPI(storyId, sprintId, projectId, data) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}projects/${projectId}/sprints/${sprintId}/stories/${storyId}`;
  const response = await axios.put(url,data, config);
  return response.data;
}


export async function getAllContractsAPI(storyId) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken(),
    },
  }
  let url = `${BASE_URL}stories/${storyId}/contracts`;
  const response = await axios.get(url, config);
  return response.data;
}