import {
  SAVE_USER_STORIES_START,
  SAVE_USER_STORIES_SUCCESS,
  SAVE_USER_STORIES_ERROR,
  ADD_SUCCESS_CRITERIA,
  REMOVE_SUCCESS_CRITERIA,
  CLEAR_SUCCESS_CRITERIA,
  CLEAR_USER_STORIES_DATA,
  GET_USER_STORIES_START,
  GET_USER_STORIES_SUCCESS,
  GET_USER_STORIES_ERROR,
  SET_USER_STORIES_DATA,
  UPDATE_USER_STORY_START,
  UPDATE_USER_STORY_SUCCESS,
  UPDATE_USER_STORY_ERROR,
  MOVE_USER_STORY_TO_ANOTHER_SPRINT_START,
  MOVE_USER_STORY_TO_ANOTHER_SPRINT_SUCCESS,
  MOVE_USER_STORY_TO_ANOTHER_SPRINT_ERROR,
  DELETE_USER_STORY_START,
  DELETE_USER_STORY_SUCCESS,
  DELETE_USER_STORY_ERROR,
  GET_ONE_USER_STORY_START,
  GET_ONE_USER_STORY_SUCCESS,
  GET_ONE_USER_STORY_ERROR,
  MOVE_UNSELECTED_STORIES_ANOTHER_SPRINT_ERROR,
  NOT_ENOUGH_CREDITS_ERROR,
  GET_CUSTOMERS_START,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_FILTERED_TABLE_DATA_ERROR,
  GET_FILTERED_TABLE_DATA_START,
  GET_FILTERED_TABLE_DATA_SUCCESS,
  GET_CONTRACTS_START,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_ERROR,
  GET_STORIES_START,
  GET_STORIES_SUCCESS,
  GET_STORIES_ERROR,
  UPDATE_ONE_USER_STORY_SUCCESS,
  UPDATE_ONE_USER_STORY_ERROR,
  UPDATE_ONE_USER_STORY_START
} from "./constant";
import { saveUserStoriesAPI, getUserStoriesAPI, moveUserStoriesAPI,
  updateUserStoriesAPI,  getUserStoryByIdAPI, getContractsAPI,
  getStoryAPI, deleteUserStoriesAPI, getOneUserStoriesAPI,
  getCustomersAPI, getUsersAPI, getFilteredTableDataAPI, getContractsByKeyAPI} from "../api/userStoriesApi";
import {
  USER_STORIES_SAVE_SUCCESS_MSG,
  UPDATE_USER_STORIES_SAVE_SUCCESS_MSG,
  USER_STORY_MOVE_MSG,
  DELETE_USER_STORY_MSG,
  UNSELECTED_STORIES_SUBMISSION_ERROR_MSG,
  NOT_ENOUGH_CREDITS_ERROR_MSG,
} from "../common/constants";
import { updateStoryAPI } from "api/contractsApi";
import { toast } from "react-toastify";


const saveUserStoriesStart = () => ({
  type: SAVE_USER_STORIES_START,
});

const saveUserStoriesSuccess = json => ({
  type: SAVE_USER_STORIES_SUCCESS,
  saveResponse: json.data,
  successMsg: USER_STORIES_SAVE_SUCCESS_MSG
});

const saveUserStoriesError = error => ({
    type: SAVE_USER_STORIES_ERROR,
    errorMsg: error.response && error.response.data && error.response.data.errorMessage
});

export const saveUserStories = (data, projectId, sprintId) => {
  return async (dispatch) => {
    dispatch(saveUserStoriesStart());
    try {
      const response = await saveUserStoriesAPI(data, projectId, sprintId);
      dispatch(saveUserStoriesSuccess(response));
      return response;
    } catch (error) {
      dispatch(saveUserStoriesError(error));
      return error;
    }
  }
};

export const addSuccessCriteria = (json) => ({
  type: ADD_SUCCESS_CRITERIA,
  json,
});

export const removeSuccessCriteria = (val) => ({
  type: REMOVE_SUCCESS_CRITERIA,
  val,
});

export const clearSuccessCriteria = () => ({
  type: CLEAR_SUCCESS_CRITERIA,
});

export const clearUserStoriesData = () => ({
  type: CLEAR_USER_STORIES_DATA,
});

const getUserStoriesStart = () => ({
  type: GET_USER_STORIES_START,
});

const getUserStoriesSucces = json => ({
  type: GET_USER_STORIES_SUCCESS,
  userStoriesListResponse: json.data,
});

const getUserStoriesError = error => ({
  type: GET_USER_STORIES_ERROR,
  errorMsg: error.message
});

export const getUserStories = (projectId, sprintId) => {
  return async (dispatch) => {
    dispatch(getUserStoriesStart());
    try {
      const response = await getUserStoriesAPI(projectId, sprintId);
      dispatch(getUserStoriesSucces(response));
      return response;
    } catch (error) {
      dispatch(getUserStoriesError(error));
      return error;
    }
  }
};

const moveUserStoryToAnotherSprintStart = () => ({
  type: MOVE_USER_STORY_TO_ANOTHER_SPRINT_START,
});

const moveUserStoryToAnotherSprintSucces = json => ({
  type: MOVE_USER_STORY_TO_ANOTHER_SPRINT_SUCCESS,
  moveUserStoriesListResponse: json.data,
  successMsg: USER_STORY_MOVE_MSG
});

const moveUserStoryToAnotherSprintError = error => ({
  type: MOVE_USER_STORY_TO_ANOTHER_SPRINT_ERROR,
  errorMsg: error.message
});

export const moveUserStoryToAnotherSprint = (projectId, sprintId, storyId, newSprintId) => {
  return async (dispatch) => {
    dispatch(moveUserStoryToAnotherSprintStart());
    try {
      const response = await moveUserStoriesAPI(projectId, sprintId, storyId, newSprintId);
      dispatch(moveUserStoryToAnotherSprintSucces(response));
      return response;
    } catch (error) {
      dispatch(moveUserStoryToAnotherSprintError(error));
      return error;
    }
  }
};

const updateUserStoriesStart = () => ({
  type: UPDATE_USER_STORY_START,
});

const updateUserStoriesSuccess = json => ({
  type: UPDATE_USER_STORY_SUCCESS,
  updateResponse: json.data,
  successMsg: UPDATE_USER_STORIES_SAVE_SUCCESS_MSG
});

const updateUserStoriesError = error => ({
    type: UPDATE_USER_STORY_ERROR,
    errorMsg: error.response && error.response.data &&  error.response.data.errorMessage
});

// data, projectId, sprintId, storyId

// data = [{
//   
// }]

export const updateUserStories = (data, projectId, sprintId, callback) => {
  return async (dispatch) => {
    dispatch(updateUserStoriesStart());
    try {
      const response = await updateUserStoriesAPI(data, projectId, sprintId);
      callback && callback(response)
      dispatch(updateUserStoriesSuccess(response));
      return response;
    } catch (error) {
      dispatch(updateUserStoriesError(error));
      return error;
    }
  }
};

const updateOneUserStoryStart = () => ({ type: UPDATE_ONE_USER_STORY_START})
const updateOneUserStorySuccess = payload => ({ type: UPDATE_ONE_USER_STORY_SUCCESS, story: payload.data})
const updateOneUserStoryError = () => ({ type: UPDATE_ONE_USER_STORY_ERROR})

export const updateOneUserStory = (story, updatedStory, callback) => {
  return async (dispatch) => {
    dispatch(updateOneUserStoryStart());
    try {
      const response = await updateStoryAPI(story._id, story.sprint, story.project, updatedStory);
      dispatch(updateOneUserStorySuccess(response.data));
      callback && callback(response);
      return response;
    } catch (error) {
      toast.error(error.response && error.response.data &&  error.response.data.errorMessage);
      dispatch(updateOneUserStoryError());
      return error;
    }
  }
};

export const setUserStoriesData = (data) => ({
  type: SET_USER_STORIES_DATA,
  userStoriesList: data,
});


const deleteUserStoryStart = () => ({
  type: DELETE_USER_STORY_START,
});

const deleteUserStorySuccess = () => ({
  type: DELETE_USER_STORY_SUCCESS,
  successMsg: DELETE_USER_STORY_MSG
});

const deleteUserStoryError = (error) => ({
  type: DELETE_USER_STORY_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.message
});

export const deleteUserStory = (projectId, sprintId, storyId) => {
  return async (dispatch) => {
    dispatch(deleteUserStoryStart());
    try {
      const response = await deleteUserStoriesAPI(projectId, sprintId, storyId);
      dispatch(deleteUserStorySuccess(response));
      return response;
    } catch (error) {
      dispatch(deleteUserStoryError(error));
      return error;
    }
  }
};

const getOneUserStoriesStart = () => ({
  type: GET_ONE_USER_STORY_START,
});

const getOneUserStorySuccess = payload => ({
  type: GET_ONE_USER_STORY_SUCCESS,
  story: payload.data,
});

const getOneUserStoriesError = error => ({
  type: GET_ONE_USER_STORY_ERROR,
  errorMsg: error.message
});

export const getOneUserStories = (projectId, sprintId, storyId) => {
  return async (dispatch) => {
    dispatch(getOneUserStoriesStart());
    try {
      const response = await getOneUserStoriesAPI(projectId, sprintId, storyId);
      dispatch(getOneUserStorySuccess(response));
      return response;
    } catch (error) {
      dispatch(getOneUserStoriesError(error));
      return error;
    }
  }
};
export const showErrorToMoveStoryAnotherSprint = () => ({
  type: MOVE_UNSELECTED_STORIES_ANOTHER_SPRINT_ERROR,
  errorMsg: UNSELECTED_STORIES_SUBMISSION_ERROR_MSG
});

export const showErrorForNotEnoughCredits = () => ({
  type: NOT_ENOUGH_CREDITS_ERROR,
  errorMsg: NOT_ENOUGH_CREDITS_ERROR_MSG
});




const getCustomersStart = () => ({
  type: GET_CUSTOMERS_START,
});

const getCustomersSuccess = json => ({
  type: GET_CUSTOMERS_SUCCESS,
  customerListResponse: json.data,
});

const getCustomersError = error => ({
  type: GET_CUSTOMERS_ERROR,
  errorMsg: error.message
});

export const getCustomers = () => {
  return async (dispatch) => {
    dispatch(getCustomersStart());
    try {
      const response = await getCustomersAPI();
      dispatch(getCustomersSuccess(response));
      return response;
    } catch (error) {
      dispatch(getCustomersError(error));
    }
  }
}
export const getUserStory = (storyId, callBack) => {
  return async (dispatch) => {
    dispatch(getOneUserStoriesStart());
    try {
      const response = await getUserStoryByIdAPI(storyId);
      callBack && callBack(response.data)
      dispatch(getOneUserStorySuccess(response));
      return response;
    } catch (error) {
      dispatch(getOneUserStoriesError(error));
      return error;
    }
  }
};


const getUsersStart = () => ({
  type: GET_USER_START,
});

const getUsersSuccess = json => ({
  type: GET_USER_SUCCESS,
  userListResponse: json.data,
});

const getUsersError = error => ({
  type: GET_USER_ERROR,
  errorMsg: error.message
});

export const getUsers = (userID) => {
  return async (dispatch) => {
    dispatch(getUsersStart());
    try {
      const response = await getUsersAPI(userID);
      dispatch(getUsersSuccess(response));
      return response;
    } catch (error) {
      dispatch(getUsersError(error));
    }
  }
}

const getContractsStart = () => ({
  type: GET_CONTRACTS_START,
});

const getContractsSucces = json => ({
  type: GET_CONTRACTS_SUCCESS,
  getContractResponse: json.data,
});

const getContractsError = error => ({
  type: GET_CONTRACTS_ERROR,
  errorMsg: error.message
});

export const getContracts = (contractId, callBack) => {
  return async (dispatch) => {
    dispatch(getContractsStart());
    try {
      const response = await getContractsAPI(contractId);
      callBack && callBack(response.data)
      dispatch(getContractsSucces(response));
      return response;
    } catch (error) {
      dispatch(getContractsError(error));
      return error;
    }
  }
};

export const getContractsByKey = (contractId, callBack) => {
  return async (dispatch) => {
    dispatch(getContractsStart());
    try {
      const response = await getContractsByKeyAPI(contractId);
      callBack && callBack(response.data)
      dispatch(getContractsSucces(response));
      return response;
    } catch (error) {
      dispatch(getContractsError(error));
      return error;
    }
  }
};


const getFilteredTableDataStart = () => ({
  type: GET_FILTERED_TABLE_DATA_START,
});

const getFilteredTableDataSuccess = json => ({
  type: GET_FILTERED_TABLE_DATA_SUCCESS,
  filteredTableDataResponse: json.data,
});

const getFilteredTableDataError = error => ({
  type: GET_FILTERED_TABLE_DATA_ERROR,
  errorMsg: error.message
});

export const getFilteredTableData = (requestor, sprint, project, status) => {
  return async (dispatch) => {
    dispatch(getFilteredTableDataStart());
    try {
      const response = await getFilteredTableDataAPI(requestor, sprint, project, status);
      dispatch(getFilteredTableDataSuccess(response));
      return response;
    } catch (error) {
      dispatch(getFilteredTableDataError(error));
    }
  }
}
const getStoriesStart = () => ({
  type: GET_STORIES_START,
});

const getStoriesSuccess = json => ({
  type: GET_STORIES_SUCCESS,
  response: json.data,
});

const getStoriesError = error => ({
  type: GET_STORIES_ERROR,
  errorMsg: error.message
});

export const getStoryList = (storyId, callBack) => {
  return async (dispatch) => {
    dispatch(getStoriesStart());
    try {
      const response = await getStoryAPI(storyId);
      callBack && callBack(response.data)
      dispatch(getStoriesSuccess(response));
      return response;
    } catch (error) {
      dispatch(getStoriesError(error));
      return error;
    }
  }
};