import {
  UPDATE_USERS_CREDITS_START,
  UPDATE_USERS_CREDITS_SUCCESS,
  UPDATE_USERS_CREDITS_ERROR,
  UPDATE_USERS_DATA_ERROR,
  UPDATE_USERS_DATA_START,
  UPDATE_USERS_DATA_SUCCESS,
  UPDATE_USERS_ROLE_START,
  UPDATE_USERS_ROLE_SUCCESS,
  UPDATE_USERS_ROLE_ERROR,
  DEDUCT_CREDITS_START,
  DEDUCT_CREDITS_SUCCESS,
  DEDUCT_CREDITS_ERROR,
  GET_USERS_ERROR,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USER_DETAILS_START,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  GET_DOER_START,
  GET_DOER_SUCCESS,
  GET_DOER_ERROR,
  GET_DOER_DETAILS_START,
  GET_DOER_DETAILS_SUCCESS,
  GET_DOER_DETAILS_ERROR,
  GET_USER_CONTRACTS_START,
  GET_USER_CONTRACTS_SUCCESS,
  GET_USER_CONTRACTS_ERROR,
  UPDATE_DOER_START,
  UPDATE_DOER_SUCCESS,
  UPDATE_DOER_ERROR,
  UPDATE_DOER_STATUS_START,
  UPDATE_DOER_STATUS_SUCCESS,
  UPDATE_DOER_STATUS_ERROR,
  LOAD_COUNTRY_LIST_START,
  LOAD_COUNTRY_LIST_SUCCESS,
  LOAD_COUNTRY_LIST_ERROR,
  LOAD_TIMEZONE_LIST_START,
  LOAD_TIMEZONE_LIST_SUCCESS,
  LOAD_TIMEZONE_LIST_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR
} from "./constant";
import {updateUsers, UsersCredits, UsersCreditsDeduct, getUsers, getUsersInfoAPI, userProfileUpdateAPI, getDoerAPI, getContracts, updateDoer, loadTimeZoneListAPI, loadCountryListAPI, updatePasswordAPI, updateDoerStatusAPI} from "../api/usersApi";
import {USER_UPDATE_SUCCESS_MSG} from "../common/constants";


const updateUserCreditStart = () => ({
  type: UPDATE_USERS_CREDITS_START,
});
const updateUserCreditSuccess = (data) => ({
  type: UPDATE_USERS_CREDITS_SUCCESS,
  successMsg: USER_UPDATE_SUCCESS_MSG,
  payload: data
});
const updateUserCreditError = (error) => ({
  type: UPDATE_USERS_CREDITS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateUserCredit = (data,userId,callBack) => {
  return async (dispatch) => {
    dispatch(updateUserCreditStart());
    try {
      const response = await UsersCredits(data,userId);
      dispatch(updateUserCreditSuccess(response));
      callBack && callBack();
      return response;
    } catch (error) {
      dispatch(updateUserCreditError(error));
      return error;
    }
  }
};



const getDoerStart = () => ({
  type: GET_DOER_START,
});
const getDoerSuccess = (data) => ({
  type:  GET_DOER_SUCCESS,
  payload: data
});
const getDoerError = (error) => ({
  type: GET_DOER_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getDoerList = () => {
  return async (dispatch) => {
    dispatch(getDoerStart());
    try {
      const response = await getDoerAPI();
      dispatch(getDoerSuccess(response));
      return response;
    } catch (error) {
      dispatch(getDoerError(error));
      return error;
    }
  }
};



const getUserStart = () => ({
  type: GET_USERS_START,
});
const getUserSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  payload: data
});
const getUserError = (error) => ({
  type: GET_USERS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getUserList = (callBack) => {
  return async (dispatch) => {
    dispatch(getUserStart());
    try {
      const response = await getUsers();
      callBack && callBack(response.data)
      dispatch(getUserSuccess(response));
      return response;
    } catch (error) {
      dispatch(getUserError(error));
      return error;
    }
  }
};


const updateUserRoleStart = () => ({
  type: UPDATE_USERS_ROLE_START,
});
const updateUserRoleSuccess = (data) => ({
  type: UPDATE_USERS_ROLE_SUCCESS,
  successMsg: USER_UPDATE_SUCCESS_MSG,
  payload: data
});
const updateUserRoleError = (error) => ({
  type: UPDATE_USERS_ROLE_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateUserRole = (data,userId, callback) => {
  return async (dispatch) => {
    dispatch(updateUserRoleStart());
    try {
      const response = await updateUsers(data,userId);
      dispatch(updateUserRoleSuccess(response));
      callback && callback();
      return response;
    } catch (error) {
      dispatch(updateUserRoleError(error));
      return error;
    }
  }
};

const deductCreditStart = () => ({
  type: DEDUCT_CREDITS_START,
});
const deductCreditSuccess = (data) => ({
  type: DEDUCT_CREDITS_SUCCESS,
  payload: data
});
const deductCreditError = (error) => ({
  type: DEDUCT_CREDITS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const deductCredit = (data,userId) => {
  return async (dispatch) => {
    dispatch(deductCreditStart());
    try {
      const response = await UsersCreditsDeduct(data,userId);
      dispatch(deductCreditSuccess(response));
      return response;
    } catch (error) {
      dispatch(deductCreditError(error));
      return error;
    }
  }
};

const getUserDetailsStart = () => ({
  type: GET_USER_DETAILS_START,
});
const getUserDetailsSuccess = (data) => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: data
});
const getUserDetailsError = (error) => ({
  type: GET_USER_DETAILS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getUserDetails = (userId) => {
  return async (dispatch) => {
    dispatch(getUserDetailsStart());
    try {
      // const response = await getUsersInfoAPI('?ids=' + userId);
      const response = await getUsersInfoAPI('/'+userId);
      dispatch(getUserDetailsSuccess(response));
      return response;
    } catch (error) {
      dispatch(getUserDetailsError(error));
      return error;
    }
  }
} 

const getDoerDetailsStart = () => ({
  type: GET_DOER_DETAILS_START,
});
const getDoerDetailsSuccess = (data) => ({
  type: GET_DOER_DETAILS_SUCCESS,
  payload: data
});
const getDoerDetailsError = (error) => ({
  type: GET_DOER_DETAILS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getDoerDetails = (doerId, callback) => {
  return async (dispatch) => {
    dispatch(getDoerDetailsStart());
    try {
      const response = await getDoerAPI(doerId);
      response && callback && callback(response)
      dispatch(getDoerDetailsSuccess(response));
      return response;
    } catch (error) {
      dispatch(getDoerDetailsError(error));
      return error;
    }
  }
} 





const updateUsersDataStart = () => ({
  type: UPDATE_USERS_DATA_START,
});
const updateUsersDataSuccess = (data) => ({
  type: UPDATE_USERS_DATA_SUCCESS,
  successMsg: USER_UPDATE_SUCCESS_MSG,
  payload: data
});
const updateUsersDataError = (error) => ({
  type: UPDATE_USERS_DATA_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateUserData = (data, callBack, isUpdatingLoginUser=false) => {
  return async (dispatch) => {
    dispatch(updateUsersDataStart());
    try {
      const response = await userProfileUpdateAPI(data);
      if(isUpdatingLoginUser) {
        const updatedUserDetails = {success: true, data:[response.data]};
        localStorage.setItem("userData", JSON.stringify(response.data));
        dispatch(getUserDetailsSuccess(updatedUserDetails));
      } 
      dispatch(updateUsersDataSuccess(response));
      
      response && callBack && callBack(response);
      return response;
    } catch (error) {
      dispatch(updateUsersDataError(error));
      return error;
    }
  }
};


const loadCountryListStart = () => ({
  type: LOAD_COUNTRY_LIST_START,
});
const loadCountryListSuccess = (data) => ({
  type: LOAD_COUNTRY_LIST_SUCCESS,
  payload: data
});
const loadCountryListError = (error) => ({
  type: LOAD_COUNTRY_LIST_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const loadCountryList = () => {
  return async (dispatch) => {
    dispatch(loadCountryListStart());
    try {
      const response = await loadCountryListAPI();
      dispatch(loadCountryListSuccess(response));
      return response;
    } catch (error) {
      dispatch(loadCountryListError(error));
      return error;
    }
  }
};


const loadTimeZoneListStart = () => ({
  type: LOAD_TIMEZONE_LIST_START,
});
const loadTimeZoneListSuccess = (data) => ({
  type: LOAD_TIMEZONE_LIST_SUCCESS,
  // successMsg: LOAD_TIMEZONE_LIST_SUCCESS_MSG,
  payload: data
});
const loadTimeZoneListError = (error) => ({
  type: LOAD_TIMEZONE_LIST_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const loadTimeZoneList = () => {
  return async (dispatch) => {
    dispatch(loadTimeZoneListStart());
    try {
      const response = await loadTimeZoneListAPI();
      dispatch(loadTimeZoneListSuccess(response));
      return response;
    } catch (error) {
      dispatch(loadTimeZoneListError(error));
      return error;
    }
  }
};


const updateDoerStart = () => ({
  type: UPDATE_DOER_START,
});
const updateDoerSuccess = (data) => ({
  type: UPDATE_DOER_SUCCESS,
  payload: data
});
const updateDoerError = (error) => ({
  type: UPDATE_DOER_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateDoerData = (data,userId, callBack) => {
  return async (dispatch) => {
    dispatch(updateDoerStart());
    try {
      const response = await updateDoer(data, userId);
      response && callBack && callBack(response);
      dispatch(updateDoerSuccess(response));
      return response;
    } catch (error) {
      dispatch(updateDoerError(error));
      return error;
    }
  }
};

export const updateDoerStatus = (data, userId, callBack) => {
  return async (dispatch) => {
    dispatch(updateDoerStart());
    try {
      const response = await updateDoerStatusAPI(data, userId);
      response && callBack && callBack(response);
      dispatch(updateDoerSuccess(response));
      return response;
    } catch (error) {
      dispatch(updateDoerError(error));
      return error;
    }
  }
};




const getUserContractsStart = () => ({
  type: GET_USER_CONTRACTS_START,
});
const getUserContractsSuccess = (data) => ({
  type: GET_USER_CONTRACTS_SUCCESS,
  payload: data
});
const getUserContractsError = (error) => ({
  type: GET_USER_CONTRACTS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getUserContracts = (userId, callBack) => {
  return async (dispatch) => {
    dispatch(getUserContractsStart());
    try {
      const response = await getContracts(userId);
      dispatch(getUserContractsSuccess(response));
      response && callBack && callBack();
      return response;
    } catch (error) {
      dispatch(getUserContractsError(error));
      return error;
    }
  }
};


const updatePasswordStart = () => ({
  type: UPDATE_PASSWORD,
});
const updatePasswordSuccess = (data) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: data
});
const updatePasswordError = (error) => ({
  type: UPDATE_PASSWORD_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updatePassword = (userId, callBack) => {
  return async (dispatch) => {
    dispatch(updatePasswordStart());
    try {
      const response = await updatePasswordAPI(userId);
      dispatch(updatePasswordSuccess(response));
      response && callBack && callBack();
      return response;
    } catch (error) {
      console.log('updated paswsword erro =', error);
      dispatch(updatePasswordError(error));
      return error;
    }
  }
};

