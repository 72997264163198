import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
import { minHeight } from "@material-ui/system";
const userDropDownStyle = () => ({
    caret: {
        marginTop: "13px",
        position: "absolute",
        right: "-18px",
        transition: "all 150ms ease-in",
        display: "inline-block",
        width: "0",
        height: "0",
        marginLeft: "2px",
        verticalAlign: "middle",
        borderTop: "4px solid",
        borderRight: "4px solid transparent",
        borderLeft: "4px solid transparent"
      },
      caretActive:{
        transform: "rotate(180deg)"
      },
      itemText: {
        color: "inherit",
        
        margin: "0",
        lineHeight: "30px",
        fontSize: "14px",
        transform: "translate3d(0px, 0, 0)",
        opacity: "1",
        transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
        position: "relative",
        display: "block",
        height: "auto",
        whiteSpace: "nowrap",
        margin: "-13px",
        //padding: "0 16px !important"
      },

        userItemText: {
            lineHeight: "22px"
          },
          photo: {
            transition: "all 300ms linear",
            width: "34px",
            height: "34px",
            //overflow: "hidden",
            //float: "left",
            zIndex: "5",
            //marginRight: "11px",
            borderRadius: "50%",
           // marginLeft: "23px",
            ...boxShadow
          },
          photoRTL: {
            float: "right",
            marginLeft: "12px",
            marginRight: "24px"
          },
          avatarImg: {
            width: "inherit",
            verticalAlign: "middle",
            //border: "0",
            borderRadius: "50%",
            minHeight:"34px",
            minWidth:"34px"
          },
          userCollapseButton: {
            //margin: "0",
            //padding: "6px 15px",
            "&:hover": {
              background: "none"
            }
          },
          customClass:{
            //marginLeft: "110px",
            display: "inline-flex",
            //marginTop: "-7px"
          },
          itemLink: {
            paddingLeft: "10px",
            paddingRight: "10px",
            transition: "all 300ms linear",
            margin: "10px 15px 0",
            borderRadius: "3px",
            position: "relative",
            display: "block",
            padding: "10px 15px",
            backgroundColor: "transparent",
            ...defaultFont,
            width: "auto",
            "&:hover": {
              outline: "none",
              backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
              boxShadow: "none"
            },
            "&,&:hover,&:focus": {
              color: "inherit"
            }
          },
          userCollapseButton: {
            margin: "0",
            padding: "6px 15px",
            "&:hover": {
              background: "none"
            }
          },
          userCollapseLinks: {
            marginTop: "-4px",
            "&:hover,&:focus": {
              color: whiteColor
            }
          },
          userCaret: {
            marginTop: "10px"
          }

})

export default userDropDownStyle;