import React, { Component } from 'react';
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Table/Table";
//import Button from "components/CustomButtons/Button.js";
import Visibility from "@material-ui/icons/Visibility";
import Create from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
//import keyboard_backspace from "@material-ui/icons/keyboard_backspace";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import projectListStyle from "assets/jss/material-dashboard-pro-react/views/projectListStyle.js";
import {
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";
import Card from "components/Card/Card";
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from "components/CustomButtons/Button.js";
//import MenuIcon from '@material-ui/icons/Menu';
import Pagination from "components/Pagination/Pagination.js";
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { getProjectList } from 'actions/project';
import { addBreadCrumb } from 'utils/common';
import { BreadCrumb } from 'utils/common';


 class projectList extends Component {
  constructor(props){
    super(props);
    this.props.dispatch(getProjectList());
    this.state = {
      projectList: undefined
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log('apiresponse', nextProps)
    if(nextProps.project.data) {
      this.setState({projectList: nextProps.project.data})
    }
  }
  gotoSprintPage(project) {
    localStorage.setItem('projectSelected', project.title);
    this.props.history.push('/admin/sprints/' + project._id)
  }
  render() {
    const {classes } = this.props;
    const { projectList } = this.state;
    const isFetching  = this.props.project.isFetching;
    const simpleButtons = (project) => ([
        { icon: Visibility },
        { icon: Create },
        { icon: DeleteIcon }
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={() => key === 1 ? this.gotoSprintPage(project) : ''}
          >
            <prop.icon className={[classes.icon, classes.actionButtonRound, classes.tableActionIcon].join(' ')} />
          </Button>
        );
      })
    )
    let tableData;
    if(projectList) {
      const project = projectList.sort((a,b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      tableData = project.map((e, i) => {
        return {
          index: i + 1,
          title: e.title,
          owner: e.userInfo &&e.userInfo.firstName + ' ' + e.userInfo.lastName,
          email: e.userInfo && e.userInfo.email,
          updatedAt: new Date(e.updatedAt).getTime(),
          action: simpleButtons(e)
        }
      })
    }
    return (
      <div>
        <BreadCrumb pathArray={[
          {name: 'Requestor'},
          {name: 'Projects'}
        ]} />
        <Card>
          <CardHeader color="info" className={classes.cardTitle}>
          Project List</CardHeader>
          <CardBody>
        { tableData && <Table
              tableHead={[
                {
                  Header: "#",
                  accessor: "index",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  className: classes.left,
                  width: 70
                },
                {
                  Header: "Project Title",
                  accessor: "title",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "Owner",
                  accessor: "owner",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  // className: classes.cellPadding,
                },
                {
                  Header: "Email ID",
                  accessor: "email",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "Update",
                  accessor: "updatedAt",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  Cell: (props) => <span>{moment(props.value).format('D MMM YYYY, h:mm A')}</span>
                },
                {
                  Header: "Actions",
                  accessor: "action",
                  sortable: false,
                  filterable: false,
                  className: classes.right,
                  headerClassName: [classes.right, classes.tableHearders].join(' ')
                }
              ]}
              tableData={tableData}
              loading={isFetching}
            />
        }
            </CardBody>
            </Card>
      </div>
    );
  }
}
const mapStateToProps = state => state;
export default connect(mapStateToProps)(withStyles(projectListStyle)(projectList));