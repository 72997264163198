import {
  GET_MARGINS_ERROR,
  GET_MARGINS_START,
  GET_MARGINS_SUCCESS,
  UPDATE_MARGIN_START,
  UPDATE_MARGIN_SUCCESS,
  UPDATE_MARGIN_ERROR,
  GET_MARGINS_FOR_CONTRACT_START,
  GET_MARGINS_FOR_CONTRACT_SUCCESS,
  GET_MARGINS_FOR_CONTRACT_ERROR,
  UPDATE_MARGINS_FOR_CONTRACT_START,
  UPDATE_MARGINS_FOR_CONTRACT_SUCCESS,
  UPDATE_MARGINS_FOR_CONTRACT_ERROR
} from "./constant";
import { getMargins, getContractMargins, updateContractMargins } from "../api/marginsApi";
import { updateMarginAPI } from "api/marginsApi";


const getMarginsStart = () => ({
  type: GET_MARGINS_START,
});
const getMarginsSuccess = (data) => ({
  type: GET_MARGINS_SUCCESS,
  payload: data
});
const getMarginsError = (error) => ({
  type: GET_MARGINS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getMarginsList = () => {
  return async (dispatch) => {
    dispatch(getMarginsStart());
    try {
      const response = await getMargins();
      dispatch(getMarginsSuccess(response));
      return response;
    } catch (error) {
      dispatch(getMarginsError(error));
      return error;
    }
  }
};


const updateMarginStart = () => ({
  type: UPDATE_MARGIN_START,
});
const updateMarginSuccess = (data) => ({
  type: UPDATE_MARGIN_SUCCESS,
  payload: data
});
const updateMarginError = (error) => ({
  type: UPDATE_MARGIN_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateMargin = (marginId, data, callback) => {
  return async (dispatch) => {
    dispatch(updateMarginStart());
    try {
      const response = await updateMarginAPI(marginId, data);
      dispatch(updateMarginSuccess(response));
      callback && callback();
      return response;
    } catch (error) {
      dispatch(updateMarginError(error));
      return error;
    }
  }
};

const getMarginsForContractStart = () => ({
  type: GET_MARGINS_FOR_CONTRACT_START,
});
const getMarginsForContractSuccess = (data) => ({
  type: GET_MARGINS_FOR_CONTRACT_SUCCESS,
  payload: data
});
const getMarginsForContractError = (error) => ({
  type: GET_MARGINS_FOR_CONTRACT_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getMarginsForContract = (contractId, callback) => {
  return async (dispatch) => {
    dispatch(getMarginsForContractStart());
    try {
      const response = await getContractMargins(contractId);
      dispatch(getMarginsForContractSuccess(response));
      callback && callback();
      return response;
    } catch (error) {
      dispatch(getMarginsForContractError(error));
      return error;
    }
  }
};

const updateMarginsForContractStart = () => ({
  type: UPDATE_MARGINS_FOR_CONTRACT_START,
});
const updateMarginsForContractSuccess = (data) => ({
  type: UPDATE_MARGINS_FOR_CONTRACT_SUCCESS,
  payload: data
});
const updateMarginsForContractError = (error) => ({
  type: UPDATE_MARGINS_FOR_CONTRACT_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateMarginForContracts = (contractId, data, callback) => {
  return async (dispatch) => {
    dispatch(updateMarginsForContractStart());
    try {
      console.log(data);
      const response = await updateContractMargins(contractId, data);
      dispatch(updateMarginsForContractSuccess(response));
      callback && callback();
      return response;
    } catch (error) {
      dispatch(updateMarginsForContractError(error));
      return error;
    }
  }
}