import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Table from 'components/Table/Table';

import ContractEditStyle from 'assets/jss/material-dashboard-pro-react/views/ContractEditStyle.js';

import { BreadCrumb } from 'utils/common';
import { toast } from 'react-toastify';
import moment from 'moment';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import SaveBid from './SaveBid';

import { postStory } from '../../api/contractsApi';
import {
  getAssigneeList,
  getContractStatus,
  putAwardedBid,
} from '../../actions/contract';
import Datetime from 'react-datetime';
import { getUserList } from '../../actions/userUpdate';
import { getContractsByKey, getStoryList } from '../../actions/userStories';
import CommentSection from './../../components/CommentSection/CommentSection';
import { getProjectList } from 'actions/project';
import { updateContractStatus } from 'actions/contract';
import EventIcon from '@material-ui/icons/Event';
import FormControl from '@material-ui/core/FormControl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import StoryCard from '../../components/story-card';
import { formatDateUsingUserTimezone } from 'utils/common';
import OverrideCredits from './OverrideCredits';

const cardTypeAPI = 'API Card';
const cardTypeFrontend = 'Frontend Card';
const cardTypeBackend = 'Backend Card';
const cardTypeDesign = 'Design Card';

class EditContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bidStatus: '',
      contract: [],
      doers: [],
      assumption: '',
      assignee: '',
      bidDetails: '',
      loading: true,
      setClassicModal: false,
      classicModal: false,
      criterias: [],
      dueDate: undefined,
      modalState: false,
      assigneeList: [],
      bidList: [],
      status: [],
      title: this.props.match.params.contractKey,
      contractId: '',
      contractKey: this.props.match.params.contractKey,
      activity: 'comment',
      withdraws: {},
      overrideCreditsModal: false
    };
    this.dateInp = React.createRef();
  }

  loadBids(contractData) {
    this.props.dispatch(
      getAssigneeList(contractData && contractData.id, (assigneeData) => {
        const withdraws = {};
        const sortedAssignee = assigneeData.data
          .filter((e) => e.bid)
          .sort(
            (a, b) =>
              new Date(a.bid.updatedAt).getTime() -
              new Date(b.bid.updatedAt).getTime()
          );
        sortedAssignee
          .filter(
            (bidDetail) =>
              bidDetail.bid.bidstatus &&
              bidDetail.bid.bidstatus.toLowerCase() === 'withdrawn'
          )
          .map(
            (bidDetail) => (withdraws[bidDetail['bid']['userId']] = bidDetail)
          );
        const awardedAssignee = sortedAssignee.find(
          (bidDetail) => bidDetail.bid.awarded
        );
        let awardedAssigneeUserId = '';
        if (awardedAssignee) {
          awardedAssigneeUserId = awardedAssignee.bid.userId;
        }
        this.setState({
          bidList: assigneeData.data,
          assigneeList: sortedAssignee,
          withdraws: withdraws,
          assignee: awardedAssigneeUserId,
        });
      })
    );
  }

  componentDidMount() {
    if (
      !this.props.contract ||
      !this.props.contract.contractStatusList ||
      !this.props.contract.contractStatusList.length > 0
    ) {
      this.props.dispatch(
        getContractStatus((status) => {
          this.setState({
            status: status.data,
          });
        })
      );
    }

    let contractData = null;
    if (
      this.props.contract &&
      this.props.contract.openContracts &&
      this.props.contract.openContracts.length > 0
    ) {
      const contracts = this.props.contract.openContracts;
      contractData = contracts.find(
        (contract) =>
          contract.contractKey === this.props.match.params.contractKey
      );
    }

    if (contractData) {
      const story = contractData.stories && contractData.stories[0];
      this.setState({
        contractData: contractData,
        contractId: contractData.id,
        storyData: story || [],
        project: story ? story.project : {},
        criterias: story.criterias || story.criteria,
      });
      this.loadBids(contractData);
    }

    if (!contractData) {
      this.props.dispatch(
        getContractsByKey(this.props.match.params.contractKey, (response) => {
          contractData = response;
          this.loadBids(contractData);
          this.props.dispatch(
            getStoryList(contractData.stories[0].id, (story) => {
              this.props.dispatch(
                getProjectList(story.project, (project) => {
                  this.setState({
                    contractData: contractData,
                    contractId: contractData.id,
                    storyData: story,
                    project: project,
                    criterias: story.criteria,
                  });
                })
              );
            })
          );
        })
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.status.length === 0 &&
      nextProps.contract &&
      nextProps.contract.contractStatusList &&
      nextProps.contract.contractStatusList.length > 0
    ) {
      return { status: nextProps.contract.contractStatusList };
    }
  }

  onAssigneeFetch = () => {
    this.props.dispatch(
      getAssigneeList(
        this.state.contractData && this.state.contractData.id,
        (assigneeData) => {
          const sortedAssignee = assigneeData.data
            .filter((e) => e.bid)
            .sort(
              (a, b) =>
                new Date(a.updatedAt).getTime() -
                new Date(b.updatedAt).getTime()
            );
          setTimeout(() => {
            this.setState({
              bidList: assigneeData.data || [],
              assigneeList: sortedAssignee || [],
              assignee: null,
            });
          }, 2000);
        }
      )
    );
  };

  onStatusChange(event) {
    event.persist();
    this.setState(
      {
        contractData: {
          ...this.state.contractData,
          contractStatus: event.target.value,
        },
      },
      () => {
        this.props.dispatch(
          updateContractStatus(
            this.state.contractData.id,
            this.state.contractData,
            (response) => {
              toast.success('Status Updated');
            }
          )
        );
      }
    );
  }

  modalClickHandler = (data) => {
    if (data) {
      data.callback = () => {
        this.props.dispatch(
          getContractsByKey(
            this.props.match.params.contractKey,
            (contractData) => {
              this.setState({
                contractData: contractData,
                contractId: contractData.id,
              });
            }
          )
        );
        // this.props.dispatch(getDiscountManagement());
      };
    }
    this.setState({ modalState: !this.state.modalState && data });
  };

  overrideCreditsClickHandler = () => {
    this.setState({ overrideCreditsModal: !this.state.overrideCreditsModal  });
  }

  openCal = () => {
    this.dateInp.current.openCalendar();
  };

  setDueDate = (event) => {
    this.setState(
      {
        contractData: {
          ...this.state.contractData,
          contractBidExpiry: moment(event).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        },
      },
      () => {}
    );
  };

  updateDueDate = () => {
    const { dispatch, match } = this.props;
    const { contractData } = this.state;
    dispatch(
      updateContractStatus(this.state.contractData.id, contractData, () => {
        toast.success('Bid due date updated successfully');
      })
    );
  };

  handleSimple = async (e) => {
    let { stories, contract } = this.state;
    contract.contractStatus = e.target.value;
    this.setState({ contract });
    const res = await postStory(stories.project, stories.sprint, contract);
    if (res && res.success) {
      toast.success('Stories Successfully Updated');
    }
  };

  gotoSprintPage() {
    this.props.history.goBack();
  }

  onAssigneeChange(event) {
    event.persist();
    const bid = this.state.assigneeList.find(
      (e) => e.id === event.target.value
    );
    const awardedBid = {
      ...bid.bid,
      awarded: true,
    };
    this.setState({ assignee: event.target.value }, () => {
      bid.bid.awarded = true;
      // this.props.dispatch(putAwardedBid(bid, bid.bid.id, () => {
      this.props.dispatch(
        putAwardedBid(awardedBid, this.state.contractId, () => {
          toast.success('Bid awarded successfully');
        })
      );
      // }))
    });
  }

  onChange = (event) => {
    this.setState({
      doers: event.target.value,
    });
  };
  addCriteria = () => {
    this.setState({
      showCriteria: true,
    });
  };
  closeCriteria = () => {
    this.setState({
      showCriteria: false,
    });
  };
  saveCriteria = () => {
    this.setState({
      showCriteria: false,
    });
  };

  gotoSprintPage() {
    this.props.history.goBack();
  }

  onActivityChange(e) {
    e.persist();
    this.setState({ activity: e.target.value });
  }

  gotoawardBid(bid, id) {
    bid.bid.awarded = true;
    this.props.dispatch(
      putAwardedBid(bid.bid, id, () => {
        toast.success('Bid awarded successfully');
      })
    );
  }

  checkUserAvailability(user, contractData){

    if(!contractData){
      return "";
    }

    let userAvail = contractData.visible.filter(item => item.id === user.id);

    if(userAvail && userAvail[0].rejected === true){
      return "Rejected"
    }else{
      if(user.bid){
        return "Accepted"
      }else{
        return "Not available"
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { assigneeList, bidList, contractData } = this.state;

    let yesterday = Datetime.moment().subtract(1, 'day');
    let valid = function(current) {
      return current.isAfter(yesterday);
    };

    const storyType =
      this.state.storyData && this.state.storyData.storyType
        ? this.state.storyData.storyType === cardTypeDesign
          ? 'design-card'
          : this.state.storyData.storyType === cardTypeFrontend
          ? 'frontend-card'
          : this.state.storyData.storyType === cardTypeAPI
          ? 'api-card'
          : this.state.storyData.storyType === cardTypeBackend
          ? 'backend-card'
          : ''
        : '';

    let jsonFromDescription = null;
    if (storyType !== '') {
      jsonFromDescription = JSON.parse(this.state.storyData.description);
    }

    const simpleButtons = (bid) =>
      [
        { icon: VisibilityIcon },
        {
          icon: PlayCircleOutlineIcon,
          onClick: () => this.gotoawardBid(bid, this.state.contractId),
        },
        // {icon: LocationOnIcon }
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={prop.onClick}
          >
            <prop.icon
              style={{ color: prop.color }}
              className={[
                classes.icon,
                classes.actionButtonRound,
                classes.tableActionIcon,
              ].join(' ')}
            />
          </Button>
        );
      });

    const tableData = bidList.map((e, i) => ({
      index: i + 1,
      firstName: e.firstName,
      lastName: e.lastName,
      hour: e.bid
        ? (() => {
            const minutes = e.bid.effortEstimateMinutes;
            const hours = Math.floor(minutes / 60);
            return `${hours}:${minutes - hours * 60} Hr`;
          })()
        : 'Not available',
      // status: e.bid ? e.bid.bidstatus : 'Not available',
      status: this.checkUserAvailability(e, contractData),
      completionDate: e.bid
        ? formatDateUsingUserTimezone(e.bid.completionEstimate)
        : "Not available",
      developmentCredits: (e.bid && e.bid.creditSummary)
        ? e.bid.creditSummary.devCredits
        : "Not available",
      otherCredits: (e.bid && e.bid.creditSummary)
      ? e.bid.creditSummary.otherCredits
        : "Not available",
      action: e.bid ? (
        <div className={classes.actionStyle}>{simpleButtons(e, i)}</div>
      ) : null,
    }));
    return (
      <div>
        <BreadCrumb
          pathArray={[
            { name: 'Market Place' },
            {
              name: 'Open Contracts',
              path: '/admin/open-contracts',
            },
            { name: this.state.title },
          ]}
        />
        {this.state.contractData && this.state.storyData && (
          <Card>
            <CardHeader color='info' className={classes.cardHeader}>
              <Button
                justIcon
                color='transparent'
                onClick={() => this.gotoSprintPage()}
              >
                <ArrowBackIcon />
              </Button>
              <h4 className={classes.cardTitle}>{this.state.title}</h4>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Project:
                  </FormLabel>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={9}
                  className={classes.selectPadding}
                >
                  {this.state.project.title}
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    User Story:
                  </FormLabel>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={5}
                  className={classes.selectPadding}
                >
                  {this.state.storyData.title}
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Status:
                      </FormLabel>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={9}
                      className={classes.selectPadding}
                    >
                      <Select
                        onChange={(e) => this.onStatusChange(e)}
                        className={classes.dateInputFullWidth}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={this.state.contractData.contractStatus}
                      >
                        <MenuItem
                          disabled
                          classes={{ root: classes.selectMenuItem }}
                        >
                          {' '}
                          Choose Acceptance Status{' '}
                        </MenuItem>
                        {this.state.status.map((menu) => (
                          <MenuItem
                            value={menu}
                            classes={{ root: classes.selectMenuItem }}
                          >
                            {' '}
                            {menu}{' '}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

              <GridContainer justify='flex-end'>
                {storyType === '' && (
                  <>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Description:
                      </FormLabel>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={5}
                      className={classes.selectPadding}
                      style={{ marginTop: '2rem' }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.storyData.description,
                        }}
                      />
                    </GridItem>
                  </>
                )}

                <GridItem xs={12} sm={12} md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Assignee:
                      </FormLabel>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={9}
                      className={classes.selectPadding}
                    >
                      <Select
                        onChange={(e) => this.onAssigneeChange(e)}
                        className={classes.dateInputFullWidth}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        value={this.state.assignee}
                      >
                        <MenuItem
                          disabled
                          classes={{ root: classes.selectMenuItem }}
                        >
                          {' '}
                          Choose Assignee{' '}
                        </MenuItem>
                        {this.state.assigneeList.map((e) => (
                          <MenuItem
                            value={e.id}
                            classes={{ root: classes.selectMenuItem }}
                          >
                            {' '}
                            {e.firstName + ' ' + e.lastName}{' '}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {storyType !== '' && (
                <StoryCard
                  readOnly={true}
                  storyType={storyType}
                  classes={classes}
                  {...jsonFromDescription}
                />
              )}

              {/* Success Criteria: */}
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Story success criteria:
                  </FormLabel>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={5}
                  className={classes.selectPadding}
                  style={{ marginTop: '28px' }}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={9}>
                      {this.state.criterias.sort((a, b) => (a.position > b.position) ? 1 : -1).map((e) => (
                        <div className={classes.criteriaGap}>
                          {' '}
                          <FormControlLabel
                            className={
                              classes.CheckboxContainer +
                              ' ' +
                              classes.selectPadding
                            }
                            control={
                              <Checkbox
                                className={classes.textEdit}
                                value='checkedB'
                                color='primary'
                              />
                            }
                            label={
                              <div
                                className={
                                  classes.textEdit + ' ' + classes.editCriteria
                                }
                              >
                                {(e && e.text) ? e.text : (e && e.criteria) ? e.criteria : ''}
                              </div>
                            }
                            style={{
                              color: 'rgba(0, 0, 0, 0.87)',
                            }}
                          />
                        </div>
                      ))}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      className={classes.selectPadding}
                    >
                      <Button
                        color='info'
                        className={
                          classes.designButton + ' ' + classes.selectPadding
                        }
                        round
                        onClick={() => this.addCriteria()}
                      >
                        + Add
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Bids Due:
                      </FormLabel>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={9}
                      className={classes.selectPadding}
                    >
                      <FormControl fullWidth>
                        <div className={classes.dateLogo}>
                          <div className={classes.dateInput}>
                            <Datetime
                              isValidDate={valid}
                              timeFormat={true}
                              value={moment(contractData.contractBidExpiry)}
                              onChange={this.setDueDate}
                              onBlur={this.updateDueDate}
                              ref={this.dateInp}
                            />
                          </div>
                          <EventIcon onClick={() => this.openCal()} />
                        </div>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormLabel className={classes.flex1}>Bid Table:</FormLabel>
                  <Button
                    color='info'
                    className={
                      classes.addCriteriaBtn + ' ' + classes.designButton
                    }
                    round
                    onClick={this.modalClickHandler}
                    style={{
                      float: 'right',
                      boxShadow: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Request Estimates
                  </Button>

                  <Button
                    color='info'
                    className={
                      classes.addCriteriaBtn + ' ' + classes.designButton
                    }
                    round
                    onClick={this.overrideCreditsClickHandler}
                    style={{
                      float: 'right',
                      boxShadow: 'none',
                      cursor: 'pointer',
                      marginRight:'10px'
                    }}
                  >
                    OVERRIDE CREDITS
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Table
                    className={classes.tableHeight + ' ' + classes.display}
                    style={{ display: 'block' }}
                    tableHead={[
                      {
                        Header: '#',
                        accessor: 'index',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                        // className: classes.left,
                        width: 70,
                      },
                      {
                        Header: 'First Name',
                        accessor: 'firstName',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                      },
                      {
                        Header: 'Last Name',
                        accessor: 'lastName',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                        className: classes.cellPadding,
                      },
                      {
                        Header: 'Status',
                        accessor: 'status',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                      },
                      {
                        Header: 'Estimated Work Effect',
                        accessor: 'hour',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                      },
                      {
                        Header: 'Completion Date',
                        accessor: 'completionDate',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                      },
                      {
                        Header: 'Development Credits',
                        accessor: 'developmentCredits',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                      },
                      {
                        Header: 'Other Credits',
                        accessor: 'otherCredits',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                      },
                      {
                        Header: 'Action',
                        accessor: 'action',
                        filterable: false,
                        headerClassName: classes.tableHearders,
                      },
                    ]}
                    // loading={isFetching}
                    // TODO tableData={tableData}
                    tableData={tableData}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem className={classes.activity} xs={12} sm={2} md={2}>
                  <FormLabel
                    className={
                      classes.labelHorizontal + ' ' + classes.paddingTop6
                    }
                  >
                    Activity:
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={5} md={5}>
                  <Select
                    className={classes.slSize + ' ' + classes.labelText}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    onChange={(e) => this.onActivityChange(e)}
                    value={this.state.activity}
                    classes={{
                      select: classes.select,
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Activity
                    </MenuItem>
                    <MenuItem
                      value='history'
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      History
                    </MenuItem>
                    <MenuItem
                      value='comment'
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Comment
                    </MenuItem>
                    {/* {this.state.assigneeList.map((e) =>
                                              <MenuItem classes={{ root: classes.selectMenuItem }} value={e.id}>{e.firstName + " " + e.lastName}</MenuItem>
                                      )} */}
                  </Select>
                </GridItem>
              </GridContainer>
              <CommentSection
                withdraws={this.state.withdraws}
                id={this.state.contractData.id}
              />
            </CardBody>
          </Card>
        )}

        {this.state.modalState && (
          <SaveBid
            closeModal={this.modalClickHandler}
            modalState={this.state.modalState}
            contractData={this.state.contractData}
            onAssigneeFetch={() => this.onAssigneeFetch()}
          />
        )}

        {this.state.overrideCreditsModal && (
          <OverrideCredits
            closeModal={this.overrideCreditsClickHandler}
            modalState={this.state.overrideCreditsModal}
            contractData={this.state.contractData}
          />
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(
  withStyles(ContractEditStyle)(EditContract)
);
