import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(modalStyle);

function CustomDialog({ onClose, onConfirm, showDialog, children }) {
    const classes = useStyles();
    return (
        <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
            }}
            open={showDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
        >
            <DialogTitle
                id="modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={onClose}
                >
                    <Close className={classes.modalClose} />
                </Button>
            </DialogTitle>
            <DialogContent
                id="modal-slide-description"
                className={
                    classes.modalBody
                }
            >
                {children}
            </DialogContent>
            <DialogActions
                className={
                    classes.modalFooter + " " + classes.modalFooterCenter
                }
            >
                <Button
                    onClick={onClose}
                    color="transparent"
                    className={classes.modalSmallFooterFirstButton}
                >
                    No
                </Button>
                <Button
                    onClick={onConfirm}
                    color="success"
                    simple
                    className={
                        classes.modalSmallFooterFirstButton +
                        " " +
                        classes.modalSmallFooterSecondButton
                    }
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomDialog
