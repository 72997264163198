import React, {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';

import ContractEditStyle from 'assets/jss/material-dashboard-pro-react/views/ContractEditStyle.js';
import Input from '@material-ui/core/Input';
import {BreadCrumb} from 'utils/common';
import {toast} from 'react-toastify';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormLabel from '@material-ui/core/FormLabel';
import {getProjectList} from 'actions/project';

import {
  getContractBid,
  getContractByStoryId,
  getContractById,
  getContractComments,
  postContractComments,
  postStory,
} from '../../api/contractsApi';
import {
  getContractList,
  updateContractModal,
  getAssigneeList,
  putAwardedBid,
  getBidDetail,
  getContractStatus,
  postCreateContract,
  getAllContracts,
} from '../../actions/contract';
import {updateOneUserStory} from '../../actions/userStories';
import {getProjectDetails} from '../../actions/project';
import {getUserData, getUserId} from '../../utils/common';
import {getUserList} from '../../actions/userUpdate';
import {
  getContracts,
  getUserStory,
  getContractsByKey,
} from '../../actions/userStories';
import TextField from '@material-ui/core/TextField';
import StoryCard from '../../components/story-card';

import {updateContractStatus} from 'actions/contract';
import {developmentStatus} from 'common/constants';
//import { getContracts, getStoryList, getContractsByKey } from '../../actions/userStories'

const cardTypeAPI = 'API Card';
const cardTypeFrontend = 'Frontend Card';
const cardTypeBackend = 'Backend Card';
const cardTypeDesign = 'Design Card';

class UserStoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bidStatus: '',
      contract: [],
      isLoading: true,
      status: [],
      story: this.props.story,
      showDescriptionEditor: false,
      // API card states
      apiAccessibleField: '',
      apiArchitecture: '',
      apiAuthenticatedField: '',
      apiDatastore: '',
      apiDescription: '',
      apiRefDesc: '',
      apiTestDesc: '',
      apiTool: '',
      // Frontend card states
      frontEndDesc: '',
      frontEndDesignMockRef: '',
      frontEndMockRef: '',
      frontEndSwaggerField: '',
      frontEndTool: '',
      frontEndViewNameField: '',
      frontEndViewNavigationField: '',
      frontEndWorkFlow: '',
      // Design card states
      designActionElement: '',
      designControlField: '',
      designDesc: '',
      designElement: '',
      designMock: '',
      designTool: '',
      designViewNameField: '',
      designViewNavigationField: '',
      // Backend card states
      backEndTool: '',
      backEndDesignMock: '',
      backEndAuthenticatedField: '',
      backEndAccessibleField: '',
      backEndViewNameField: '',
      backEndViewNavigationField: '',
      backEndDesc: '',
      backEndWorkFlow: '',
    };
  }

  populateFieldsBasedOnCardType(story) {
    if (story.storyType === 'API Card') {
      const storyFieldsData = JSON.parse(story.description);
      this.setState({
        apiAccessibleField: storyFieldsData.apiAccessibleField
          ? storyFieldsData.apiAccessibleField
          : '',
        apiArchitecture: storyFieldsData.apiArchitecture
          ? storyFieldsData.apiArchitecture
          : '',
        apiAuthenticatedField: storyFieldsData.apiAuthenticatedField
          ? storyFieldsData.apiAuthenticatedField
          : '',
        apiDatastore: storyFieldsData.apiDatastore
          ? storyFieldsData.apiDatastore
          : '',
        apiDescription: storyFieldsData.apiDescription
          ? storyFieldsData.apiDescription
          : '',
        apiRefDesc: storyFieldsData.apiRefDesc
          ? storyFieldsData.apiRefDesc
          : '',
        apiTestDesc: storyFieldsData.apiTestDesc
          ? storyFieldsData.apiTestDesc
          : '',
        apiTool: storyFieldsData.apiTool ? storyFieldsData.apiTool : '',
      });
    } else if (story.storyType === 'Frontend Card') {
      const storyFieldsData = JSON.parse(story.description);
      this.setState({
        frontEndDesc: storyFieldsData.frontEndDesc
          ? storyFieldsData.frontEndDesc
          : '',
        frontEndDesignMockRef: storyFieldsData.frontEndDesignMockRef
          ? storyFieldsData.frontEndDesignMockRef
          : '',
        frontEndMockRef: storyFieldsData.frontEndMockRef
          ? storyFieldsData.frontEndMockRef
          : '',
        frontEndSwaggerField: storyFieldsData.frontEndSwaggerField
          ? storyFieldsData.frontEndSwaggerField
          : '',
        frontEndTool: storyFieldsData.frontEndTool
          ? storyFieldsData.frontEndTool
          : '',
        frontEndViewNameField: storyFieldsData.frontEndViewNameField
          ? storyFieldsData.frontEndViewNameField
          : '',
        frontEndViewNavigationField: storyFieldsData.frontEndViewNavigationField
          ? storyFieldsData.frontEndViewNavigationField
          : '',
        frontEndWorkFlow: storyFieldsData.frontEndWorkFlow
          ? storyFieldsData.frontEndWorkFlow
          : '',
      });
    } else if (story.storyType === 'Design Card') {
      const storyFieldsData = JSON.parse(story.description);
      this.setState({
        designActionElement: storyFieldsData.designActionElement
          ? storyFieldsData.designActionElement
          : '',
        designControlField: storyFieldsData.designControlField
          ? storyFieldsData.designControlField
          : '',
        designDesc: storyFieldsData.designDesc
          ? storyFieldsData.designDesc
          : '',
        designElement: storyFieldsData.designElement
          ? storyFieldsData.designElement
          : '',
        designMock: storyFieldsData.designMock
          ? storyFieldsData.designMock
          : '',
        designTool: storyFieldsData.designTool
          ? storyFieldsData.designTool
          : '',
        designViewNameField: storyFieldsData.designViewNameField
          ? storyFieldsData.designViewNameField
          : '',
        designViewNavigationField: storyFieldsData.designViewNavigationField
          ? storyFieldsData.designViewNavigationField
          : '',
      });
    } else if (story.storyType === 'Backend Card') {
      const storyFieldsData = JSON.parse(story.description);
      this.setState({
        backEndTool: storyFieldsData.backEndTool
          ? storyFieldsData.backEndTool
          : '',
        backEndDesignMock: storyFieldsData.backEndDesignMock
          ? storyFieldsData.backEndDesignMock
          : '',
        backEndAuthenticatedField: storyFieldsData.backEndAuthenticatedField
          ? storyFieldsData.backEndAuthenticatedField
          : '',
        backEndAccessibleField: storyFieldsData.backEndAccessibleField
          ? storyFieldsData.backEndAccessibleField
          : '',
        backEndViewNameField: storyFieldsData.backEndViewNameField
          ? storyFieldsData.backEndViewNameField
          : '',
        backEndViewNavigationField: storyFieldsData.backEndViewNavigationField
          ? storyFieldsData.backEndViewNavigationField
          : '',
        backEndDesc: storyFieldsData.backEndDesc
          ? storyFieldsData.backEndDesc
          : '',
        backEndWorkFlow: storyFieldsData.backEndWorkFlow
          ? storyFieldsData.backEndWorkFlow
          : '',
      });
    }
  }

  componentDidMount() {
    const {match} = this.props;

    this.props.dispatch(
      getUserStory(match.params.storyId, (storyData) => {
        this.setState({
          story: storyData,
          isLoading: false,
        });
        this.populateFieldsBasedOnCardType(storyData);
        this.props.dispatch(getProjectList(storyData.project));
      })
    );
  }

  onDescriptionChange(e) {
    this.setState({
      story: {...this.state.story, description: e.target.value},
    });
  }

  onDescriptionSubmit = async () => {
    const {story} = this.props;

    let updatedStory = this.state.story;
    if (story.storyType === 'API Card') {
      const {
        apiAccessibleField,
        apiArchitecture,
        apiAuthenticatedField,
        apiDatastore,
        apiDescription,
        apiRefDesc,
        apiTestDesc,
        apiTool,
      } = this.state;

      const descriptionShovedState = {
        description: {},
        apiAccessibleField,
        apiArchitecture,
        apiAuthenticatedField,
        apiDatastore,
        apiDescription,
        apiRefDesc,
        apiTestDesc,
        apiTool,
      };

      const descriptionShovedStateStr = JSON.stringify(descriptionShovedState);
      updatedStory = {
        ...this.state.story,
        description: descriptionShovedStateStr,
      };
    } else if (story.storyType === 'Frontend Card') {
      const {
        frontEndDesc,
        frontEndDesignMockRef,
        frontEndMockRef,
        frontEndSwaggerField,
        frontEndTool,
        frontEndViewNameField,
        frontEndViewNavigationField,
        frontEndWorkFlow,
      } = this.state;

      const descriptionShovedState = {
        description: {},
        frontEndDesc,
        frontEndDesignMockRef,
        frontEndMockRef,
        frontEndSwaggerField,
        frontEndTool,
        frontEndViewNameField,
        frontEndViewNavigationField,
        frontEndWorkFlow,
      };

      const descriptionShovedStateStr = JSON.stringify(descriptionShovedState);
      updatedStory = {
        ...this.state.story,
        description: descriptionShovedStateStr,
      };
    } else if (story.storyType === 'Design Card') {
      const {
        designActionElement,
        designControlField,
        designDesc,
        designElement,
        designMock,
        designTool,
        designViewNameField,
        designViewNavigationField,
      } = this.state;

      const descriptionShovedState = {
        description: {},
        designActionElement,
        designControlField,
        designDesc,
        designElement,
        designMock,
        designTool,
        designViewNameField,
        designViewNavigationField,
      };

      const descriptionShovedStateStr = JSON.stringify(descriptionShovedState);
      updatedStory = {
        ...this.state.story,
        description: descriptionShovedStateStr,
      };
    } else if (story.storyType === 'Backend Card') {
      const {
        backEndTool,
        backEndDesignMock,
        backEndAuthenticatedField,
        backEndAccessibleField,
        backEndViewNameField,
        backEndViewNavigationField,
        backEndDesc,
        backEndWorkFlow,
      } = this.state;

      const descriptionShovedState = {
        description: {},
        backEndTool,
        backEndDesignMock,
        backEndAuthenticatedField,
        backEndAccessibleField,
        backEndViewNameField,
        backEndViewNavigationField,
        backEndDesc,
        backEndWorkFlow,
      };

      const descriptionShovedStateStr = JSON.stringify(descriptionShovedState);
      updatedStory = {
        ...this.state.story,
        description: descriptionShovedStateStr,
      };
    }
    await this.props.dispatch(
      updateOneUserStory(story, updatedStory, () => {
        toast.success('Story Updated');
      })
    );
    this.setState({
      showDescriptionEditor: false,
    });
  };

  onDescriptionCancel() {
    const {state, props} = this;
    this.setState({
      showDescriptionEditor: false,
      story: {...state.story, description: props.story.description},
    });
  }

  onStatusChange(event) {
    const {story} = this.props;
    event.persist();
    this.setState(
      {
        story: {
          ...this.state.story,
          development: {
            ...this.state.story.development,
            status: event.target.value,
          },
        },
      },
      () => {
        this.props.dispatch(
          updateOneUserStory(story, this.state.story, () => {
            toast.success('Status Updated');
          })
        );
      }
    );
  }

  handleSimple = async (e) => {
    let {story, contract} = this.state;
    contract.contractStatus = e.target.value;
    this.setState({contract});
    const res = await postStory(story.project, story.sprint, contract);
    if (res && res.success) {
      toast.success('Story Successfully Updated');
    }
  };

  gotoUserStoryPage() {
    this.props.history.goBack();
  }

  onChange = (event) => {
    this.setState({
      doers: event.target.value,
    });
  };

  onInputChange = (event) => {
    const {
      target: {value, name},
    } = event;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {classes, story, project} = this.props;
    const {
      apiAccessibleField,
      apiArchitecture,
      apiAuthenticatedField,
      apiDatastore,
      apiDescription,
      apiRefDesc,
      apiTestDesc,
      apiTool,
      frontEndDesc,
      frontEndDesignMockRef,
      frontEndMockRef,
      frontEndSwaggerField,
      frontEndTool,
      frontEndViewNameField,
      frontEndViewNavigationField,
      frontEndWorkFlow,
      designActionElement,
      designControlField,
      designDesc,
      designElement,
      designMock,
      designTool,
      designViewNameField,
      designViewNavigationField,
      backEndTool,
      backEndDesignMock,
      backEndAuthenticatedField,
      backEndAccessibleField,
      backEndViewNameField,
      backEndViewNavigationField,
      backEndDesc,
      backEndWorkFlow,
    } = this.state;

    const storyCardStates = {
      apiAccessibleField,
      apiArchitecture,
      apiAuthenticatedField,
      apiDatastore,
      apiDescription,
      apiRefDesc,
      apiTestDesc,
      apiTool,
      frontEndDesc,
      frontEndDesignMockRef,
      frontEndMockRef,
      frontEndSwaggerField,
      frontEndTool,
      frontEndViewNameField,
      frontEndViewNavigationField,
      frontEndWorkFlow,
      designActionElement,
      designControlField,
      designDesc,
      designElement,
      designMock,
      designTool,
      designViewNameField,
      designViewNavigationField,
      backEndTool,
      backEndDesignMock,
      backEndAuthenticatedField,
      backEndAccessibleField,
      backEndViewNameField,
      backEndViewNavigationField,
      backEndDesc,
      backEndWorkFlow,
    };

    const storyType =
      this.props.story && this.props.story.storyType
        ? this.props.story.storyType === cardTypeDesign
          ? 'design-card'
          : this.props.story.storyType === cardTypeFrontend
          ? 'frontend-card'
          : this.props.story.storyType === cardTypeAPI
          ? 'api-card'
          : this.props.story.storyType === cardTypeBackend
          ? 'backend-card'
          : ''
        : '';
    const canEdit =
      story.development &&
      story.development.status &&
      story.development.status.toLowerCase() === 'not started'
        ? true
        : false;
    return (
      <div>
        <BreadCrumb
          pathArray={[
            {name: 'Requestor'},
            {
              name: 'User stories',
              path: '/admin/user-stories',
            },
            {name: story.storyKey},
          ]}
        />
        <Card>
          <CardHeader color="info" className={classes.cardHeader}>
            <Button
              justIcon
              color="transparent"
              onClick={() => this.gotoUserStoryPage()}
            >
              <ArrowBackIcon />
            </Button>
            {this.state.story && this.state.story.storyKey && (
              <h4 className={classes.cardTitle}>{story.storyKey}</h4>
            )}
          </CardHeader>
          {story &&
          this.state.status &&
          project &&
          this.state.isLoading === false ? (
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel className={classes.labelHorizontal}></FormLabel>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={9}
                  className={classes.selectPadding}
                >
                  {storyType && (
                    <span style={{textTransform: 'capitalize'}}>
                      {storyType.replace('-', ' ')}
                    </span>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Project:
                  </FormLabel>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={5}
                  className={classes.selectPadding}
                >
                  <strong>{project.title}</strong>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Status:
                      </FormLabel>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={9}
                      className={classes.selectPadding}
                    >
                      <Select
                        onChange={(e) => this.onStatusChange(e)}
                        className={classes.dateInputFullWidth}
                        MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        value={this.state.story.development.status}
                      >
                        <MenuItem
                          disabled
                          classes={{root: classes.selectMenuItem}}
                        >
                          {' '}
                          Choose Acceptance Status{' '}
                        </MenuItem>
                        {developmentStatus.map((menu, index) => (
                          <MenuItem
                            key={index}
                            value={menu.value}
                            classes={{root: classes.selectMenuItem}}
                          >
                            {' '}
                            {menu.name}{' '}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    User Story:
                  </FormLabel>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={9}
                  className={classes.selectPadding}
                >
                  {story.title}
                </GridItem>
              </GridContainer>
              {this.props.story && this.props.story.storyType === null && (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Description:
                    </FormLabel>
                  </GridItem>
                  {this.state.showDescriptionEditor ? (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.selectPadding}
                    >
                      <textarea
                        className={classes.textArea}
                        onChange={(e) => this.onDescriptionChange(e)}
                        value={this.state.story.description}
                      />
                      <div style={{float: 'right', color: '#00acc1'}}>
                        <span
                          onClick={() => this.onDescriptionSubmit()}
                          className={
                            classes.editorSave + ' ' + classes.clickable
                          }
                        >
                          save
                        </span>
                        <span
                          onClick={() => this.onDescriptionCancel()}
                          className={classes.clickable}
                        >
                          cancel
                        </span>
                      </div>
                    </GridItem>
                  ) : (
                    <>
                      <GridItem
                        xs={8}
                        sm={10}
                        md={5}
                        className={`${classes.selectPadding} ${classes.removeMargin}`}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: story.description,
                          }}
                        ></div>
                      </GridItem>
                      <GridItem
                        xs={4}
                        sm={2}
                        md={3}
                        className={classes.selectPadding}
                      >
                        {canEdit && (
                          <span
                            className={classes.clickable}
                            style={{color: '#00acc1'}}
                            onClick={() =>
                              this.setState({showDescriptionEditor: true})
                            }
                          >
                            Edit
                          </span>
                        )}
                      </GridItem>
                    </>
                  )}
                </GridContainer>
              )}

              {storyType !== '' && (
                <StoryCard
                  storyType={storyType}
                  onChange={this.onInputChange}
                  classes={classes}
                  {...storyCardStates}
                  disabled={canEdit ? false : true}
                />
              )}

              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    Story success criteria:
                  </FormLabel>
                </GridItem>

                <GridItem
                  xs={12}
                  sm={12}
                  md={5}
                  className={classes.selectPadding}
                  style={{marginTop: '28px'}}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={9}>
                      {story.criteria.sort((a, b) => (a.position > b.position) ? 1 : -1).map((e, index) => (
                        <div key={index} className={classes.criteriaGap}>
                          {' '}
                          <FormControlLabel
                            className={
                              classes.CheckboxContainer +
                              ' ' +
                              classes.selectPadding
                            }
                            control={
                              <Checkbox
                                className={classes.textEdit}
                                value="checkedB"
                                color="primary"
                              />
                            }
                            label={
                              <div
                                className={
                                  classes.textEdit + ' ' + classes.editCriteria
                                }
                              >
                                {' '}
                                {e.text}{' '}
                              </div>
                            }
                            style={{
                              color: 'rgba(0, 0, 0, 0.87)',
                            }}
                          />
                        </div>
                      ))}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      className={classes.selectPadding}
                    >
                      <Button
                        color="info"
                        className={
                          classes.designButton + ' ' + classes.selectPadding
                        }
                        round
                        onClick={() => this.addCriteria()}
                      >
                        + Add
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {storyType !== '' && canEdit && (
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{textAlign: 'center', marginTop: '2rem'}}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.onDescriptionSubmit()}
                  >
                    Update
                  </Button>
                </GridItem>
              )}
            </CardBody>
          ) : (
            <div style={{textAlign: 'center'}}>
              <LinearProgress />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  story: state.userStories.singleStory,
  project: state.project.oneProjectData,
});

export default connect(mapStateToProps)(
  withStyles(ContractEditStyle)(UserStoryDetail)
);
