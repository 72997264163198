export const CONTACT_US_EMAIL = 'support@archimydes.com';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STRIPE_KEYS = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

export const timezoneList = [
    {
        timezone: 'Pacific/Tongatapu',
        display: "GMT+13:00",
        name: "Nuku'alofa"
    },
    {
        timezone: "Pacific/Auckland",
        display: "GMT+12:00",
        name: "Auckland, Wellington"
    },
    {
        timezone: "Etc/GMT-12",
        display: "GMT+12:00",
        name: "Fiji, Kamchatka, Marshall Is."
    },
    {
        timezone: "Asia/Magadan",
        display: "GMT+12:00",
        name: "Magadan"
    },
    {
        timezone: "Etc/GMT-11",
        display: "GMT+11:00",
        name: "Solomon Is., New Caledonia"
    },
    {
        timezone: "Asia/Vladivostok",
        display: "GMT+11:00",
        name: "Vladivostok"
    },
    {
        timezone: "Asia/Yakutsk",
        display: "GMT+10:00",
        name: "Yakutsk"
    },
    {
        timezone: "Australia/Brisbane",
        display: "GMT+10:00",
        name: "Brisbane"
    },
    {
        timezone: "Australia/Sydney",
        display: "GMT+10:00",
        name: "Canberra, Melbourne, Sydney"
    },
    {
        timezone: "Australia/Hobart",
        display: "GMT+10:00",
        name: "Hobart"
    },
    {
        timezone: "Australia/Adelaide",
        display: "GMT+09:30",
        name: "Adelaide"
    },
    {
        timezone: "Australia/Darwin",
        display: "GMT+09:30",
        name: "Darwin"
    },
    {
        timezone: "Asia/Irkutsk",
        display: "GMT+09:00",
        name: "Irkutsk, Ulaan Bataar"
    },
    {
        timezone: "Asia/Tokyo",
        display: "GMT+09:00",
        name: "Osaka, Sapporo, Tokyo"
    },
    {
        timezone: "Asia/Seoul",
        display: "GMT+09:00",
        name: "Seoul"
    },
    {
        timezone: "Asia/Hong_Kong",
        display: "GMT+08:00",
        name: "Beijing, Chongqing, Hong Kong, Urumqi"
    },
    {
        timezone: "Asia/Krasnoyarsk",
        display: "GMT+08:00",
        name: "Krasnoyarsk"
    },
    {
        timezone: "Australia/Perth",
        display: "GMT+08:00",
        name: "Perth"
    },
    {
        timezone: "Asia/Taipei",
        display: "GMT+08:00",
        name: "Taipei"
    },
    {
        timezone: "Asia/Bangkok",
        display: "GMT+07:00",
        name: "Bangkok, Hanoi, Jakarta"
    },
    {
        timezone: "Asia/Novosibirsk",
        display: "GMT+07:00",
        name: "Novosibirsk"
    },
    {
        timezone: "Asia/Rangoon",
        display: "GMT+06:30",
        name: "Yangon Rangoon"
    },
    {
        timezone: "Asia/Dhaka",
        display: "GMT+06:00",
        name: "Astana, Dhaka"
    },
    {
        timezone: "Asia/Yekaterinburg",
        display: "GMT+06:00",
        name: "Ekaterinburg"
    },
    {
        timezone: "Asia/Katmandu",
        display: "GMT+05:45",
        name: "Kathmandu"
    },
    {
        timezone: "Asia/Kolkata",
        display: "GMT+05:30",
        name: "Chennai, Kolkata, Mumbai, New Delhi"
    },
    {
        timezone: "Asia/Tashkent",
        display: "GMT+05:00",
        name: "Asia/Tashkent"
    },
    {
        timezone: "Asia/Kabul",
        display: "GMT+04:30",
        name: "Kabul"
    },
    {
        timezone: "Asia/Dubai",
        display: "GMT+04:00",
        name: "Abu Dhabi, Muscat"
    },
    {
        timezone: "Asia/Baku",
        display: "GMT+04:00",
        name: "Baku"
    },
    {
        timezone: "Europe/Moscow",
        display: "GMT+04:00",
        name: "Moscow, St. Petersburg, Volgograd"
    },
    {
        timezone: "Asia/Tbilisi",
        display: "GMT+04:00",
        name: "Tbilisi"
    },
    {
        timezone: "Asia/Yerevan",
        display: "GMT+04:00",
        name: "Yerevan"
    },
    {
        timezone: "Asia/Tehran",
        display: "GMT+03:30",
        name: "Tehran"
    },
    {
        timezone: "Asia/Baghdad",
        display: "GMT+03:00",
        name: "Baghdad"
    },
    {
        timezone: "Europe/Minsk",
        display: "GMT+03:00",
        name: "Kaliningrad, Minsk"
    },
    {
        timezone: "Asia/Kuwait",
        display: "GMT+03:00",
        name: "Kuwait, Riyadh"
    },
    {
        timezone: "Africa/Addis_Ababa",
        display: "GMT+03:00",
        name: "Nairobi"
    },
    {
        timezone: "Asia/Amman",
        display: "GMT+02:00",
        name: "Amman"
    },
    {
        timezone: "Europe/Bucharest",
        display: "GMT+02:00",
        name: "Athens, Bucharest"
    },
    {
        timezone: "Asia/Beirut",
        display: "GMT+02:00",
        name: "Beirut"
    },
    {
        timezone: "Africa/Cairo",
        display: "GMT+02:00",
        name: "Cairo"
    },
    {
        timezone: "Asia/Damascus",
        display: "GMT+02:00",
        name: "Damascus"
    },
    {
        timezone: "Africa/Blantyre",
        display: "GMT+02:00",
        name: "Harare, Pretoria"
    },
    {
        timezone: "Europe/Helsinki",
        display: "GMT+02:00",
        name: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    },
    {
        timezone: "Europe/Istanbul",
        display: "GMT+02:00",
        name: "Istanbul"
    },
    {
        timezone: "Asia/Jerusalem",
        display: "GMT+02:00",
        name: "Jerusalem"
    },
    {
        timezone: "Europe/Nicosia",
        display: "GMT+02:00",
        name: "Nicosia"
    },
    {
        timezone: "Europe/Amsterdam",
        display: "GMT+01:00",
        name: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
        timezone: "Europe/Belgrade",
        display: "GMT+01:00",
        name: "Belgrade, Bratislava, Budapest, Prague, Ljubljana"
    },
    {
        timezone: "Europe/Brussels",
        display: "GMT+01:00",
        name: "Brussels, Copenhagen, Madrid, Paris"
    },
    {
        timezone: "Europe/Warsaw",
        display: "GMT+01:00",
        name: "Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
        timezone: "Africa/Algiers",
        display: "GMT+01:00",
        name: "West Central Africa"
    },
    {
        timezone: "Africa/Windhoek",
        display: "GMT+01:00",
        name: "Windhoek"
    },
    {
        timezone: "Africa/Casablanca",
        display: "GMT-00:00",
        name: "Casablanca"
    },
    {
        timezone: "Etc/UTC",
        display: "GMT-00:00",
        name: "Coordinated Universal Time"
    },
    {
        timezone: "Africa/Abidjan",
        display: "GMT-00:00",
        name: "Monrovia, Reykjavik"
    },
    {
        timezone: "Europe/London",
        display: "GMT-00:00",
        name: "Dublin, Lisabon, London, Edinburgh"
    },
    {
        timezone: "Atlantic/Cape_Verde",
        display: "GMT-01:00",
        name: "Cape Verde Is."
    },
    {
        timezone: "Atlantic/Azores",
        display: "GMT-01:00",
        name: "Azores"
    },
    {
        timezone: "America/Noronha",
        display: "GMT-02:00",
        name: "Mid-Atlantic"
    },
    {
        timezone: "Etc/GMT+2",
        display: "GMT-02:00",
        name: "Coordinated Universal Time - 02"
    },
    {
        timezone: "America/Sao_Paulo",
        display: "GMT-03:00",
        name: "Brasilia"
    },
    {
        timezone: "America/Argentina/Buenos_Aires",
        display: "GMT-03:00",
        name: "Buenos Aires"
    },
    {
        timezone: "America/Cayenne",
        display: "GMT-03:00",
        name: "Cayenne, Fortalenza"
    },
    {
        timezone: "America/Godthab",
        display: "GMT-03:00",
        name: "Greenland"
    },
    {
        timezone: "America/Montevideo",
        display: "GMT-03:00",
        name: "Montevideo"
    },
    {
        timezone: "America/St_Johns",
        display: "GMT-03:30",
        name: "Newfoundland"
    },
    {
        timezone: "America/Asuncion",
        display: "GMT-04:00",
        name: "Asuncion"
    },
    {
        timezone: "America/Goose_Bay",
        display: "GMT-04:00",
        name: "Atlantic Time, Goose Bay"
    },
    {
        timezone: "America/Glace_Bay",
        display: "GMT-04:00",
        name: "Atlantic Time, Canada"
    },
    {
        timezone: "America/Cuiaba",
        display: "GMT-04:00",
        name: "Cuiaba"
    },
    {
        timezone: "America/La_Paz",
        display: "GMT-04:00",
        name: "Georgetown, La Paz, Manaus, San Juan"
    },
    {
        timezone: "America/Santiago",
        display: "GMT-04:00",
        name: "Santiago"
    },
    {
        timezone: "America/Caracas",
        display: "GMT-04:30",
        name: "Caracas"
    },
    {
        timezone: "America/Bogota",
        display: "GMT-05:00",
        name: "Bogota, Lima, Quito, Rio Branco"
    },
    {
        timezone: "America/New_York",
        display: "GMT-05:00",
        name: "Eastern Time, US & Canada"
    },
    {
        timezone: "America/Havana",
        display: "GMT-05:00",
        name: "Cuba"
    },
    {
        timezone: "America/Indiana/Indianapolis",
        display: "GMT-05:00",
        name: "Indiana (East)"
    },
    {
        timezone: "America/Belize",
        display: "GMT-06:00",
        name: "Central America"
    },
    {
        timezone: "America/Chicago",
        display: "GMT-06:00",
        name: "Central Time, US & Canada"
    },
    {
        timezone: "America/Cancun",
        display: "GMT-06:00",
        name: "Guadalajara, Mexico City, Monterrey"
    },
    {
        timezone: "Canada/Saskatchewan",
        display: "GMT-06:00",
        name: "Saskatchewan"
    },
    {
        timezone: "America/Dawson_Creek",
        display: "GMT-07:00",
        name: "Arizona"
    },
    {
        timezone: "America/Chihuahua",
        display: "GMT-07:00",
        name: "Chihuahua, La Paz, Mazatlan"
    },
    {
        timezone: "America/Denver",
        display: "GMT-07:00",
        name: "Mountain Time, US & Canada"
    },
    {
        timezone: "America/Ensenada",
        display: "GMT-08:00",
        name: "Tijuana, Baja California"
    },
    {
        timezone: "America/Los_Angeles",
        display: "GMT-08:00",
        name: "Pacific Time, US & Canada"
    },
    {
        timezone: "America/Anchorage",
        display: "GMT-09:00",
        name: "Alaska"
    },
    {
        timezone: "Etc/GMT+10",
        display: "GMT-10:00",
        name: "Hawaii"
    },
    {
        timezone: "Pacific/Midway",
        display: "GMT-11:00",
        name: "Midway Island, Samoa"
    },
    {
        timezone: "Etc/GMT+12",
        display: "GMT-12:00",
        name: "International Date Line West"
    }

]

export const sprintStatus = [
    {
        name: 'Draft',
        value: 'Draft',
    },
    {
        name: 'In Review',
        value: 'In Review',
    },
    {
        name: 'Submit Stories',
        value: 'Submit Stories',
    },
    {
        name: 'In Progress',
        value: 'In Progress',
    },
    {
        name: 'Completed',
        value: 'Completed',
    },
    {
        name: 'Cancelled',
        value: 'Cancelled',
    },
]

export const sprintReviewStatus = [
    {
        name: "N/A",
        value: "N/A"
    },
    {
        name: "User Story Review",
        value: "User Story Review"
    },
    {
        name: "In Review",
        value: "In Review"
    },
    {
        name: "Code Review",
        value: "Code Review"
    },
    {
        name: "Completed",
        value: "Completed"
    },

]

export const sprintReviewStatuses = [
    {
        name: 'To Do',
        value: 'To Do'
    },
    {
        name: 'Story Review',
        value: 'Story Review'
    },
    {
        name: 'Assign to Bid',
        value: 'Assign to Bid'
    },
    {
        name: 'Review Bid',
        value: 'Review Bid'
    },
    {
        name: 'Initiate Development',
        value: 'Initiate Development'
    },
    {
        name: 'In Development',
        value: 'In Development'
    },
    {
        name: 'Development Blocked',
        value: 'Development Blocked'
    },
    {
        name: 'Awaiting Code Review',
        value: 'Awaiting Code Review'
    },
    {
        name: 'Merge Code',
        value: 'Merge Code'
    },
    {
        name: 'Awaiting Deployment',
        value: 'Awaiting Deployment'
    },
    {
        name: 'Awaiting QA',
        value: 'Awaiting QA'
    },
    {
        name: 'In QA',
        value: 'In QA'
    },
    {
        name: 'Bug Fix',
        value: 'Bug Fix'
    },
    {
        name: 'QA Blocked',
        value: 'QA Blocked'
    },
    {
        name: 'Acceptance',
        value: 'Acceptance'
    },
    {
        name: 'In UAT',
        value: 'In UAT'
    },
    {
        name: 'Return From UAT',
        value: 'Return From UAT'
    },
    {
        name: 'Accept',
        value: 'Accept'
    },
    {
        name: 'Done',
        value: 'Done'
    }
]

export const complexity = [
    {
        name: "Low",
        value: "Low"
    },
    {
        name: "Medium",
        value: "Medium"
    },
    {
        name: "High",
        value: "High"
    },
]
export const developmentStatus = [
    {
        name: "Not Started",
        value: "Not Started"
    },
    {
        name: "In Progress",
        value: "In Progress"
    },
    {
        name: "Completed",
        value: "Completed"
    },
]

export const contractStatus = [
    {name: "TO DO", value: "TO_DO"},
    {name: "STORY REVIEW", value: "STORY_REVIEW"},
    {name: "ASSIGN TO BID", value: "ASSIGN_TO_BID"},
    {name: "REVIEW BID", value: "REVIEW_BID"},
    {name: "INITIATE DEVELOPMENT", value: "INITIATE_DEVELOPMENT"},
    {name: "IN DEVELOPMENT", value: "IN_DEVELOPMENT"},
    {name: "DEVELOPMENT BLOCKED", value: "DEVELOPMENT_BLOCKED"},
    {name: "AWAITING CODE REVIEW", value: "AWAITING_CODE_REVIEW"},
    {name: "MERGE CODE", value: "MERGE_CODE"},
    {name: "AWAITING DEPLOYMENT", value: "AWAITING_DEPLOYMENT"},
    {name: "AWAITING QA", value: "AWAITING_QA"},
    {name: "IN QA", value: "IN_QA"},
    {name: "BUG FIX", value: "BUG_FIX"},
    {name: "QA BLOCKED", value: "QA_BLOCKED"},
    {name: "READY FOR QA", value: "READY_FOR_QA"},
    {name: "IN UAT", value: "IN_UAT"},
    {name: "RETURN FROM UAT", value: "RETURN_FROM_UAT"},
    {name: "ACCEPTED", value: "ACCEPTED"},
    {name: "DONE", value: "DONE"},
    {name: "DEVELOPMENT BID", value: "DEVELOPMENT_BID"},
    {name: "QA BID", value: "QA_BID"},
    {name: "DEVELOPMENT COMPLETED", value: "DEVELOPMENT_COMPLETED"},
    {name: "QA COMPLETED", value: "QA_COMPLETED"},
    {name: "CLIENT TESTING", value: "CLIENT_TESTING"},
    {name: "RETURN FROM CLIENT", value: "RETURN_FROM_CLIENT"},
    {name: "AWAITING PAYMENT", value: "AWAITING_PAYMENT"},
    {name: "PAID", value: "PAID"},
]

export const acceptanceStatus = [
    {
        name: "N/A",
        value: "N/A"
    },
    {
        name: "Test",
        value: "Test"
    },
    {
        name: "Bug Fix",
        value: "Bug Fix"
    },
    {
        name: "Accepted",
        value: "Accepted"
    },
    {
        name: "Not Started",
        value: "Not Started"
    }
]

export const acceptStatusOfContract = ["PAID", "AWAITING_PAYMENT"]
export const paymentStatus = ["DONE", "AWAITING_PAYMENT", "PAID"]
//Success Message On API Call
export const PROJECT_SAVE_SUCCESS_MSG = 'Project saved successfully';
export const PROJECT_UPDATE_SUCCESS_MSG = 'Project updated successfully';
export const CONTRACT_SAVE_SUCCESS_MSG = 'Contract saved successfully';
export const UPDATE_CONTRACT_SUCCESS_MSG = 'Contract updated successfully';
export const SPRINT_UPDATE_SUCCESS_MSG = 'Sprint updated successfully';
export const SPRINT_SAVE_SUCCESS_MSG = 'Sprint saved successfully';
export const USER_STORIES_SAVE_SUCCESS_MSG = 'User stories saved successfully';
export const LOGOUT_SUCCESS_MSG = 'User Logout Successfully';
export const USER_UPDATE_SUCCESS_MSE = 'User Updated Successfully';
export const ORGANIZATION_UPDATE_SUCCESS_MSG = 'Organization Updated Successfully';
export const USER_DELETE_SUCCESS_MSE = 'User deleted Successfully';
export const CREATE_USER_SAVE_SUCCESS_MSG = 'User created Successfully';
export const CREATE_ORGANIZATION_SAVE_SUCCESS_MSG = 'Organization created Successfully';
export const ADD_DISCOUNT_MENAGEMENT_MSG = 'Add discount Successfully';
export const DELETE_DISCOUNT_MENAGEMENT_MSG = 'Discount coupons deleted Successfully';
export const USER_STORY_MOVE_MSG = 'User story moved Successfully';
export const UPDATE_USER_STORIES_SAVE_SUCCESS_MSG = 'User story updated successfully';
export const APPLY_VOUCHER_MSG = 'Voucher applied successfully';
export const DELETE_USER_STORY_MSG = 'User story deleted successfully';
export const PAYMENT_MSG = 'Your Payment is successfully';
export const UNSELECTED_STORIES_SUBMISSION_ERROR_MSG = 'Any user stories that have not been selected, needs to be moved to another sprint before submission';
export const NOT_ENOUGH_CREDITS_ERROR_MSG = 'You don\'t have enough credits to proceed with selected user stories, please add more credits';
export const USER_UPDATE_SUCCESS_MSG = 'User updated successfully';
export const ROLES = ['Admin', 'Customer', 'ServiceProvider'];
export const ORG_USER_ROLES = ['CustomerAdmin', 'Customer'];
export const USER_STATUSES = ['Pending', 'Audition', 'Inactive', 'Active', 'Evaluation', 'AwaitingDocuments'];
export const SKILLS = ['Java', 'Node.JS', 'React', 'React Native', 'Javascript', 'Python', 'Angular', 'Vue', 'iOS/Swift', 'Android/Kotlin', 'C#/.NET', 'PHP/Laravel', 'Ruby on Rails', 'Go', 'Rust'];
