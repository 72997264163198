import {
  GET_ORGANIZATIONS_START,
  GET_ORGANIZATIONS_ERROR,
  GET_ORGANIZATIONS_SUCCESS,
  UPDATE_ORGANIZATIONS_DATA_START,
  UPDATE_ORGANIZATIONS_DATA_ERROR,
  UPDATE_ORGANIZATIONS_DATA_SUCCESS,
  SET_ACTIVE_ORG,
  CLEAR_ORGANIZATION_ERRORS,
  SAVE_CREATE_ORGANIZATION_SUCCESS, SAVE_CREATE_ORGANIZATION_START, CREATE_ORGANIZATION_SAVE_ERROR
} from "actions/constant";

const organizationUpdates = (
  state = {
    isSavingCreate: false,
    successCreateOrganization: [],
  }, action,) => {
  switch (action.type) {
    case SAVE_CREATE_ORGANIZATION_START:
      return Object.assign({}, state, {
        isSavingCreate: true,
      });
    case SET_ACTIVE_ORG:
      return Object.assign({}, state, {
        activeOrg: action.payload,
      });
    case SAVE_CREATE_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        isSavingCreate: false,
        organizationLists: state.organizationLists.concat(action.saveResponse),
      });
    case CREATE_ORGANIZATION_SAVE_ERROR:
      return Object.assign({}, state, {
        isSavingCreate: false,
        createOrganizationError: action.errorMsg,
      });
    case UPDATE_ORGANIZATIONS_DATA_START:
      return Object.assign({}, state, {
        isUpdating: true
      });
    case UPDATE_ORGANIZATIONS_DATA_SUCCESS:
      return Object.assign({}, state, {
        organizationLists: state.organizationLists.map((organization) =>
          organization.id === action.payload.data.id ? action.payload.data : organization
        ),
        isUpdating: false
      });
    case UPDATE_ORGANIZATIONS_DATA_ERROR:

      return Object.assign({}, state, {
        updateOrganizationError: action.errorMsg,
        isUpdating: false

      });
    case GET_ORGANIZATIONS_START:
      return Object.assign({}, state, {
        isFetching: true
      });
    case GET_ORGANIZATIONS_SUCCESS:
      return Object.assign({}, state, {
        organizationLists: action.payload && action.payload.data,
        isFetching: false
      });
    case GET_ORGANIZATIONS_ERROR:

      return Object.assign({}, state, {
        getOrganizationListError: action.errorMsg,
        isFetching: false
      });
    case CLEAR_ORGANIZATION_ERRORS:
      return Object.assign({}, state, {
        createOrganizationError: '',
        getOrganizationListError: '',
        updateOrganizationError: '',
      });
    default:
      return state;
  }
};

export default organizationUpdates;
