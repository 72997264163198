import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { grayColor, infoColor } from "../../material-dashboard-pro-react";

const projectListStyle = {
    ...styles,
    tableActionIcon: {
        color: grayColor[9]
    },
    tableHearders: {
        color: infoColor[0]
    },
    right: {
        textAlign: "right !important"
    },
    cardTitle:{
        paddingTop: '3px'
    },
    addVoucherBtn: {
        float: 'right',
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '24px',
        //height: '34px'
        padding: '8px 16px'
    },
    contractHeading:{
        "&:hover" : {
            textDecoration: "underline"
        }
    },
    voucherHeading: {
        display: 'flex',
        alignItems: 'center',
        flex: 1
    },
    cardHdr: {
        display: 'flex',
        alignItems: 'center'
    }
    
}

export default projectListStyle;