

const FilePopUpStyle = {
    container:{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 10000,
        color: 'white'
    },
    btn:{
       // backgroundColor: '',
    },
    topSec:{
        display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 10px'
    },
    downloadBtn: {
        //margin: '10px 20px'
    },
    midItem: {
       height: '550px',
       width: '450px',
       
    },
    itemContainer:{
        justifyContent: 'center',
        display: 'flex'
    }

}

export default FilePopUpStyle;