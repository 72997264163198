import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { getMarginsList, updateMargin } from "actions/margins.js";
import UserListStyle from "assets/jss/material-dashboard-pro-react/views/UserListStyle";

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "components/CustomButtons/Button.js";

import AddIcon from "@material-ui/icons/Add";
import EditMarginsDialog from "./EditMarginsDialog";

class Margins extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(getMarginsList());
    this.state = {
      marginsList: [],
      selectedMargin: {
        id: "",
        role: "",
        baseCredits: "",
        percent: ""
      },
      selectedMarginIndex: 0,
      openEditMarginsDialog: false
    };
  }

  componentWillReceiveProps(props) {
    if (props.margins.marginsList) {
      this.setState({ marginsList: props.margins.marginsList });
    }
  }

  closeEditMarginsDialog = () => {
    this.setState({
      openEditMarginsDialog: false
    });

  };

  updateMargin = async (data) => {
    try {
      this.closeEditMarginsDialog();

      const response = await this.props.dispatch(updateMargin(data.id, data, () => {
        toast.success("Contract updated successfully");
      }));

      if (response.status == 204) {
        let getMarginsList = this.state.marginsList;
        let marginsList = getMarginsList[this.state.selectedMarginIndex];
        marginsList = {
          ...marginsList,
          baseCredits: data.baseCredits,
          percent: data.percent
        }
        getMarginsList[this.state.selectedMarginIndex] = marginsList
        this.setState({
          marginsList: getMarginsList
        });
      }
    } catch (error) {

    }

  }

  render() {
    const { classes } = this.props;
    const { marginsList } = this.state;
    const isFetching = this.props.userUpdate.isFetching;
    const simpleButtons = (value, index) =>
      [{ icon: CreateIcon }].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={() =>
              key === 0
                ? this.setState({
                  openEditMarginsDialog: true,
                  selectedMargin: value,
                  selectedMarginIndex: index
                })
                : ""
            }>
            <prop.icon
              className={[
                classes.icon,
                classes.actionButtonRound,
                classes.tableActionIcon
              ].join(" ")}
            />
          </Button>
        );
      });

    let tableData;
    if (marginsList && marginsList.length) {
      tableData = marginsList.map((e, i) => {
        return {
          index: i + 1,
          role: e.role,
          baseCredits: e.baseCredits,
          percent: e.percent,
          storyType: e.storyType,
          engagementMode: e.engagementMode,
          action: simpleButtons(e, i)
        };
      });
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <EditMarginsDialog
              updateMargin={this.updateMargin}
              selectedMargin={this.state.selectedMargin}
              openEditMarginsDialog={this.state.openEditMarginsDialog}
              closeEditMarginsDialog={this.closeEditMarginsDialog}
            />
            <Card>
              <CardBody>
                <Table
                  tableData={tableData}
                  loading={isFetching}
                  tableHead={[
                    {
                      Header: "#",
                      accessor: "index",
                      filterable: false,
                      headerClassName: classes.tableHearders,
                      className: classes.left,
                      width: 70
                    },
                    {
                      Header: "Role ",
                      accessor: "role",
                      filterable: false,
                      headerClassName: classes.tableHearders
                    },
                    {
                      Header: "Base Credits ",
                      accessor: "baseCredits",
                      filterable: false,
                      headerClassName: classes.tableHearders
                    },
                    {
                      Header: "Effort Percent ",
                      accessor: "percent",
                      filterable: false,
                      headerClassName: classes.tableHearders
                    },
                    {
                      Header: "Story Type ",
                      accessor: "storyType",
                      filterable: false,
                      headerClassName: classes.tableHearders
                    },
                    {
                      Header: "Engagement Mode ",
                      accessor: "engagementMode",
                      filterable: false,
                      headerClassName: classes.tableHearders
                    },
                    {
                      Header: "Actions",
                      accessor: "action",
                      sortable: false,
                      filterable: false,
                      headerClassName: [
                        classes.right,
                        classes.tableHearders
                      ].join(" "),
                      className: classes.right
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(
  withStyles(UserListStyle)(Margins)
);
