import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import GridItem from "components/Grid/GridItem.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Checkbox from "@material-ui/core/Checkbox";
import { BreadCrumb } from "utils/common";
import userDetailsStyle from "assets/jss/material-dashboard-pro-react/views/userDetailsStyle.js";
import Switch from "@material-ui/core/Switch";
import { getUserDetails } from "actions/userUpdate";
import { getCredit } from "actions/credits";
import { updateUserData } from "actions/userUpdate";
import { updateUserRole } from "actions/userUpdate";
import { updateUserCredit } from "actions/userUpdate";
import { toast } from "react-toastify";
import { createNewUser, getOrganization } from "actions/orgUsers";
import { ORG_USER_ROLES } from "common/constants";
import LoadingOverlay from "react-loading-overlay";

var generator = require("generate-password");
class OrgUserDetails extends Component {
  constructor (props) {
    super(props);
    this.isEditUser = this.props.match.params.userId;

    this.state = {
      name: "",
      fName: "",
      lName: "",
      email: "",
      avCredits: "",
      addCredits: "",
      password: "",
      cPassword: "",
      roles: [],
      invite: false,
      fNameErr: !this.isEditUser,
      lNameErr: !this.isEditUser,
      emailErr: !this.isEditUser,
      passwordErr: !this.isEditUser,
      cPasswordErr: !this.isEditUser,
      organization: [],
      hasCredits: ""
    };

    this.emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  }

  async componentDidMount() {
    let organizationId;
    if (this.isEditUser) {
      const userDetails = await this.props.dispatch(getUserDetails(this.props.match.params.userId));
      organizationId = userDetails.data.organizationId;
      this.props.dispatch(getCredit(this.props.match.params.userId));
    } else {
      organizationId = this.props.match.params.orgId;      
    }
    const organizationDetails = await this.props.dispatch(getOrganization(organizationId));
    this.setState({ avCredits: organizationDetails.data.total, organization: organizationDetails.data, hasCredits: !isNaN(organizationDetails.data.total) });
  }

  componentWillReceiveProps (props) {
    if (props.userUpdate.userDetails && props.userCredit.creditList && this.isEditUser) {
      const userDetails = props.userUpdate.userDetails;
      this.userData = userDetails;
      this.setState({
        name: [userDetails.firstName, userDetails.lastName].join(" "),
        email: userDetails.email,
        roles: userDetails.userRoles
      });   
    }  
    // if (props.userCredit.creditList) {
    //     this.setState({
    //         avCredits: props.userCredit.creditList.credits.available,
    //     });
    // }
  }

  submitData () {
    if (this.state.roles.length) {
      if (this.isEditUser) {
        // this.props.dispatch(updateUserData({
        //     ...this.userData, ...{
        //         firstName: this.state.name.substring(0, this.state.name.lastIndexOf(' ')),
        //         lastName: this.state.name.substring(this.state.name.lastIndexOf(' ') + 1),
        //         email: this.state.email,
        //         userRoles: this.state.roles
        //     }
        // }, () => {
        //     this.props.history.goBack();
        // }));
        this.props.dispatch(
          updateUserRole(
            {
              userRoles: this.state.roles.filter(e =>
                ORG_USER_ROLES.includes(e)
              )
            },
            this.props.match.params.userId,
            () => {
              if (this.state.addCredits) {
                this.props.dispatch(
                  updateUserCredit(
                    { amount: this.state.addCredits },
                    this.props.match.params.userId,
                    () => {
                      this.props.history.goBack();
                    }
                  )
                );
              } else {
                this.props.history.goBack();
              }
            }
          )
        );
      } else {
        this.props.dispatch(
          createNewUser(
            {
              firstName: this.state.fName,
              lastName: this.state.lName,
              email: this.state.email,
              password: this.state.password,
              organizationId: this.props.match.params.orgId,
              userRoles: this.state.roles.filter(e =>
                ORG_USER_ROLES.includes(e)
              )
            },
            this.state.invite,
            () => {
              this.props.history.goBack();
            }
          )
        );
      }
    } else {
      toast.error("Please select atleast one role");
    }
  }

  validate (validators, value) {
    let err;
    for (let i in validators) {
      const validator = validators[i];
      switch (validator.type) {
        case "required":
          err = value ? "" : validator.msg;
          break;
        case "pattern":
          err = new RegExp(validator.pattern).test(value) ? "" : validator.msg;
          break;
        case "maxlength":
          err = value.length <= validator.maxLength ? "" : validator.msg;
          break;
        default:
          err = validator.validate(value) ? "" : validator.msg;
      }
      if (err) {
        return err;
      }
    }
    return err;
  }

  hasError () {
    for (let i in this.state) {
      if (i.includes("Err") && this.state[i]) {
        return true;
      }
    }
  }

  onFormChange (type, event, role) {
    event.persist();
    const result = {};
    let validators;
    switch (type) {
      case "name":
        validators = [{ type: "required", msg: "Name is required" }];
        result["name"] = event.target.value;
        result["nameErr"] = this.validate(validators, event.target.value);
        break;
      case "fName":
        validators = [{ type: "required", msg: "First Name is required" }];
        result["fName"] = event.target.value;
        result["fNameErr"] = this.validate(validators, event.target.value);
        break;
      case "lName":
        validators = [{ type: "required", msg: "Last Name is required" }];
        result["lName"] = event.target.value;
        result["lNameErr"] = this.validate(validators, event.target.value);
        break;
      case "email":
        validators = [
          { type: "required", msg: "Email is required" },
          {
            type: "pattern",
            pattern: this.emailPattern,
            msg: "Email is not valid"
          }
        ];
        result["email"] = event.target.value;
        result["emailErr"] = this.validate(validators, event.target.value);
        break;
      case "avCredits":
        validators = [{ type: "required", msg: "Credits is required" }];
        result["avCredits"] = event.target.value;
        result["avCreditsErr"] = this.validate(validators, event.target.value);
        break;
      case "addCredits":
        result["addCredits"] = event.target.value;
        break;
      case "pass":
        validators = [
          { type: "required", msg: "Password is required" },
          {
            type: "pattern",
            pattern: this.passPattern,
            msg:
              "Password must contain atleast 6 characters including 1 lowercase, 1 uppercase, 1 number & 1 special character"
          }
        ];
        result["password"] = event.target.value;
        result["passwordErr"] = this.validate(validators, event.target.value);
        break;
      case "cPass":
        validators = [
          { type: "required", msg: "Confirm Password is required" },
          {
            type: "passMatch",
            msg: "Passwords don't match",
            validate: value => this.state.password === event.target.value
          }
        ];
        result["cPassword"] = event.target.value;
        result["cPasswordErr"] = this.validate(validators, event.target.value);
        break;
      case "role":
        const roles = this.state.roles;
        if (event.target.checked) {
          roles.push(role);
        } else {
          roles.splice(
            roles.findIndex(e => e === role),
            1
          );
        }
        result["roles"] = roles;
        break;
      case "invite":
        result["invite"] = event.target.checked;

        break;
    }
    //this.setState({ ...result, untouched: false })

    this.setState({ ...result, untouched: false }, () => {
      if (this.state.invite) {
        const randomPassword = generator.generate({
          length: 10,
          numbers: true,
          uppercase: true,
          lowercase: true,
          symbols: true,
          strict: true
        });
        this.setState({
          password: randomPassword,
          cPassword: randomPassword,
          passwordErr: "",
          cPasswordErr: ""
        });
      }
    });
  }

  goBack () {
    this.props.history.goBack();
  }

  render () {
    const { classes } = this.props;
    const { isFetching, isUpdating } = this.props.userUpdate;
    const { isCredit } = this.props.userCredit;
    const { isSavingCreate } = this.props.createUsers;
    const {
      name,
      email,
      avCredits,
      addCredits,
      password,
      cPassword,
      roles,
      invite,
      fName,
      lName,
      nameErr,
      emailErr,
      avCreditsErr,
      passwordErr,
      cPasswordErr,
      fNameErr,
      lNameErr,
      hasCredits
    } = this.state;
    const Switch1 = withStyles({
      switchBase: userDetailsStyle.switchBase
    })(Switch);
    return (
      <div>
        <BreadCrumb
          pathArray={[
            { name: "Users", path: "/admin/users" },
            { name: this.isEditUser ? "Edit" : "Create" }
          ]}
        />
        {!isFetching && hasCredits && (
          <Card>
            <CardHeader color='info' className={classes.cardHeader}>
              <Button
                justIcon
                color='transparent'
                onClick={() => this.goBack()}
              >
                <ArrowBackIcon />
              </Button>
              <div className={classes.cardTitle}>
                {this.isEditUser ? "User Details" : "Create User"}
              </div>
            </CardHeader>
            <LoadingOverlay
              active={isUpdating || isSavingCreate}
              spinner
              text='Loading...'
            >
              <CardBody>
                <form>
                  <GridContainer justify='flex-end'>
                    <GridItem xs={12} sm={12} md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <div className={classes.headings}>USER DETAILS</div>
                    </GridItem>
                  </GridContainer>
                  {this.isEditUser && (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormLabel className={classes.labelHorizontal}>
                          Name
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={9}>
                        <CustomInput
                          error={nameErr}
                          helperText={nameErr}
                          inputProps={{
                            defaultValue: name,
                            type: "text",
                            disabled: true,
                            onChange: event => this.onFormChange("name", event)
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                  {!this.isEditUser && (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <FormLabel className={classes.labelHorizontal}>
                            First Name
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                          <CustomInput
                            error={fNameErr}
                            helperText={fNameErr}
                            inputProps={{
                              defaultValue: fName,
                              type: "text",
                              onChange: event =>
                                this.onFormChange("fName", event)
                            }}
                            formControlProps={{ fullWidth: true }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <FormLabel className={classes.labelHorizontal}>
                            Last Name
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                          <CustomInput
                            error={lNameErr}
                            helperText={lNameErr}
                            inputProps={{
                              defaultValue: lName,
                              type: "text",
                              onChange: event =>
                                this.onFormChange("lName", event)
                            }}
                            formControlProps={{ fullWidth: true }}
                          />
                        </GridItem>
                      </GridContainer>
                    </>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        Email
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <CustomInput
                        error={emailErr}
                        helperText={emailErr}
                        inputProps={{
                          defaultValue: email,
                          disabled: this.isEditUser,
                          type: "text",
                          onChange: event => this.onFormChange("email", event)
                        }}
                        formControlProps={{ fullWidth: true }}
                      />
                    </GridItem>
                  </GridContainer>
                  {this.isEditUser && (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <FormLabel className={classes.labelHorizontal}>
                            Available Credits
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                          <CustomInput
                            error={avCreditsErr}
                            helperText={avCreditsErr}
                            inputProps={{
                              defaultValue: avCredits,
                              type: "number",
                              disabled: true,
                              onChange: event =>
                                this.onFormChange("avCredits", event)
                            }}
                            formControlProps={{ fullWidth: true }}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <FormLabel className={classes.labelHorizontal}>
                            Credits to Add
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                          <CustomInput
                            inputProps={{
                              defaultValue: addCredits,
                              type: "number",
                              onChange: event =>
                                this.onFormChange("addCredits", event)
                            }}
                            formControlProps={{ fullWidth: true }}
                          />
                        </GridItem>
                      </GridContainer>
                    </>
                  )}
                  <hr />
                  {!this.isEditUser && (
                    <>
                      <GridContainer justify='flex-end'>
                        <GridItem xs={12} sm={12} md={3}></GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                          <div className={classes.headings}>PASSWORD</div>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <FormLabel className={classes.labelHorizontal}>
                            New Password
                          </FormLabel>
                        </GridItem>

                        {this.state.invite ? (
                          <GridItem xs={12} sm={12} md={9}>
                            <CustomInput
                              error={passwordErr}
                              helperText={passwordErr}
                              inputProps={{
                                value: "",
                                disabled: this.state.invite
                              }}
                              formControlProps={{ fullWidth: true }}
                            />
                          </GridItem>
                        ) : (
                          <GridItem xs={12} sm={12} md={9}>
                            <CustomInput
                              error={passwordErr}
                              helperText={passwordErr}
                              inputProps={{
                                defaultValue: password,
                                type: "password",
                                onChange: event =>
                                  this.onFormChange("pass", event)
                              }}
                              formControlProps={{ fullWidth: true }}
                            />
                          </GridItem>
                        )}
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <FormLabel className={classes.labelHorizontal}>
                            Confirm Password
                          </FormLabel>
                        </GridItem>

                        {this.state.invite ? (
                          <GridItem xs={12} sm={12} md={9}>
                            <CustomInput
                              error={cPasswordErr}
                              helperText={cPasswordErr}
                              inputProps={{
                                value: "",
                                disabled: this.state.invite
                              }}
                              formControlProps={{ fullWidth: true }}
                            />
                          </GridItem>
                        ) : (
                          <GridItem xs={12} sm={12} md={9}>
                            <CustomInput
                              error={cPasswordErr}
                              helperText={cPasswordErr}
                              inputProps={{
                                defaultValue: cPassword,
                                type: "password",
                                onChange: event =>
                                  this.onFormChange("cPass", event)
                              }}
                              formControlProps={{ fullWidth: true }}
                            />
                          </GridItem>
                        )}
                      </GridContainer>
                      <hr />
                    </>
                  )}
                  <GridContainer justify='flex-end'>
                    <GridItem xs={12} sm={12} md={3}></GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <div className={classes.headings}>USER ROLE</div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <FormLabel
                        className={
                          classes.labelHorizontal + " " + classes.labelAdjust
                        }
                      >
                        User Role
                      </FormLabel>
                    </GridItem>
                    <GridItem
                      className={classes.userRoles}
                      xs={12}
                      sm={12}
                      md={9}
                    >
                      <div>
                        <Checkbox
                          checked={roles.includes(ORG_USER_ROLES[0])}
                          onChange={event =>
                            this.onFormChange("role", event, ORG_USER_ROLES[0])
                          }
                          color='primary'
                        />
                        <span
                          style={{
                            color: roles.includes(ORG_USER_ROLES[0])
                              ? "#00acc1"
                              : ""
                          }}
                        >
                          Organization Admin
                        </span>
                      </div>
                      <div>
                        <Checkbox
                          checked={roles.includes(ORG_USER_ROLES[1])}
                          onChange={event =>
                            this.onFormChange("role", event, ORG_USER_ROLES[1])
                          }
                          color='primary'
                        />
                        <span
                          style={{
                            color: roles.includes(ORG_USER_ROLES[1])
                              ? "#00acc1"
                              : ""
                          }}
                        >
                          Organization User
                        </span>
                      </div>
                    </GridItem>
                  </GridContainer>
                  {!this.isEditUser && (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <FormLabel
                          className={
                            classes.labelHorizontal + " " + classes.toggleLabel
                          }
                        >
                          Send Invite
                        </FormLabel>
                      </GridItem>
                      <GridItem
                        className={classes.selectPadding}
                        xs={12}
                        sm={12}
                        md={9}
                      >
                        <Switch1
                          defaultChecked={invite}
                          onChange={event => this.onFormChange("invite", event)}
                          color='primary'
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                  <hr />
                </form>
              </CardBody>
              <CardFooter className={classes.btnStyle}>
                <Button color='transparent' round onClick={() => this.goBack()}>
                  Cancel
                </Button>
                <Button
                  color='info'
                  round
                  disabled={this.hasError()}
                  onClick={() => this.submitData()}
                >
                  {this.isEditUser ? "Update" : "Create"}
                </Button>
              </CardFooter>
            </LoadingOverlay>
          </Card>
        )}
      </div>
    );
  }
}

export const mapStateToProps = state => state;

export default connect(mapStateToProps)(
  withStyles(userDetailsStyle)(OrgUserDetails)
);
