import {getUserAPI} from "../api/usersApi";
import {Link} from 'react-router-dom';
import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import styles from 'assets/jss/material-dashboard-pro-react/components/breadCrumbStyle.js'
import momentTz from 'moment-timezone';

export const getToken = () => localStorage.getItem('accessToken') ? 'Bearer ' + localStorage.getItem('accessToken') : '' ;
export const getTokenExpiration = () => localStorage.getItem('expireToken') ? localStorage.getItem('expireToken') : '' ;
export const getUserData = () => localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {} ;

export const removeToken = () => localStorage.removeItem('accessToken');
export const removeAllUserinfo = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('expireToken');
  localStorage.removeItem('userData');
}
export const checkUserDeleted = async () =>{
  const userData = getUserData();
  try{
    const response = await getUserAPI(userData._id);
    if(response && response.data){
    }
  }catch (er) {
    if(er.response){
      removeAllUserinfo()
    }
  }
}
export const isLoggedIn = () => {
  checkUserDeleted()
  const accessToken = getToken();
  const expirationDate = getTokenExpiration();
  if(localStorage.getItem('userData') && accessToken && !isTokenExpired(expirationDate)){
    return true;
  }
  return false;
};

export const isTokenExpired = (token, expirationDate) => {
  if (expirationDate < new Date()) {
    removeAllUserinfo();
    return true;
  }
  return false;
};

export const getUserId = () => {
 const userData = getUserData();
 if(userData && userData._id){
   return userData._id;
 }
 return '';
};

export const getBreadCrumbs = () => {
  const data = localStorage.getItem("breadCrumbData");
  return  data ? JSON.parse(data) : [];
}

export const popBreadCrumbs = (index) => {
  let data = getBreadCrumbs()
  localStorage.setItem("breadCrumbData", JSON.stringify(data.slice(0, index)))
}

export const addBreadCrumb = (element) => {
  let data = getBreadCrumbs()
  data.findIndex(d => d.name === element.name) === -1 && data.push(element);
  localStorage.setItem("breadCrumbData", JSON.stringify(data))
}

const useStyles = makeStyles(styles);

export function BreadCrumb(props) {
  const classes = useStyles()
  const { pathArray }= props;
  return <div className={classes.style}>{pathArray.map((path, i) => (
    path.path ? 
      <span>
        &nbsp;<Link style={{color: '#4db7c4', textDecoration: 'underline'}} to={path.path}>{path.name}</Link> /&nbsp;
      </span>
      : <span>{path.name} { i !== pathArray.length - 1 ? '/' : ''}</span>
  ))}</div>
}

export const formatDateUsingUserTimezone = rawDate => {
  if(!rawDate || momentTz(rawDate) === 'Invalid Date') return 'Invalid Date';
  const { profiles } = getUserData();
  const timezone = profiles && profiles[0] && profiles[0].location && profiles[0].location.timezone
    ? profiles[0].location.timezone
    : momentTz.tz.guess();

  let date = momentTz(rawDate).tz(timezone)

  // If timezone provided by user is not valid fallback to guess
  if (!date.tz()) {
    date = date.tz(momentTz.tz.guess())
  }
  return `${date.format('DD MMM YYYY')} - ${date.format('HH:mm')}`
}

export function sortUsersAscendingOrder(data){

  data.sort((userA, userB) => {
    if(userA.user.firstName.toLowerCase() > userB.user.firstName.toLowerCase() ){
      return 1;
    }
    if(userA.user.firstName.toLowerCase() < userB.user.firstName.toLowerCase() ){
      return -1;
    }
    return 0;
  } )

  return data;

}