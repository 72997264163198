// import {
//     cardTitle,
//     successColor,
//     dangerColor
//   } from "assets/jss/material-dashboard-pro-react.js";
  import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
  import {
    cardTitle,
    primaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
    hexToRgb
  } from "assets/jss/material-dashboard-pro-react.js";

  const sprintEditStyle = {
    ...customCheckboxRadioSwitch,
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center'
    },
    staticFormGroup: {
      marginLeft: "0",
      marginRight: "0",
      paddingBottom: "10px",
      margin: "8px 0 0 0",
      position: "relative",
      "&:before,&:after": {
        display: "table",
        content: '" "'
      },
      "&:after": {
        clear: "both"
      }
    },
    staticFormControl: {
      marginBottom: "0",
      paddingTop: "8px",
      paddingBottom: "8px",
      minHeight: "34px"
    },
    inputAdornment: {
      marginRight: "8px",
      position: "relative"
    },
    inputAdornmentIconSuccess: {
      color: successColor[0] + "!important"
    },
    inputAdornmentIconError: {
      color: dangerColor[0] + "!important"
    },
    btnStyle:{
      justifyContent: "flex-end"
    },
    cancleBtn:{
      color: "gray",
      background: "none",
      boxShadow: "none"
    },
    updateBtn:{
      backgroundColor: infoColor[0],
      borderRadius: "50px"
    },
    selectPadding: {
      alignSelf: "flex-end !important",
      
    },
    headings:{
      
    color: infoColor[0],
    fontSize: "15px"
  },
    slSize:{
      width: "150px"
    },
    bottom: {
      paddingBottom:"24px"
    },
    up: {
      paddingTop: "24px",
      paddingLeft: "253px",
      fontSize: "11px",
      color: infoColor[2]
    },
    backBtn: {
      background: "transparent",
      /* border: none; */
      boxShadow: "none"
    },
    creditWidth: {
      width: "150px"
    },
    load: {
      position: 'absolute',
  left: '0',
  top: '0',
  right: '0',
  bottom: '0',
  background: 'center noRepeat',
    url: '(https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif)'
    }
  };
  
  export default sprintEditStyle;
  