import {
  GET_PROJECT_LIST_START,
  GET_PROJECT_LIST_ERROR,
  SAVE_PROJECT_START,
  SAVE_PROJECT_ERROR,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_ERROR,
  SAVE_SPRINT_START,
  SAVE_SPRINT_ERROR,
  SAVE_USER_STORIES_START,
  SAVE_USER_STORIES_ERROR,
  GET_ALL_SPRINT_LIST_START,
  GET_ALL_SPRINT_LIST_ERROR,
  LOGOUT_ERROR,
  CLEAR_ERROR,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  UPDATE_USERS_CREDITS_ERROR,
  UPDATE_USERS_CREDITS_SUCCESS,
  ADD_DISCOUNT_MANAGEMENT_ERROR,
  ADD_DISCOUNT_MANAGEMENT_SUCCESS,
  GET_DISCOUNT_MANAGEMENT_ERROR,
  GET_DISCOUNT_MANAGEMENT_SUCCESS,
  DELETE_DISCOUNT_MANAGEMENT_SUCCESS,
  DELETE_DISCOUNT_MANAGEMENT_ERROR,
  UPDATE_USER_STORY_ERROR,
  UPDATE_USER_STORY_SUCCESS,
  MOVE_USER_STORY_TO_ANOTHER_SPRINT_SUCCESS,
  MOVE_USER_STORY_TO_ANOTHER_SPRINT_ERROR,
  GET_CREDIT_SUCCESS,
  GET_CREDIT_ERROR,
  GET_CREDIT_HISTORY_SUCCESS,
  GET_CREDIT_HISTORY_ERROR,
  GET_CREDIT_BY_PROJECT_SUCCESS,
  GET_CREDIT_BY_PROJECT_ERROR,
  UPDATE_SPRINT_SUCCESS,
  UPDATE_SPRINT_ERROR,
  APPLY_VOUCHER_SUCCESS,
  APPLY_VOUCHER_ERROR,
  DELETE_USER_STORY_SUCCESS,
  DELETE_USER_STORY_ERROR,
  SAVE_CREATE_USER_SUCCESS,
  CREATE_USER_SAVE_ERROR,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  MOVE_UNSELECTED_STORIES_ANOTHER_SPRINT_ERROR,
  NOT_ENOUGH_CREDITS_ERROR,
  RESERVE_CREDITS_START,
  RESERVE_CREDITS_SUCCESS,
  RESERVE_CREDITS_ERROR,


} from "actions/constant";


export default (state=null, action) => {
  switch(action.type) {
    // Any new Error actions needs to be added here
    case GET_PROJECT_LIST_ERROR:
    case SAVE_PROJECT_ERROR:
    case UPDATE_PROJECT_ERROR:
    case SAVE_SPRINT_ERROR:
    case SAVE_USER_STORIES_ERROR:
    case GET_ALL_SPRINT_LIST_ERROR:
    case LOGOUT_ERROR:
    case LOGIN_ERROR:
    case UPDATE_USERS_CREDITS_ERROR:
    case ADD_DISCOUNT_MANAGEMENT_ERROR:
    case GET_DISCOUNT_MANAGEMENT_ERROR:
    case DELETE_DISCOUNT_MANAGEMENT_ERROR:
    case UPDATE_USER_STORY_ERROR:
    case MOVE_USER_STORY_TO_ANOTHER_SPRINT_ERROR:
    case UPDATE_SPRINT_SUCCESS:
    case GET_CREDIT_ERROR:
    case GET_CREDIT_HISTORY_ERROR:
    case GET_CREDIT_BY_PROJECT_ERROR:
    case APPLY_VOUCHER_ERROR:
    case DELETE_USER_STORY_ERROR:
    case CREATE_USER_SAVE_ERROR:
    case PAYMENT_ERROR:
    case MOVE_UNSELECTED_STORIES_ANOTHER_SPRINT_ERROR:
    case NOT_ENOUGH_CREDITS_ERROR:
    case RESERVE_CREDITS_ERROR:
      return Object.assign({}, state, {
        errorMsg: action.errorMsg,
        actionType: action.type,
      });
    // Any new Fetch or clear error actions needs to be added here
    case CLEAR_ERROR:
    case GET_PROJECT_LIST_START:
    case SAVE_PROJECT_START:
    case UPDATE_PROJECT_START:
    case SAVE_SPRINT_START:
    case SAVE_USER_STORIES_START:
    case GET_ALL_SPRINT_LIST_START:
    case RESERVE_CREDITS_START:
    case LOGIN_SUCCESS:
    case ADD_DISCOUNT_MANAGEMENT_SUCCESS:
    case GET_DISCOUNT_MANAGEMENT_SUCCESS:
    case DELETE_DISCOUNT_MANAGEMENT_SUCCESS:
    case UPDATE_USER_STORY_SUCCESS:
    case MOVE_USER_STORY_TO_ANOTHER_SPRINT_SUCCESS:
    case GET_CREDIT_SUCCESS:
    case GET_CREDIT_HISTORY_SUCCESS:
    case GET_CREDIT_BY_PROJECT_SUCCESS:
    case APPLY_VOUCHER_SUCCESS:
    case DELETE_USER_STORY_SUCCESS:
    case SAVE_CREATE_USER_SUCCESS:
    case  PAYMENT_SUCCESS:
    case  RESERVE_CREDITS_SUCCESS:
      return null;
    default:
      return state;
  }
}