import axios from "axios";
import { BASE_URL } from "../common/constants";
import { getToken } from "../utils/common";

export async function createNewOrgrApi (data) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}oauth/signup`;
  const response = await axios.post(url, data, config);
  return response.data;
}

export async function getOrgsAPI () {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}organizations?page=1&limit=10000`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function getOrgsUsersAPI (orgId) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}organizations/${orgId}/users/?page=1&limit=10000`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function createOrgsUsersAPI (data) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  let url = `${BASE_URL}oauth/signup`;
  const response = await axios.post(url, data, config);
  return response.data;
}

export async function getOrganizationAPI(orgId) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken()
    }
  };
  let url = `${BASE_URL}organizations/${orgId}`;
  const response = await axios.get(url, config);
  return response.data;
}