import {
  SAVE_CREATE_ORGANIZATION_START,
  SAVE_CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_SAVE_ERROR,
  UPDATE_ORGANIZATIONS_DATA_ERROR,
  UPDATE_ORGANIZATIONS_DATA_START,
  UPDATE_ORGANIZATIONS_DATA_SUCCESS,
  GET_ORGANIZATIONS_ERROR,
  GET_ORGANIZATIONS_START,
  GET_ORGANIZATIONS_SUCCESS,
  SET_ACTIVE_ORG,
  CLEAR_ORGANIZATION_ERRORS
} from "./constant";
import { CREATE_ORGANIZATION_SAVE_SUCCESS_MSG } from "../common/constants";
import { createNewOrganizationApi, getOrganizations, organizationUpdateAPI } from "../api/organizationUpdates";
import { ORGANIZATION_UPDATE_SUCCESS_MSG } from "../common/constants";

const saveCreateOrganizationStart = () => ({
  type: SAVE_CREATE_ORGANIZATION_START,
});

const saveCreateOrganizationSuccess = json => ({
  type: SAVE_CREATE_ORGANIZATION_SUCCESS,
  saveResponse: json.data,
  successMsg: CREATE_ORGANIZATION_SAVE_SUCCESS_MSG
});
export const setActiveOrganization = organization => ({
  type: SET_ACTIVE_ORG,
  payload: organization,
});


const saveCreateOrganizationError = error => {
  console.log(error);
  return {
    type: CREATE_ORGANIZATION_SAVE_ERROR,
    errorMsg: error.data && error.data.errorMessage
  }
};

const clearOrganizationErrors = () => {
  return {
    type: CLEAR_ORGANIZATION_ERRORS,
  }
};

export const createNewOrganization = (data, callback) => {
  return async (dispatch) => {
    dispatch(saveCreateOrganizationStart());
    try {
      const response = await createNewOrganizationApi(data);

      dispatch(saveCreateOrganizationSuccess(response));
      response && callback && callback();
      return response;
    } catch (error) {
      dispatch(saveCreateOrganizationError(error.response));
      dispatch(clearOrganizationErrors());
      return error;
    }
  }
};

const updateOrganizationsDataStart = () => ({
  type: UPDATE_ORGANIZATIONS_DATA_START,
});
const updateOrganizationsDataSuccess = (data) => ({
  type: UPDATE_ORGANIZATIONS_DATA_SUCCESS,
  successMsg: ORGANIZATION_UPDATE_SUCCESS_MSG,
  payload: data
});
const updateOrganizationsDataError = (error) => ({
  type: UPDATE_ORGANIZATIONS_DATA_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const updateOrganizationData = (data, id, callBack) => {
  return async (dispatch) => {
    dispatch(updateOrganizationsDataStart());
    try {
      const response = await organizationUpdateAPI(data, id);
      dispatch(updateOrganizationsDataSuccess(response));
      response && callBack && callBack(response);
      return response;
    } catch (error) {
      dispatch(updateOrganizationsDataError(error));
      dispatch(clearOrganizationErrors());
      return error;
    }
  }
};

const getOrganizationsStart = () => ({
  type: GET_ORGANIZATIONS_START,
});
const getOrganizationsSuccess = (data) => ({
  type: GET_ORGANIZATIONS_SUCCESS,
  payload: data
});
const getOrganizationsError = (error) => ({
  type: GET_ORGANIZATIONS_ERROR,
  errorMsg: error && error.response && error.response.data && error.response.data.errorMessage
});

export const getOrganizationsList = (callBack) => {
  return async (dispatch) => {
    dispatch(getOrganizationsStart());
    try {
      const response = await getOrganizations();
      callBack && callBack(response.data)
      dispatch(getOrganizationsSuccess(response));
      return response;
    } catch (error) {
      dispatch(getOrganizationsError(error));
      dispatch(clearOrganizationErrors());
      return error;
    }
  }
};



