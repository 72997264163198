import React, { Component } from "react";
import moment from "moment";
import Table from "components/Table/Table";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import { Select } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import FormLabel from "@material-ui/core/FormLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/UserStoriesStyle";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { getContractList } from "../../actions/contract";
import { BreadCrumb } from "utils/common";
import {
  getCustomers,
  getUsers,
} from "actions/userStories";
import { getOrganizationsList } from "actions/orgUsers";
import { getProjectListForOrganization } from "actions/project";
import { getAllSprintList } from "actions/sprint";
import {
  acceptStatusOfContract,
  contractStatus,
} from "../../common/constants";

class openContractList extends Component {
  constructor(props) {
    super(props);
    this.initialStateValue = "-";
    this.state = {
      contractList: [],
      requestor: this.initialStateValue,
      userKey: 0,
      sprint: this.initialStateValue,
      project: this.initialStateValue,
      status: this.initialStateValue,
      disabled: true,
      organizations: [],
      organization: this.initialStateValue
    };
  }


  componentDidMount() {
    if (
      this.props.contract &&
      this.props.contract.openContracts &&
      this.props.contract.openContracts.length > 0
    ) {
      console.log("Contract already exists");
      return;
    }
    this.props.dispatch(getOrganizationsList());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contract.openContracts) {
      this.setState({ contractList: nextProps.contract.openContracts });
    }

    if (nextProps.orgsUserReducer.organizationsList) {
      this.setState({
        organizations: nextProps.orgsUserReducer.organizationsList,
      });
    }
    if (nextProps.userStories.projectList) {
      this.setState({
        projects: nextProps.userStories.projectList,
      });
    }
    if (nextProps.sprint.data) {
      this.setState({
        sprints: nextProps.sprint.data,
      });
    }
    if (nextProps.userStories.filteredTableDataList) {
      this.setState({
        filteredTableData: nextProps.userStories.filteredTableDataList,
      });
    }
  }

  onEditContract(id, contractKey) {
    this.props.history.push({
      pathname: `/admin/editContract/${contractKey}`,
    });
  }

  onSelectChange(event, type) {
    event.persist();
    const value = event.target.value === this.initialStateValue ? undefined : event.target.value;

    this.setState({ [type]: value });
    if (type === "organization") {
      this.props.dispatch(getProjectListForOrganization(value)).then((res) => {
        this.setState({
          sprint: this.initialStateValue,
          project: this.initialStateValue,
          projects: res.data,
          disabled: true,
        });
      });
      
    } else if (type === "project") {
      this.props.dispatch(getAllSprintList(value));
      this.setState({ sprint: this.initialStateValue, disabled: value ? false : true, });
    }
  }

  gotoUserId(value) {
    this.props.history.push("/admin/userStory-detail/" + value);
  }

  getContractTableData() {
    const { disabled, sprint, project, status, userKey } = this.state;

    // double check that the requestor field is set
    if (disabled) return;

    let query = '?detailed=true';
    query += sprint && sprint !== this.initialStateValue ? `&sprint=${sprint}`: "";
    query += project && project !== this.initialStateValue ? `&project=${project}`: "";
    query += status && status !== this.initialStateValue ? `&status=${status}`: "";
    this.props.dispatch(getContractList(
      query,
      true
    ));
  }

  render() {
    const { classes } = this.props;
    const { contractList } = this.state;
    const isFetching = this.props.contract.isFetching;
    const { organizations, projects, sprints, filteredTableData } = this.state;

    const openContract = (i, id, contractKey) => {
      return (
        <a
          className="clickable"
          onClick={() => this.onEditContract(id, contractKey)}
        >
          {contractKey}
        </a>
      );
    };

    let tableData;
    if (contractList) {

      let contract = contractList.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      if (contract && contract.length) {

        contract =
          contract.filter(
            (f) => !acceptStatusOfContract.includes(f.contractStatus)
          ) || [];
        tableData = contract.map((e, i) => {
          console.log(e.stories[0].storyType);
          console.log( e.stories[0].storyType?.replace(' Card',''));
          return {
            index: i + 1,
            storyIndex: openContract(i, e.id, e.contractKey),
            contractType: e.stories[0].storyType?.replace(' Card','') || e.stories[0].storyType || 'Not Specified',
            title: (e.stories && e.stories[0] && e.stories[0].title) || this.initialStateValue,
            owner:
              e.userInfo && e.userInfo.firstName + " " + e.userInfo.lastName,
            email: e.userInfo && e.userInfo.email,
            bidRequested: e && e.visible.length, //  
            bidReceived: e && (e.mybids.length + (e.visible.filter(f=>{return (f.rejected===true)})).length),
            noOfBidRequest: e && e.mybids.length,
            status: e && e.contractStatus,
            updatedAt:
              e.contractBidExpiry && new Date(e.contractBidExpiry).getTime(),
          };
        });
      }
    }

    return (
      <div>
        <BreadCrumb
          pathArray={[{ name: "Market Place" }, { name: " Open Contracts" }]}
        />
        <Card>
          <CardHeader color="info" className={classes.display}>
            <div className={classes.cardTitle}>Filters</div>
          </CardHeader>
          {organizations && (
            <CardBody>
              <GridContainer justify="flex-end">
                <GridItem xs={4} sm={3} md={3} className={classes.title}>
                  <FormLabel>
                    <div>Organization</div>
                    <div>(*required)</div>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={3} md={3}>
                  <Select
                    className={classes.selectItem}
                    onChange={(event) =>
                      this.onSelectChange(event, "organization")
                    }
                    value={this.state.organization}
                  >
                    <MenuItem
                      value={this.initialStateValue}
                      disabled
                      classes={{ root: classes.selectMenuItem }}
                    >
                      Select Organization
                    </MenuItem>
                    {organizations.map((org) => (
                      <MenuItem
                        key={org.id}
                        value={org.id}
                        classes={{ root: classes.selectMenuItem }}
                      >
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                </GridItem>

                <GridItem xs={4} sm={3} md={3} className={classes.title}>
                  <FormLabel>Sprint</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={3} md={3}>
                  <Select
                    value={this.state.sprint}
                    className={classes.selectItem}
                    onChange={(event) => this.onSelectChange(event, "sprint")}
                  >
                    <MenuItem
                      value={this.initialStateValue}
                      disabled
                      classes={{ root: classes.selectMenuItem }}
                    >
                      Select Sprint
                    </MenuItem>
                    {sprints &&
                      sprints.map((e) => (
                        <MenuItem
                          value={e._id}
                          classes={{ root: classes.selectMenuItem }}
                        >
                          {e.title}
                        </MenuItem>
                      ))}
                  </Select>
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.btnStyle}>
                <GridItem xs={4} sm={3} md={3} className={classes.title}>
                  <FormLabel>Project</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={3} md={3}>
                  <Select
                    value={this.state.project}
                    className={classes.selectItem}
                    onChange={(event) => this.onSelectChange(event, "project")}
                  >
                    <MenuItem
                      value={this.initialStateValue}
                      disabled
                      classes={{ root: classes.selectMenuItem }}
                    >
                      Select Project
                    </MenuItem>
                    {projects &&
                      projects.map((e) => (
                        <MenuItem
                          key={e.id}
                          value={e.id}
                          classes={{ root: classes.selectMenuItem }}
                        >
                          {e.title}
                        </MenuItem>
                      ))}
                  </Select>
                </GridItem>

                <GridItem xs={4} sm={3} md={3} className={classes.title}>
                  <FormLabel>Status</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={3} md={3}>
                  <Select
                    value={this.state.status}
                    className={classes.selectItem}
                    onChange={(event) => this.onSelectChange(event, "status")}
                  >
                    <MenuItem
                      value={this.initialStateValue}
                      disabled
                      classes={{ root: classes.selectMenuItem }}
                    >
                      Select Status
                    </MenuItem>
                    {contractStatus.map((e, i) => (
                      <MenuItem
                        key={i}
                        value={e.value}
                        classes={{ root: classes.selectMenuItem }}
                      >
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </GridItem>
              </GridContainer>
              <Button
                disabled={this.state.disabled}
                className={[classes.filterBtn, classes.btnStyle].join(" ")}
                color="info"
                round
                onClick={() =>
                  this.getContractTableData()
                }
              >
                Apply
              </Button>
            </CardBody>
          )}
        </Card>
        <Card>
          <CardHeader color="info" className={classes.cardTitle}>
            Open Contracts
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                {
                  Header: "#",
                  accessor: "index",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  className: classes.left,
                  width: 70,
                },
                {
                  Header: "Contract Id",
                  accessor: "storyIndex",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "Contract Type",
                  accessor: "contractType",
                  filterable: false,
                  // Cell: (props) => <span className={classes.idLinkStyle} onClick={() => this.gotoUserId(props.value.id)}>{props.value.readableId}</span>,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "User Story",
                  accessor: "title",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  // className: classes.cellPadding,
                },
                {
                  Header: "Contract Status",
                  accessor: "status",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "Bid Requested",
                  accessor: "bidRequested",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "Bid Received",
                  accessor: "bidReceived",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                },
                {
                  Header: "Due Date",
                  accessor: "updatedAt",
                  filterable: false,
                  headerClassName: classes.tableHearders,
                  Cell: (props) => (
                    <span>
                      {moment(props.value).format("D MMM YYYY, h:mm A")}
                    </span>
                  ),
                },
              ]}
              loading={isFetching}
              tableData={tableData}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(withStyles(styles)(openContractList));
