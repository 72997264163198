import { toast } from "react-toastify";
import {
  GET_ORGS_USERS_START,
  GET_ORGS_USERS_SUCCESS,
  GET_ORGS_USERS_ERROR,
  CREATE_ORGS_USERS_ERROR,
  GET_ORGANIZATIONS_LIST_START,
  GET_ORGANIZATIONS_LIST_SUCCESS,
  GET_ORGANIZATIONS_LIST_ERROR,
  GET_ORGANIZATIONS_START,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_ERROR
} from "actions/constant";

const orgsUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORGS_USERS_START:
      return Object.assign({}, state, {
        isFetchingUsers: true
      });
    case GET_ORGS_USERS_SUCCESS:
      return Object.assign({}, state, {
        users: action.payload && action.payload.data,
        isFetchingUsers: false
      });
    case GET_ORGS_USERS_ERROR:
      toast.error(action.errorMsg);

      return Object.assign({}, state, {
        orgsUsersError: action.errorMsg,
        isFetchingUsers: false
      });

    case CREATE_ORGS_USERS_ERROR:
      toast.error(action.errorMsg);

      return Object.assign({}, state, {
        orgsUsersError: action.errorMsg,
        isFetchingUsers: false
      });

    case GET_ORGANIZATIONS_LIST_START: 
      return Object.assign({}, state, {
        isFetchingOrganizationList: true
      });
    
    case GET_ORGANIZATIONS_LIST_SUCCESS: 
      return Object.assign({}, state, {
        isFetchingOrganizationList: false,
        organizationsList: action.payload.data
      });

    case GET_ORGANIZATIONS_LIST_ERROR: 
      toast.error(action.errorMsg);
      return Object.assign({}, state, {
        orgsUsersError: action.errorMsg,
        isFetchingOrganizationList: false
      });

    case GET_ORGANIZATIONS_START: 
      return Object.assign({}, state, {
        isFetchingOrganization: true
      });

    case GET_ORGANIZATION_SUCCESS: 
      return Object.assign({}, state, {
        isFetchingOrganization: false,
        organization: action.payload.data
      }); 

    case GET_ORGANIZATION_ERROR: 
      toast.error(action.errorMsg);
      return Object.assign({}, state, {
        getOrgError: action.errorMsg,
        isFetchingOrganization: false
      });

    default:
      return state;
  }
};

export default orgsUserReducer;
