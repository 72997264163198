import React , {Component}from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components


import ReactTable from 'react-table';
import withStyles from "@material-ui/core/styles/withStyles";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from "components/CustomButtons/Button.js";

import Pagination from 'components/Pagination/Pagination';



import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";


class CustomTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTablePage: 1,
            defaultPageSize: 10,
            paginationOpts: null,
            bunch: 1,
            bunchSize: 5
        }
        this.table = React.createRef();
    }

 
  createPagination(dataSize, activeIndex) {
      
    const result = [];
    if(this.state.bunch !== 1) {
        result[0] = { text: <ChevronLeftIcon />, onClick: () => this.onTablePageChange('prev', dataSize) }
    }
    if((this.state.bunch * this.state.bunchSize < dataSize)) {
        result[dataSize + 1] = { text: <ChevronRightIcon />, onClick: () => this.onTablePageChange('next', dataSize) }
    }
    
    const {bunch, bunchSize} = this.state;
    const loopSize = (bunch * bunchSize ) >= dataSize ? dataSize : (bunch * bunchSize);
    for (let i = ((this.state.bunch - 1) * this.state.bunchSize + 1); i <= loopSize; i++) {
        result[i] = { text: i, onClick: () => this.onTablePageChange(i, dataSize), active: i === activeIndex }
    }
    //console.log('create page =', dataSize, activeIndex, this.state, loopSize, result);
    return result;
}

onTablePageChange(event, dataSize) {
    let activeTablePage = this.state.activeTablePage;
    let bunch = this.state.bunch;
    let bunchSize = this.state.bunchSize;
    switch (event) {
        case 'prev':
            if(activeTablePage > (bunch - 1) * bunchSize + 1) {
                activeTablePage = activeTablePage === 1 ? 1 : activeTablePage - 1;
                this.goPrev();
            } else {
                bunch = bunch - 1 ? bunch - 1 : bunch;
                activeTablePage = activeTablePage === 1 ? 1 : activeTablePage - 1; 
            }
            //this.goPrev()
            break;
        case 'next':
            if(activeTablePage < bunch * bunchSize) {
                activeTablePage = activeTablePage === this.state.paginationOpts.length - 2 ? activeTablePage : activeTablePage + 1;
                this.goNext();
            } else {
                bunch = bunch + 1;
                activeTablePage = (bunch - 1) * bunchSize + 1 
            }
            // activeTablePage = (bunch - 1) * bunchSize + 1 
            //this.goNext()
            break;
        default:
            activeTablePage = event;
            console.log(this.table)
            // this.table.current.onPageChange(activeTablePage - 1)
    }
    this.setState({
        activeTablePage,
        bunch: bunch,
    }, () => {
        this.setState({
            paginationOpts: this.createPagination(dataSize, activeTablePage)
        }, () => {
            this.table.current.onPageChange(activeTablePage - 1)
        })
    })
}

componentWillReceiveProps(props) {
        let {defaultPageSize} = this.state
    if(props.tableData) {
        //console.log('total data = ', props.tableData);

        let noOfPages = Math.ceil(props.tableData.length / defaultPageSize) ;
        let activeTablePage = this.state.activeTablePage
        if(props.loading == true){
            activeTablePage = 1;
        }

        this.setState({
            paginationOpts:this.createPagination(
                noOfPages,
                activeTablePage),
            defaultPageSize
        })
    }
}

render() {
    const classes = this.props.classes;
    const { paginationOpts, defaultPageSize } = this.state;
    const {
      tableHead,
      tableData,
      loading,
      // tableHeaderColor,
      // hover,
      // colorsColls,
      // coloredColls,
      // customCellClasses,
      // customClassesForCells,
      // striped,
      // tableShopping,
      // customHeadCellClasses,
      // customHeadClassesForCells
    } = this.props;     
  return (
    <>
        <ReactTable
            ref={this.table}
            data={tableData}
            filterable={false}
            columns={tableHead}
            defaultPageSize={defaultPageSize}
            pageSize={defaultPageSize}
            showPageJump={false}
            loading={loading}
            showPageSizeOptions={false}
            onSortedChange={() => {
                this.setState({activeTablePage: 1
            })}}
            showPaginationBottom={true}
            NextComponent={(event) => {
                this.goNext = event.onClick; 
                return <div></div>
            }}
            PreviousComponent={(event) => {
                this.goPrev = event.onClick; 
                return <div></div>
            }}
            onPageChange={event => console.log(event)}
        />
        <div className={classes.right}>
            {   paginationOpts && 
                <Pagination pages={paginationOpts} color='info'/>
            }
        </div>
    </>
  );
}
}

export default withStyles(styles)(CustomTable)

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: []
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool
};
