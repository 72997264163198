import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import {withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { cyan } from '@material-ui/core/colors';
import { grayColor, infoColor, robotoFont } from "../../material-dashboard-pro-react";
import { ExpansionPanel } from "@material-ui/core";


export const CreditStyle = {
    ...styles,
    floatIcon: {
        color: 'white',
        marginTop: '-28px',
        position: 'absolute'
    },
    cardTitle:{
       fontSize: '18px',
       color:'#162B43',
        lineHeight: '21px',
        paddingLeft: '90px',
    },
    stat: {
        fontSize: '22px',
       color:'#162B43',
        lineHeight: '25px',
        textAlign: 'right',
        ...robotoFont.regular,
    },
    sub: {
        color: '#999999',
        fontSize: '12px',
        margin: 0,
        ...robotoFont.light,
    },
    creditCount: {
        color: infoColor[0],
        fontSize: '22px',
        lineHeight: '25px',
        textAlign: 'right',
        marginLeft: '30px',
        ...robotoFont.regular,
    },
    creditSub: {
        color: infoColor[0],
        fontSize: '12px',
        margin: 0,
        ...robotoFont.light,
    },
    tableHearders: {
        color: infoColor[0],
        fontSize: '17px',
        lineHeight: '20px',
    },
    creditCell : {
        color: infoColor[0],
        fontSize : '14px',
        lineHeight: '16px',
    },
    sprintHeader : {
        color: '#162B43',
        fontSize: '14px',
        lineHeight: '16px',
    },
    sprintHeading : {
        fontSize: '11px',
        textTransform: 'uppercase',
    },
    creditContainer: {
        marginTop: '52px',
    },
    creditTableContainer : {
        padding:' 16px 41px',
    },
    projectCell: {
        width: '80%',
    },
    sprintContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
    },
}

export const BuyMoreButton = withStyles(theme => ({
    root: {
      color: 'white',
      backgroundColor: cyan[400],
      '&:hover': {
        backgroundColor: cyan[600],
      },
      float: 'right',
    },
  }))(Fab);

  export const BorderlessPanel = withStyles(theme => ({
    root: {
      boxShadow: 'none',
    },
    expanded : {
        color: infoColor[0],
        ...robotoFont.medium,
    }
  }))(ExpansionPanel);
