import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { getUserList } from 'actions/userUpdate.js';

import withStyles from '@material-ui/core/styles/withStyles';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Table from 'components/Table/Table.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/DoerListStyle';
import Pagination from 'components/Pagination/Pagination';

import {BreadCrumb} from 'utils/common';
import AddIcon from '@material-ui/icons/Add';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

import {getDoerList} from 'actions/userUpdate';
import moment from 'moment';

class DoerList extends Component {
    
  constructor(props) {
    super(props);
    this.props.dispatch(getDoerList());
    this.state = {
      doerList: undefined,
      toggleState: [],
    };
  }

  componentWillReceiveProps(props) {
    if (props.userUpdate.doerLists) {
      this.setState({doerList: props.userUpdate.doerLists});
    }
  }

  gotoEditUser(user) {
    this.props.history.push('/admin/editDoer/' + (user._id || user.id));
  }
  // gotoSprintVisibilityPage(user){
  //     this.props.history.push(`/admin/${this.props.match.params.userId}/viewUser/${user._id}`)
  //   }

  gotoAdd() {
    this.props.history.push('/admin/createDeveloper?from=developer');
  }

  render() {
    const {classes} = this.props;
    const {doerList} = this.state;
    const isFetching = this.props.userUpdate.isFetching;
    const simpleButtons = (user, index) =>
      [
        // {icon: this.state.toggleState[index] ? ToggleOnIcon : ToggleOffIcon, color: this.state.toggleState[index] ? '#47acc2' : '',onClick:() => {
        //     const toggleState = this.state.toggleState;
        //     toggleState[index] = !toggleState[index];
        //     this.setState({toggleState: toggleState})
        // }},
        {icon: EditIcon, onClick: () => this.gotoEditUser(user)},
        /** TODO: These icons are  not used as of now, we can uncomment when needed */
        // {icon: LocationOnIcon },
        // {icon: DeleteIcon }
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={prop.onClick}
          >
            <prop.icon
              style={{color: prop.color}}
              className={[
                classes.icon,
                classes.actionButtonRound,
                classes.tableActionIcon,
              ].join(' ')}
            />
          </Button>
        );
      });
    let tableData;

    if (doerList) {
      const doer = doerList.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );

      tableData = doer.map((e, i) => {
        // const profile = e.profiles.find(e => e.type === 'User')
        // const skill = e.skills && e.skills.length && e.skills[0];

        return {
          index: i + 1,
          firstName: e.user.firstName,
          lastName: e.user.lastName,
          action: simpleButtons(e, i),
          Email: e.user.email,
          status: e.user.userStatus,
          rate: e.skills
            .sort((a, b) => a.baseRate - b.baseRate)
            .map((sk) => `${sk.skill.skill}: $${sk.baseRate}`)
            .join(', '),
          addedOn: moment(e.createdAt).format('D MMM YYYY, h:mm A'),
          // && e.skills.sort((a,b) => a.baseRate - b.baseRate)[0].baseRate
        };
      });
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={classes.display} color="info">
                <div className={classes.cardTitle}>Developer List</div>
                <div>
                  <Button
                    round
                    size="sm"
                    color="white"
                    style={{
                      color: '#000',
                    }}
                    onClick={() => this.gotoAdd()}
                  >
                    <AddIcon /> Add
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  tableData={tableData}
                  loading={isFetching}
                  tableHead={[
                    {
                      Header: '#',
                      accessor: 'index',
                      filterable: false,
                      headerClassName: classes.tableHearders,
                      className: classes.left,
                      width: 70,
                    },
                    {
                      Header: 'First Name ',
                      accessor: 'firstName',
                      filterable: false,
                      headerClassName: classes.tableHearders,
                    },
                    {
                      Header: 'Last Name ',
                      accessor: 'lastName',
                      filterable: false,
                      headerClassName: classes.tableHearders,
                    },
                    {
                      Header: 'Base Hourly Rate ',
                      accessor: 'rate',
                      filterable: false,
                      headerClassName: classes.tableHearders,
                    },
                    {
                      Header: 'Email ID',
                      accessor: 'Email',
                      filterable: false,
                      headerClassName: classes.tableHearders,
                    },
                    {
                      Header: 'Added On',
                      accessor: 'addedOn',
                      filterable: false,
                      headerClassName: classes.tableHearders,
                    },
                    {
                      Header: 'Status ',
                      accessor: 'status',
                      filterable: false,
                      headerClassName: classes.tableHearders,
                    },
                    {
                      Header: 'Actions',
                      accessor: 'action',
                      sortable: false,
                      filterable: false,
                      headerClassName: [
                        classes.right,
                        classes.tableHearders,
                      ].join(' '),
                      className: classes.right,
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(withStyles(styles)(DoerList));
