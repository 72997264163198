import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const btnStyle = {
  background: "#10b6ca",
  color: "white"
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{textTransform: "capitalize"}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default class EditMarginsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.selectedMargin.id,
      role: props.selectedMargin.role,
      baseCredits: props.selectedMargin.baseCredits,
      percent: props.selectedMargin.percent,
      baseCreditsError: "",
      percentError: "",
    }
  }

  updateMarginForm = (type, value) => {
    
    if(type === "baseCredits") this.setState({baseCredits: value, baseCreditsError: "", percentError: ""})
    if(type === "percent") this.setState({percent: value, baseCreditsError: "", percentError: ""})

    if(value >= 100){
      const error = "Value must be less than or equal to 100"
      if(type === "baseCredits") this.setState({baseCredits: 100, baseCreditsError: error})
      if(type === "percent") this.setState({percent: 100, percentError: error})
    }
  }

  submitForm = () => {
    return {
      id: this.state.id,
      role: this.state.role,
      baseCredits: this.state.baseCredits,
      percent: this.state.percent,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedMargin != prevProps.selectedMargin) {
      this.setState({ 
        id: this.props.selectedMargin.id, 
        role: this.props.selectedMargin.role, 
        baseCredits: this.props.selectedMargin.baseCredits, 
        percent: this.props.selectedMargin.percent, 
      });
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.openEditMarginsDialog}
          onClose={this.props.closeEditMarginsDialog}
          aria-labelledby="form-dialog-title">
          <DialogTitle
            id="form-dialog-title"
            onClose={this.props.closeEditMarginsDialog}>
            Edit Margin
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="role"
              label="Role"
              type="text"
              fullWidth
              disabled
              value={this.props.selectedMargin.role}
            />
            <TextField
              margin="dense"
              id="baseCredits"
              label="Base Credits"
              type="number"
              fullWidth
              variant="outlined"
              value={this.state.baseCredits}
              onChange={(e) => this.updateMarginForm("baseCredits", e.target.value)}
            />
            {this.baseCreditsError !== "" && <Box mb={2}><Typography variant="body2" color="error">{this.state.baseCreditsError}</Typography></Box>}
            <TextField
              margin="dense"
              id="percent"
              label="Effort Percent"
              type="number"
              fullWidth
              variant="outlined"
              value={this.state.percent}
              onChange={(e) => this.updateMarginForm("percent", e.target.value)}
            />
            {this.percentError !== "" && <Box mb={2}><Typography variant="body2" color="error">{this.state.percentError}</Typography></Box>}
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
                <Box mt={4}>
                  <Button style={btnStyle} variant="contained" onClick={
                    () => this.props.updateMargin(this.submitForm())
                    }>
                    UPDATE
                  </Button>
              </Box>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
