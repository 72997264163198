// import {
//     cardTitle,
//     successColor,
//     dangerColor
//   } from "assets/jss/material-dashboard-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
  cardTitle,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const userDetailsStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    // marginTop: "15px",
    fontSize: '20px',
    color: 'white',
    marginBottom: "0px"
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  userRoles: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '-10px'
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px"
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative"
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important"
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important"
  },
  btnStyle:{
    justifyContent: "flex-end"
  },
  cancleBtn:{
    color: "gray",
    background: "none",
    boxShadow: "none"
  },
  updateBtn:{
    backgroundColor: infoColor[0],
    borderRadius: "50px"
  },
  selectPadding: {
    alignSelf: "flex-end !important",
    marginBottom: '-8px'
  },
  switchBase: {
    color: infoColor[0],
    // '&$checked': {
    //   color: infoColor[0],
    // },
    // '&$checked + $track': {
    //   backgroundColor: infoColor[0],
    // },
  },
  headings:{
    
  color: infoColor[0],
  fontSize: "12px",
  fontWeight: 'bold'
},
  slSize:{
    width: "150px"
  },
  bottom: {
    paddingBottom:"24px"
  },
  up: {
    paddingTop: "24px",
    paddingLeft: "253px",
    fontSize: "11px",
    color: infoColor[2]
  },
  backBtn: {
    background: "transparent",
    /* border: none; */
    boxShadow: "none"
  },
  creditWidth: {
    width: "150px"
  },
  selectPadding: {
    alignSelf: "flex-end !important",
    marginBottom: '15px'
  },
  divider : {
    marginBottom: '20px',
    marginTop: '20px'
  },
  labelAdjust : {
    marginBottom: '0px'
  },
  toggleLabel : {
    marginBottom: '24px'
  }
};

export default userDetailsStyle;
