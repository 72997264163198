import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { grayColor, infoColor } from "../../material-dashboard-pro-react";

const OrganizationListStyle = {
    ...styles,
    tableActionIcon: {
        color: grayColor[9]
    },
    tableHearders: {
        color: infoColor[0]
    },
    right: {
        textAlign: "right !important"
    },
    cardTitle:{
       // paddingTop: '3px',
       fontSize: '20px',
       color:'white',
        flex: '1'
    },
    display:{
        display:'flex',
        alignItems: 'center'
    },
    addBtn1:{

        color: 'black',
        textAlign: "right !important",
        

    }
}

export default OrganizationListStyle;