import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { grayColor, infoColor } from "../../material-dashboard-pro-react";

const sprintListStyle = {
    ...styles,
    right: {
        textAlign: 'right !important'
    },
    tableActionIcon: {
        color: grayColor[9]
    },
    tableHearders: {
        color: infoColor[0]
    },
    backBtn: {
        background: "transparent",
        /* border: none; */
        boxShadow: "none"
    },
    display: {
        display: "flex",
        alignItems: 'center'
    },
    capitalize: {
        textTransform: 'capitalize'
    }
}

export default sprintListStyle;